<template>
<div>
	<form-input :value="value" type="password" label="New Password" autocomplete="new-password" v-on:change="val=>password=val" :disabled="disabled"/>
	<div class="progress">
		<div class="progress-bar" :class="[colorClass,{'progress-bar-animated':password_strength < 5},{'progress-bar-striped':password_strength < 5}]" role="progressbar" :style="barSize"></div>
	</div>
	
	<div style="font-size:0.8em;font-style:italic;">Include the following:</div>
	
	<div class="d-flex justify-content-between text-center mb-3" style="font-size:0.7em;">
		<div>Lowercase <font-awesome-icon :class="[password_strength_report.lowercase ? 'text-success' : 'text-secondary']" :icon="(password_strength_report.lowercase ? 'check' : 'times')+'-circle'" fixed-width /></div>
		
		<div>Uppercase <font-awesome-icon :class="[password_strength_report.uppercase ? 'text-success' : 'text-secondary']" :icon="(password_strength_report.uppercase ? 'check' : 'times')+'-circle'" fixed-width /></div>
		
		<div>Number <font-awesome-icon :class="[password_strength_report.number ? 'text-success' : 'text-secondary']" :icon="(password_strength_report.number ? 'check' : 'times')+'-circle'" fixed-width /></div>
		
		<div>Special <font-awesome-icon :class="[password_strength_report.special ? 'text-success' : 'text-secondary']" :icon="(password_strength_report.special ? 'check' : 'times')+'-circle'" fixed-width /></div>
		
		<div>Length <font-awesome-icon :class="[password_strength_report.length ? 'text-success' : 'text-secondary']" :icon="(password_strength_report.length ? 'check' : 'times')+'-circle'" fixed-width /></div>
	</div>
</div>
</template>

<script>
import FormInput from './Input.vue';
export default{
	props:['value','disabled'],
	components:{
		FormInput
	},
	data(){
		return {
			password:'',
			password_strength:0,
			password_strength_report:{}
		}
	},
	computed:{
		colorClass(){
			if( this.password_strength <= 3 ){
				return 'bg-danger';
			}else if( this.password_strength <=4 ){
				return 'bg-warning';
			}else{
				return 'bg-success';
			}
		},
		barSize(){
			var width = (20*Number(this.password_strength));
			return {
				width: width+'%'
			}  
		}
	},
	methods:{
		checkStrength(){
			
			var tests = {
				uppercase: this.password.match(/[A-Z]/g),
				lowercase: this.password.match(/[a-z]/g),
				number: this.password.match(/[0-9]/g),
				special: this.password.match(/[^\w]/g),
				repeat: this.password.match(/(.{3,})\1{1,}/g),
				consecutive: this.password.match(/(\w)\1{3,}/g)
			};
			
			var strength = 0;
			
			this._.forOwn(tests,(value, key)=>{
				if( value != null && value.length > 0 ){
					if(key == "repeat" || key == "consecutive"){
						strength--;
					}else{
						strength++;
					}
					tests[key]=true;
				}else{
					tests[key]=false; //stops errors with null values
				}
			});
			
			if(this.password.length < 8){
				strength=0;
				tests['length']=false;
			}else{
				strength++;
				tests['length']=true;
			}
			
			this.password_strength = strength;
			this.password_strength_report = tests;
		}
	},
	watch:{
		password(){
			this.checkStrength();
			
			this.$emit('input',this.password);
			this.$emit('strength',this.password_strength);
		},
		value(a){
			this.password=a;
		}
	},
}
</script>

<style lang="scss" scoped>
.progress{
	margin-top:-20px;
	height: 7px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	.progress-bar{
	}
}
</style>