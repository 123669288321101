<template>
<div>
	<div class="accordion has_caret" id="usersFilters">
		<div class="card">
			<div class="card-header" :class="{collapsed:!showFilters,open:showFilters}" @click="showFilters=!showFilters">
				<div class="d-flex justify-content-between align-items-center">
					<div>Filters</div>
					<font-awesome-icon icon="chevron-right"/>
				</div>
			</div>

			<div class="collapse" :class="{show:showFilters}">
				<div class="card-body">
					<switch-button v-model="thisHideInactiveUsers" v-on:input="$emit('toggle',thisHideInactiveUsers)">Show Deactivated Users</switch-button>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid my-3 form-group">
		<search v-model="search" v-on:input="val=>$emit('search',val)"></search>
	</div>
</div>
</template>

<script>
import SwitchButton from '../../FormElements/Switch.vue';
import Search from '../../FormElements/Search.vue';

export default{
		props: ['hideInactiveUsers'],
		components:{
			SwitchButton,
			Search
		},
		data(){
			return {
					showFilters: false,
					thisHideInactiveUsers: 0,
					search:''
			}
		},
		mounted(){
			this.thisHideInactiveUsers = this.hideInactiveUsers;
		}
}
</script>
