<template>
<div>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="$emit('close')"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Display Preferences</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<ul class="list-group">
				<li class="list-group-item" @click="view='realtime'">Real-time Sensor Settings</li>
				<li class="list-group-item" @click="view='landingpage'">Landing Page</li>
			</ul>
		</div>
	</div>

	<realtime-sensor-settings :state="(view=='realtime'?'open':'closed')" :settings="settings" v-on:close="view=''" v-on:reload="fetchSettings"/>
	<landing-page :state="(view=='landingpage'?'open':'closed')" :settings="settings" v-on:close="view=''" v-on:reload="fetchSettings"/>
</div>
</template>

<script>
//import AlarmGroups from './DisplayPreferences/AlarmSDRGroups.vue';
import RealtimeSensorSettings from './DisplayPreferences/RealtimeSensorSettings.vue';
import LandingPage from './DisplayPreferences/LandingPage.vue';

export default{
	components:{
		//AlarmGroups,
		RealtimeSensorSettings,
		LandingPage
	},
	props:['state'],
	data(){
		return {
			view:'',
			settings:[],
		}
	},
	methods:{
		fetchSettings(){
			var self = this;
			this.axios.get( this.controller('auth/display_preferences') ).then(res=>{

				//if current_sort_override does't exist things will get ugly
				//create an empty placeholder
				if(!res.data.data.current_sort_override){
					res.data.data.current_sort_override = {
						sort_by:0,
						secondary_sort_by:0,
						sort_direction:'asc',
						secondary_sort_direction:'asc'
					};
				}

				self.settings = res.data.data;
			});
		}
	},
	watch:{
		state(a){
			if( a == 'open' ){
				this.fetchSettings();
			}
		}
	}
}
</script>
