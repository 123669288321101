<template>
<div>
	<div class="list-group">
		<a v-for="(document, index) in documents" class="list-group-item" @click="downloadFile(document.file_location,document.file_name)" v-bind:key="index">
			<div class="d-flex justify-content-between">
				<div><font-awesome-icon icon="clock" fixed-width /> {{ moment.unix(document.check_datetime_unix).format("DD/MM/YYYY HH:mm") }}<br/>
				{{document.id}} ({{document.location_name}})</div>
				<div>{{ document.enabled == 1 ? 'Enabled' : 'Disabled' }}</div>
			</div>
		</a>
	</div>
	
	<alert v-if="showAlert">
		<div slot="title">
			Error
		</div>
		Unable to download file.
		<button slot="buttons" class="btn btn-block btn-secondary" @click="showAlert=false">Ok</button>
	</alert>
</div>
</template>

<script>
import Alert from '../../Alert';
export default{
	props:['documents'],
	components:{
		Alert
	},
	data(){
		return {
			showAlert: false
		}
	},
	methods:{
		downloadFile(file,name){
			var f = new FormData();
			f.append('file',file);
			f.append('name',name);
			
			var self = this;
			this.axios.post(this.controller('auth/grab_client_doc'),f,{
				responseType: 'blob'
			}).then(response=>{
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
			}).catch(()=>{
				//alert unable to download file
				self.showAlert=true;
			});
		}
	}
}
</script>
