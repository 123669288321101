<template>
	<div>
		<users-filters :hideInactiveUsers="hideInactiveUsers" v-on:toggle="val=>hideInactiveUsers=val" v-on:search="val=>search=val"></users-filters>

		<loading :state="loading"></loading>

		<ul class="list-group">
			<li class="p-0 list-group-item list-group-item-action"><button class="btn btn-block btn-secondary" @click="showNewUserOffcanvas=true">New User</button></li>
			<li class="list-group-item list-group-item-action" v-for="user in filteredUsers" :key="user.id" :class="{inactive: (user.active == 0 || !user.privacy_policy_accepted), active: selectedUser.id == user.id}" @click="selectUser(user)">{{user.first_name}} {{user.last_name}}</li>
		</ul>
		
		<div v-if="filteredUsers == 0 && loading == false" class="bg-white container-fluid text-center py-3">
			No users
		</div>

		<users-offcanvas :user="selectedUser" :groups="groups" :icc-codes="icc_codes" v-on:unselect-user="selectedUser={}" v-on:reload="getUsers"></users-offcanvas>
		
		<new-user :state="showNewUserOffcanvas" v-on:close="showNewUserOffcanvas=false" v-on:reload="getUsers"/>
	</div>
</template>

<script>
import UsersFilters from '../../components/Settings/Users/UsersFilters.vue';
import Loading from '../../components/Loading.vue';
import UsersOffcanvas from '../../components/Settings/Users/UsersOffcanvas.vue';
import NewUser from '../../components/Settings/Users/NewUser.vue';

export default{
	components:{
		UsersFilters,
		Loading,
		UsersOffcanvas,
		NewUser
	},
	data(){
		return {
			hideInactiveUsers: '0',
			loading:false,
			search:'',
			users: [],
			groups:[],
			icc_codes:[],
			selectedUser: {},
			Request: null,
			showNewUserOffcanvas: false
		}
	},
	computed:{
		filteredUsers(){
			return this.searchArrayObjects( this.users, this.search ).sort((a,b)=>{
				if( a.first_name.toLowerCase() > b.first_name.toLowerCase() ){
					return 1;
				}else if( a.first_name.toLowerCase() < b.first_name.toLowerCase() ){
					return -1;
				}else{
					return 0;
				}
			});
		}
	},
	methods:{
		getUsers(){
		
			//close off canvas
			this.showNewUserOffcanvas=false;

			//cancel existing graph requests if promise exists
			if(this.Request){
				this.Request.cancel();
			}
			//Create new cancellable request
			this.Request = this.axios.CancelToken.source();

			this.loading=true;
			var self = this;
			var f = new FormData();
			f.append('showInactive',(this.hideInactiveUsers == '1' ? false : true) )
			this.axios.post( this.controller('settings'), f,{
				cancelToken: this.Request.token
			}).then(function(response){
				self.users = response.data.data.users;
				self.groups = response.data.data.groups;
				self.icc_codes = response.data.data.icc_codes;
				self.loading=false;

				self.Request = null;
			});
		},
		selectUser(user){
			if(user.active == 1 && user.privacy_policy_accepted){
				this.selectedUser=user;
			}
		}
	},
	created(){
		this.getUsers();
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Settings','/settings'],
			['Users','/settings/users']
		]);
	},
	watch:{
		hideInactiveUsers(){
			this.getUsers();
		}
	},
	beforeDestroy(){
		//cancel existing graph requests if promise exists
		if(this.Request){
			this.Request.cancel();
		}
		this.Request = null;
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/_vars.scss';
.list-group{
	.list-group-item{
		&.inactive{
			background:#ddd;
		}
		&.active{
			background:$green;
			color:#fff;
		}
	}
}
</style>
