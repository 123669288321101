import Vue from 'vue'
import Router from 'vue-router'

/*
ROUTE VIEWS
*/
import Login from './views/Login.vue'
import Dashboard from './views/Dashboard.vue'
import AlarmEvents from './views/AlarmEvents.vue'
import SuperAdmin from './views/super_admin/Menu.vue'
import CompanyChanger from './views/super_admin/CompanyChanger.vue'
import DepartmentChanger from './views/super_admin/DepartmentOnlyChanger.vue'
import ContactDash from './views/super_admin/ContactDash.vue'
import RealtimeSensors from './views/RealtimeSensors.vue'
import UserSettingsMenu from './views/user/SettingsMenu.vue'
import Settings from './views/Settings.vue'
import SettingsUsers from './views/settings/Users.vue'
import SettingsAlarmGroups from './views/settings/AlarmGroups.vue'
import SettingsSdrGroups from './views/settings/SdrGroups.vue'
import SettingsDocumentCenter from './views/settings/DocumentCenter.vue'
import SettingsPasswords from './views/settings/Passwords.vue'
import SettingsSensorSettings from './views/settings/SensorSettings.vue'
import HelpCenter from './views/HelpCenter.vue'
import SiteMap from './views/SiteMap.vue'
import AuditTrail from './views/AuditTrail.vue';
import PrivacyPolicy from './views/first_login/PrivacyPolicy.vue';
import FirstTimeLogin from './views/first_login/Stepper.vue';
import Support from './views/Support.vue';
import Problem from './views/Problem.vue';
import env from './environment.js';

import store from './store.js';

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			redirect: ()=>{
				return (typeof store.state.user.preferences != 'undefined' && store.state.user.preferences.mobile_landing_page ? store.state.user.preferences.mobile_landing_page : '/dashboard');
			}
		},
		{
			path: '/support',
			name: 'support',
			component: Support
		},
		{
			path: '/report-a-problem',
			name: 'report_a_problem',
			component: Problem
		},
		{
			path: '/direct_login',
			name: 'direct_login',
			component: Login
		},
		{
			path: '/login',
			redirect: ()=>{
				//return 'http://localhost:10003/auth/login/mobile';
				//window.location = 'http://localhost:10003/auth/login/mobile';
				var redirect_base = env.path().replace('/mobile/','');
				window.location = redirect_base+'/auth/login/mobile';
			}
		},
		{
			path: '/privacy-policy',
			name: 'privacy_policy',
			component: PrivacyPolicy
		},
		{
			path: '/first-time-login',
			name: 'first_time_login',
			component: FirstTimeLogin
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: Dashboard
		},
		{
			path: '/alarm-events',
			name: 'alarm_events',
			component: AlarmEvents
		},

		{
			path: '/settings',
			name: 'settings',
			component: Settings
		},
			{
				path: '/settings/users',
				name: 'settings_users',
				component: SettingsUsers
			},
			{
				path: '/settings/alarm-groups',
				name: 'settings_alarm_groups',
				component: SettingsAlarmGroups
			},
			{
				path: '/settings/sdr-groups',
				name: 'settings_sdr_groups',
				component: SettingsSdrGroups
			},
			{
				path: '/settings/document-center',
				name: 'settings_document_center',
				component: SettingsDocumentCenter
			},
			{
				path: '/settings/passwords',
				name: 'settings_passwords',
				component: SettingsPasswords
			},
			{
				path: '/settings/sensor-settings',
				name: 'settings_sensor_settings',
				component: SettingsSensorSettings
			},

		/*{
			path: '/user/settings',
			name: 'user_settings',
			component: UserSettingsMenu
		},*/
		{
			path: '/user/settings/:initView?',
			component: UserSettingsMenu,
			props: true
		},

		{
			path: '/realtime-sensors',
			name: 'realtime_sensors',
			component: RealtimeSensors
		},

		{
			path: '/help-center',
			name: 'help_center',
			component: HelpCenter
		},

		{
			path: '/site-map',
			name: 'site_map',
			component: SiteMap
		},

		{
			path: '/audit-trail',
			name: 'audit_trail',
			component: AuditTrail
		},



		//Super Admin Views
		{
			path: '/super-admin',
			name: 'super_admin',
			component: SuperAdmin
		},

			{
				path: '/super-admin/company-changer',
				name: 'company_changer',
				component: CompanyChanger
			},
			
			{
				path: '/department-changer',
				name: 'department_changer',
				component: DepartmentChanger
			},

			{
				path: '/super-admin/contact-dash',
				name: 'contact_dash',
				component: ContactDash
			},
	]
})
