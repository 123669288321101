<template>
<div class="container-fluid bg-white py-3">
	<h5>Report a Problem</h5>
	<p>If you find a problem, we would appreciate you letting use know. Please use this form to give us details.</p>
	<div class="form-group">
		<label>Where?</label>
		<p style="font-size:0.8em;">Tell us what section of our site you found the problem.</p>
		<textarea class="form-control" rows="2" v-model="location"></textarea>
	</div>
	<div class="form-group">
		<label>Details</label>
		<p style="font-size:0.8em;">Describe the problem and when it happened.</p>
		<textarea class="form-control" rows="5" v-model="details"></textarea>
	</div>
	<button class="btn btn-block btn-success my-3" :disabled="location=='' || details==''" @click="send">Submit</button>
	
	<alert v-if="alertMsg">
		<div slot="title">Notice</div>
		{{alertMsg}}
		<button class="btn btn-secondary btn-block" @click="alertMsg=''" slot="buttons">Ok</button>
	</alert>
</div>
</template>

<script>
import Alert from '../components/Alert.vue';
export default{
	components:{
		Alert
	},
	data(){
		return {
			location: '',
			details: '',
			SendRequest: null,
			alertMsg: ''
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Report a Problem','/report-a-problem']
		]);
	},
	methods:{
		send(){
			var f = new FormData();
			f.append('subject','Problem with mobile site');
			f.append('message',(`<p><b>Location:</b></p>
			<p>${this.location}</p>
			<p><b>Details:</b></p>
			<p>${this.details}</p>`).trim().replace(/\r?\n|\r|\t/g,''));
			
			//cancel existing graph requests if promise exists
			if(this.SendRequest){
				this.SendRequest.cancel();
			}
			//Create new cancellable request
			this.SendRequest = this.axios.CancelToken.source();
		
			var self = this;
			this.axios.post( this.controller('dashboard/submit_contact'), f, {
				cancelToken: this.SendRequest.token
			}).then(res=>{
				if( res.data.status == 'success' ){
					//display message
					self.alertMsg = 'Message sent successfully';
					
					//reset form
					self.location='';
					self.details='';
				}else{
					self.alertMsg = res.data.msg;
				}
			
				self.SendRequest = null;
			});
		}
	}
}
</script>
