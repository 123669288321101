<template>
<div>
	<div class="container-fluid py-3">
		<div class="d-flex align-items-center">
			<div style="flex-grow:1;" class="mr-1">
				<search v-model="search"/>
			</div>
			<pagination :array="filteredSensors" v-on:data="setPage" :sortby="['id','location_name']"/>
		</div>
	</div>

	<loading :state="loading"/>
	<div class="list-group">
		<sensor v-for="(sensor, index) in currentPageSensors" :key="index" :sensor="sensor" :contact_labels="contact_labels" v-on:select="$emit('select',sensor)" :selected="sensor.id==selectedId ? true : false"/>
	</div>
</div>
</template>

<script>
import Sensor from './Sensor.vue';
import Search from '../../FormElements/Search.vue';
import Loading from '../../Loading.vue';
import Pagination from '../../Pagination.vue';

export default{
	props:['sensors','selectedId','loading','contact_labels'],
	components:{
		Sensor,
		Search,
		Loading,
		Pagination
	},
	data(){
		return {
			selected:'',
			search:'',
			currentPageSensors:[]
		}
	},
	computed:{
		filteredSensors(){
			var return_data = [];
			for(const i in this.sensors){
				return_data.push(this.sensors[i]);
			}

			return this.searchArrayObjects( return_data, this.search );
		}
	},
	methods:{
		setPage(x){
			this.currentPageSensors=x;
		}
	}
}
</script>
