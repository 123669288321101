<template>
<div class="container-fluid">
	<div class="d-flex align-items-center justify-content-between">
		<div class="search_wrapper form-group my-3 mr-1 flex-grow-1">
			<search v-model="searchFilter"></search>
		</div>
		<div ref="pagination_buttons"><pagination :array="filteredArray" v-on:data="results=>$emit('data',results)" :per-page="Number(perPage)" :sortby="sortby"/></div>
	</div>
</div>
</template>

<script>
import Pagination from './Pagination.vue';
import Search from './FormElements/Search.vue';

export default{
	components:{
		Pagination,
		Search
	},
	props:{
		array: Array,
		perPage:{
			type: [Number,String],
			default: 25
		},
		filters: Array,
		sortby: Array
	},
	data(){
		return {
			searchFilter:''
		}
	},
	computed:{
		filteredArray(){
			return this.searchArrayObjects(this.array,this.searchFilter);
		}
	}
}
</script>

<style lang="scss" scoped>
.container-fluid{
	overflow:hidden;
	background:#ddd;
}
</style>