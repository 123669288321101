var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item text-secondary d-flex justify-content-between align-items-center"},[_c('div',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"ellipsis-v","fixed-width":""}}),_vm._v(" "),_c('i',{staticStyle:{"font-size":"0.8em"}},[_vm._v("Drag to set alarm priority")])],1),_c('div',{staticClass:"text-right",staticStyle:{"font-size":"14px"}},[_c('div',{staticClass:"d-inline-block mr-1",staticStyle:{"background":"#06ab03","border-radius":"8px","height":"15px","width":"25px","margin-bottom":"-3px"}}),_vm._v(" "),_c('span',[_vm._v("= Enabled")]),_c('div',[_vm._v(_vm._s(_vm.activeUsers.length)+" Enabled Users")])])])]),_c('draggable',{staticClass:"list-group",attrs:{"handle":".handle"},on:{"start":function($event){_vm.reordering=true},"end":function($event){_vm.reordering=false}},model:{value:(_vm.usersCopy),callback:function ($$v) {_vm.usersCopy=$$v},expression:"usersCopy"}},_vm._l((_vm.usersCopy),function(user){return _c('li',{key:user.userid,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('font-awesome-icon',{staticClass:"handle mr-2",attrs:{"icon":"ellipsis-v","fixed-width":""}}),_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" "),(_vm.loading.indexOf(user.id) > -1)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1),_c('switch-input',{ref:user.id,refInFor:true,on:{"input":v=>{
						_vm.$refs.auth.check(()=>{
							//because this has values being passed it has to be wrapped in another func
							//to avoid it trying to execute rather than pass as callback
							//toggleActive(v,user)
						},()=>{
							_vm.revertToggle(user);
						});
					}},model:{value:(user._in_group),callback:function ($$v) {_vm.$set(user, "_in_group", $$v)},expression:"user._in_group"}})],1)])}),0),(_vm.showAlert)?_c('alert',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Save Error")]),_vm._v(" "+_vm._s(_vm.message)+" "),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('button',{staticClass:"btn btn-default btn-block",on:{"click":function($event){_vm.showAlert=false}}},[_vm._v("Ok")])])]):_vm._e(),(_vm.changed)?_c('div',{staticClass:"offcanvas-footer"},[_c('button',{staticClass:"btn btn-success btn-lg btn-block",on:{"click":function($event){return _vm.$refs.auth.check(_vm.saveChanges)}}},[_vm._v("Save Changes")])]):_vm._e(),_c('unsaved-changes',{attrs:{"show":_vm.showUnsavedChanges},on:{"close":_vm.forceCloseOffcanvas,"hide":function($event){_vm.showUnsavedChanges=false},"save":function($event){return _vm.$refs.auth.check(_vm.saveChangesAndClose)}}}),_c('password-modal',{ref:"auth"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }