const $ = require('jquery');
import guard from './guard.js';
import store from './store.js';
import moment from 'moment';
import env from './environment.js';
import Phone_validation from './mixins/phone_validation.js';

var mixin = {
	data(){
		return {
			moment: moment,
			contact_sensor_type_ids: [36,37],
			composite_sensor_type_ids: [45,46]
		}
	},
	methods: {
		isContactSensor(type_id){
			return this.contact_sensor_type_ids.indexOf(Number(type_id)) > -1;
		},
		isCompositeSensor(type_id){
			return this.composite_sensor_type_ids.indexOf(Number(type_id)) > -1;
		},
		homePage(){
			console.log('triggered');
		},
		useDesktopSite(){
			//set preference cookie
			//window.localStorage.setItem('site_preference','desktop');
			var redirect_base = env.path().replace('/mobile/','')
			if(!this._.isEmpty(this.$store.state.user)){
				//user is logged in so authenticate them on desktop site
				window.location = redirect_base+'/jwt_auth/login?preference_change&auth='+this.$store.state.token;
			}else{
				//no user so just redirect
				window.location = redirect_base+'/auth/login?preference_change';
			}
		},
		toggleMenu: function(side){
			switch(side){
				case "left":
					if(this.$root.leftMenuState == ""){
						this.$root.leftMenuState = "show";
					}else{
						this.$root.leftMenuState = "";
					}
				break;
				case "right":
					if(this.$root.rightMenuState == ""){
						this.$root.rightMenuState = "show";
					}else{
						this.$root.rightMenuState = "";
					}
				break;
				case "close":
					this.$root.leftMenuState = "";
					this.$root.rightMenuState = "";
				break;
			}
		},
		searchArrayObjects: function(objects,input){
			if( input == "" ){
				return objects;
			}
			var objs = [];
			objects.find(function(obj){
				$.each(obj, function(k,v){
					if(v !== null){
						if(typeof v == 'object'){
							$.each(v, function(k2, entity){
								if( String(entity.id).toLowerCase().indexOf(input.toLowerCase()) > -1 ){
									objs.push(obj);
									return false;
								}
							});
						}else if( String(v).toLowerCase().indexOf(input.toLowerCase()) > -1 ){
							objs.push(obj);
							return false;
						}
					}
				});
			});

			return objs;
		},
		//here only to make within components
		userAllowedAccess: function(path){
			return guard.userAllowedAccess(path,store.state.user.group);
		},
		reloadUserCookie: function(){
			var self = this;
			return this.axios.get(this.$root.controllers_base_path+"auth/cookie_refresh").then(function(response){
				if(typeof response.data.status == 'undefined'){
					self.$store.dispatch('setToken', response.data);
				}
			});
		},
		controller(path){
			return this.$root.controllers_base_path+path+'?'+this.moment().unix();
		},
		backend_base_url(path){
			if(typeof path == "undefined"){
				path = '';
			}
			return env.path().replace('mobile/','')+path;
		},
		/*moment(x){
			return moment(x);
		},*/
		userIs(v){
			let user_group = Number(store.state.user.group);
			switch(v){
				case 'companyAdmin':
					if( [3].indexOf( user_group ) > -1  ){
						return true;
					}else{
						return false;
					}
				// eslint-disable-next-line
				break;
				case 'adminUp':
					if( [3,4,5,7,9].indexOf( user_group ) > -1  ){
						return true;
					}else{
						return false;
					}
				// eslint-disable-next-line
				break;
				case 'generalUp':
					if( [2,3,4,5,7,9].indexOf( user_group ) > -1  ){
						return true;
					}else{
						return false;
					}
				// eslint-disable-next-line
				break;
				case 'superAdmin':
					if( [4,5,9].indexOf( user_group ) > -1  ){
						return true;
					}else{
						return false;
					}
				// eslint-disable-next-line
				break;
			}
		},
		isValidEmail: function(email){
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
        Phone_validation
	},
	filters:{
		unixToDate: function(value){
			if (value) {
				return moment.unix(value).format('DD/MM/YYYY hh:mm')
			}
		}
	}
} 


export default mixin;
