<template>
<div>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeCanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Realtime Sensor Settings</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">
				<div class="form-group">
					<label>Sorting Behaviour</label>
					<select class="form-control" v-model="settingsCopy.save_sort">
						<option value="0">Default Behaviour</option>
						<option value="1">Remember Sorting</option>
						<option value="2">User Specified</option>
					</select>
				</div>
				<div class="sorting_wrapper" :style="{height: sortWrapperHeight}">
					<div class="sorting_block py-3" ref="user_specified">
						<div class="form-group flex-grow-1">
							<label>Primary</label>
							<div class="d-flex">
								<select class="form-control mr-1" v-model="settingsCopy.sort_override.sort_by">
									<option v-for="(option,index) in sort_options" :value="option.value" :key="index">{{option.label}}</option>
								</select>
								<button class="btn btn-light" @click="settingsCopy.sort_override.sort_direction = (settingsCopy.sort_override.sort_direction == 'asc' ? 'desc' : 'asc')"><font-awesome-icon :icon="'sort-amount-'+(settingsCopy.sort_override.sort_direction == 'asc' ? 'up' : 'down')" fixed-width /></button>
							</div>
						</div>
						<div class="form-group flex-grow-1" v-if="showSecondarySort">
							<label>Secondary</label>
							<div class="d-flex">
								<select class="form-control mr-1" v-model="settingsCopy.sort_override.secondary_sort_by">
									<option v-for="(option,index) in secondarySortOptions" :value="option.value" :key="index">{{option.label}}</option>
								</select>
								<button class="btn btn-light" @click="settingsCopy.sort_override.secondary_sort_direction = (settingsCopy.sort_override.secondary_sort_direction == 'asc' ? 'desc' : 'asc')"><font-awesome-icon :icon="'sort-amount-'+(settingsCopy.sort_override.secondary_sort_direction == 'asc' ? 'up' : 'down')" fixed-width /></button>
							</div>
						</div>
					</div>
				</div>
				<switch-input v-model="settingsCopy.alarm_status">Alarm Status<br/><small>Display current alarm status information per sensor</small></switch-input>
				<switch-input v-model="settingsCopy.remedial_actions">Remedial Actions<br/><small>Display a count of pending remedial actions per sensor</small></switch-input>
			</div>
		</div>
		<div class="offcanvas-footer" v-if="hasChanged">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(save)" :disabled="isSaving || !allowChanges">
				<span v-if="!isSaving">Save Changes</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>
	</div>

	<unsaved-changes :show="showWarning" v-on:hide="showWarning=false" v-on:close="forceCloseCanvas" v-on:save="$refs.auth.check(save)"/>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import SwitchInput from '../../FormElements/Switch.vue';
import UnsavedChanges from '../../UnsavedChangesAlert.vue';


export default{
	props:['state','settings'],
	components:{
		SwitchInput,
		UnsavedChanges
	},
	data(){
		return {
			allowChanges: false,
			settingsCopy:{
				sort_override:{
					sort_by:0,
					secondary_sort_by:0,
					sort_direction:'asc',
					secondary_sort_direction:'asc'
				}
			},
			showWarning:false,
			sort_options:[
				{value:'0',label:'Reading Time'},
				{value:'1',label:'Reading Value'},
				{value:'2',label:'Out of Bounds'},
				{value:'3',label:'Location Name'},
				{value:'4',label:'Sensor ID'},
				{value:'5',label:'Sensor Type'},
				{value:'6',label:'Sensor Stale'},
				{value:'7',label:'Alarm Status'},
				{value:'8',label:'Pending Remedials'},
				{value:'9',label:'Battery Level'},
				{value:'10',label:'Alarm Group'}
			],
			sortWrapperHeight:0,
			SaveRequest: null
		}
	},
	beforeMount(){
		this.reset();
	},
	computed:{
		showSecondarySort(){
			return ([2,3,5,6,7,8,9,10].indexOf( Number(this.settingsCopy.sort_override.sort_by) ) > -1 ? true : false);
		},
		hasChanged(){
			return !this._.isEqual(this.settingsCopy,this.settings);
		},
		secondarySortOptions(){
			return this.sort_options.filter(option=>{
				return (option.value != '5' ? true : false);
			});
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		}
	},
	methods:{
		reset(){
			this.settingsCopy = {
				sort_override:{
					sort_by:0,
					secondary_sort_by:0,
					sort_direction:'asc',
					secondary_sort_direction:'asc'
				}
			};
		},
		closeCanvas(){
			if( this.hasChanged ){
				this.showWarning = true;
			}else{
				this.$emit('close');
			}
		},
		forceCloseCanvas(){
			this.showWarning = false;
			this.reset();
			this.$emit('close');
		},
		save(){

			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			var self = this;
			var f = new FormData();
			f.append('save_sort',this.settingsCopy.save_sort);
			f.append('sort_by',this.settingsCopy.sort_override.sort_by);
			f.append('secondary_sort_by',
				(this.showSecondarySort ? this.settingsCopy.sort_override.secondary_sort_by : 1)
			);
			f.append('sort_direction',this.settingsCopy.sort_override.sort_direction);
			f.append('secondary_sort_direction',
				(this.showSecondarySort ? this.settingsCopy.sort_override.secondary_sort_direction : 'asc')
			);
			f.append('alarm_status',this.settingsCopy.alarm_status);
			f.append('remedial_actions',this.settingsCopy.remedial_actions);
			f.append('secondary_flag',
				(this.showSecondarySort ? 1 : 0)
			);

			f.append('realtime_sensor_settings',true);

			this.axios.post( this.controller('auth/save_display_preferences') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				if( res.data.status == 'success' ){
					self.$emit('reload');
					self.forceCloseCanvas();
				}

				//reset cancelToken to null
				self.SaveRequest = null;
			});
		}
	},
	watch:{
		state(a){
			if(a == 'open'){
				this.settingsCopy = this._.cloneDeep(this.settings);

				//fix null sort_override to 0
				if(this.settingsCopy.sort_override == null){
					this.settingsCopy.sort_override={};
				}
			}
		},
		settingsCopy:{
			handler(){
				if( !this._.isEmpty(this.settingsCopy) && this.settingsCopy.sort_by != null ){
				//	this.showSecondarySort = [2,3,5,6,7,8,9,10].indexOf( Number(this.settingsCopy.sort_override.sort_by) ) > -1 ? true : false;
				}

				var self = this;
				//delay required for the correct height to be retreived from the dom
				setTimeout(function(){
					self.sortWrapperHeight = (self.settingsCopy.save_sort == 2 ? self.$refs.user_specified.clientHeight+2 : 0)+'px'
				},100);

			},
			deep:true
		}
	}
}
</script>

<style lang="scss" scoped>
.offcanvas{
	z-index:1033;
}
.sorting_wrapper{
	overflow:hidden;
	height:0;
	-moz-transition: height 0.5s ease;
	-webkit-transition: height 0.5s ease;
	-o-transition: height 0.5s ease;
	transition: height 0.5s ease;

	.sorting_block{
		border-top:1px solid #ccc;
		border-bottom:1px solid #ccc;
	}
}
</style>
