<template>
    <div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Edit Composite Sensor</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">
                <div>
					<label class="float-left">Date Added</label>
					<div class="float-right">{{date_added}}</div>
					<div style="float:none;clear:both;"></div>
				</div>
				<hr class="mt-0"/>
                <div class="form-group">
					<label>Location</label>
					<input type="text" class="form-control" v-model="sensorCopy.name"/>
					<div class="error" v-if="validationErrors.name">{{validationErrors.name}}</div>
				</div>

                <div class="row" v-if="userIs('adminUp')">
					<div class="form-group col">
						<label>Alarm Group</label>
						<select class="form-control" v-model="sensorCopy.alarm_group_id">
							<option value="0">Non-Alarmed</option>
							<option v-for="group in alarm_groups" :value="group.id" :key="group.id">{{group.name}}</option>
						</select>
						<div class="error" v-if="validationErrors.alarm_group">{{validationErrors.alarm_group}}</div>
					</div>
					<div class="form-group col">
						<label>SDR Group</label>
						<select class="form-control" v-model="sensorCopy.sdr_group_id">
							<option value="0">Non-Alarmed</option>
							<option v-for="group in sdr_groups" :value="group.id" :key="group.id">{{group.name}}</option>
						</select>
						<div class="error" v-if="validationErrors.sdr_group">{{validationErrors.sdr_group}}</div>
					</div>
				</div>
            </div>            
        </div>

        <unsaved-changes :show="showAlert && state=='open'" v-on:close="forceCloseOffcanvas" :save-disabled="!hasChanged || !_.isEmpty(validationErrors)" v-on:hide="showAlert=false" v-on:save="$refs.auth.check(saveChanges)"/>
        
        <div class="offcanvas-footer" v-if="hasChanged && _.isEmpty(validationErrors)">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="isSaving || !allowChanges">
				<span v-if="!isSaving">Save Changes</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>

        <alert v-if="showResponse && state=='open'">
			<div slot="title">Important</div>
			{{responseMessage}}
			<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
		</alert>

        <password-modal ref="auth" v-on:loaded="allowChanges=true"/>
    </div>
</template>

<script>
import Alert from '../Alert.vue';
import UnsavedChanges from '../UnsavedChangesAlert.vue';

export default {
    components:{
		Alert,
		UnsavedChanges
	},
    props:['state','sensor','alarm_groups','sdr_groups'],
    data(){
        return {
            sensorCopy:{},
            validationErrors:[],
            allowChanges:false,
            SaveRequest: null,
            showResponse: false,
            showAlert:false,
            responseMessage:'',
        }
    },
    computed:{
        date_added(){
			if(this.sensorCopy.entities && this.sensorCopy.entities[0].date_added_unix){
				return this.moment.unix(this.sensorCopy.entities[0].date_added_unix).format('DD/MM/YY HH:mm');
			}else{
				return '';
			}
        },
        hasChanged(){
			return !this._.isEqual(this.sensor,this.sensorCopy);
        },
        isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		},
    },
    methods:{
        closeOffcanvas(){
            if( this.hasChanged ){
				this.showAlert = true;
			}else{
                this.$parent.compositeOffcanvasState = "closed";
                this.$parent.selectedCompositeSensor = null;
            }
        },
        forceCloseOffcanvas(){
			this.sensorCopy = {};
			this.showAlert = false;
			this.$parent.compositeOffcanvasState = 'closed';
		},
        createCloneSensor(){
			this.sensorCopy = this._.cloneDeep(this.sensor);
        },
        validateForm(){
            //clear the array
            this.validationErrors = {};
            if( !this._.isEmpty(this.sensorCopy) ){
                //location name
                if( this.sensorCopy.name.length > 255 ){
                    this.validationErrors.name = 'Too long';
                }
            }
        },
        saveChanges(){
            var self = this;
			var f = new FormData();

			for( var key in this.sensorCopy ){
				f.append(key, this.sensorCopy[key]);
			}

			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
            }
            
            //Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

            this.showAlert=false;
            
            this.axios.post(this.controller('realtime_sensors/save_composite_sensor_settings'), f, {
				cancelToken: this.SaveRequest.token
			}).then(function(response){
				if(response.data.status == 'failed' && !response.data.redirect){
                    //check for response message
					if( response.data.message != '' ){
						self.responseMessage = response.data.message;
						//show the response
						self.showResponse = true;
					}
				}else if(response.data.status == 'success'){
					//check for response message
					if( response.data.message != '' ){
						self.responseMessage = response.data.message;
						//show the response
						self.showResponse = true;
					}

					//update the selectedSensor variable in the parent view
					//this is required as the current view is bound the this objects data
					self.$emit('update-selected-composite',self.sensorCopy);

					//reload sensor data
					self.$parent.fetchSensors();
				}

				self.SaveRequest = null;
			});
        }
    },
    watch:{
        state(a){
            if(a == 'open'){
                //create a clone of sensor
                this.createCloneSensor();
            }
        },
        sensorCopy:{
				handler(){
					this.validateForm();
				},
				deep:true
		}
    }
}
</script>