<template>
    <div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Alarm Information</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">
                <loading :state="loading"/>
                <div v-if="!loading && !_.isEmpty(info)" class="pb-4">
                    <div class="r">
                        <label>Sensor ID:</label>
                        {{info.sensor.id}}
                    </div>
                    <div class="r">
                        <label>Location Name:</label>
                        {{info.sensor.location_name}}
                    </div>
                    <div class="heading">Alarm</div>
                    <div class="r">
                        <label>Out of Bounds Start:</label>
                        {{ unixToDateTime(info.sensor.oob_start_timestamp_unix) }}
                        <span v-if="!info.sensor.oob_start_timestamp_unix">-</span>
                    </div>
                    <div class="r">
                        <label>First Alarm Attempt:</label>
                        {{ unixToDateTime(info.sensor.initial_alarm_attempt_unix) }}
                        <span v-if="!info.sensor.initial_alarm_attempt_unix">-</span>
                    </div>
                    <div class="r">
                        <label>Reading:</label>
                        <template v-if="!isContactSensor(info.sensor.sensor_type_id)">
                        {{ (info.sensor.latest_reading ? info.sensor.latest_reading : '') }}<span v-if="info.sensor.latest_reading" v-html="info.sensor.alarming_display_unit"></span>
                        </template>
                        <template v-if="isContactSensor(info.sensor.sensor_type_id)">
                        {{contact_sensor_label}}
                        </template>
                        <span v-if="!info.sensor.latest_reading">-</span>
                    </div>
                    <template v-if="!isContactSensor(info.sensor.sensor_type_id)">
                        <div class="r">
                            <label>Low Threshold:</label>
                            {{ info.sensor.threshold_low }}<span v-html="info.sensor.alarming_display_unit"></span>
                            <span v-if="!info.sensor.threshold_low">-</span>
                        </div>
                        <div class="r">
                            <label>High Threshold:</label>
                            {{ info.sensor.threshold_high }}<span v-html="info.sensor.alarming_display_unit"></span>
                            <span v-if="!info.sensor.threshold_high">-</span>
                        </div>
                    </template>
                    <template v-if="isContactSensor(info.sensor.sensor_type_id)">
                        <div class="r">
                            <label>Alarm On:</label>
                            {{alarm_on}}
                        </div>
                    </template>
                    <div class="r">
                        <label>Latest Reading Time:</label>
                        {{ unixToDateTime(info.sensor.last_update_timestamp_unix) }}
                        <span v-if="!info.sensor.last_update_timestamp_unix">-</span>
                    </div>
                    <template v-if="Number(info.persistent_alarming) == 1">
                        <div class="heading">Re-alarming</div>
                        <div class="r">
                            <label>Latest Alarm Attempt:</label>
                            {{ unixToDateTime(info.sensor.latest_alarm_attempt_unix) }}
                            <span v-if="!info.sensor.latest_alarm_attempt_unix">-</span>
                        </div>
                        <div class="r">
                            <label>Time Alarming Suspended:</label>
                            {{ unixToDateTime(info.sensor.realarming_suspended_unix) }}
                            <span v-if="!info.sensor.realarming_suspended_unix">-</span>
                        </div>
                        <div class="r">
                            <label>Re-alarming Suspended By:</label>
                            {{ (info.sensor.realarming_suspended_by_name ? info.sensor.realarming_suspended_by_name : '') }}
                            <span v-if="!info.sensor.realarming_suspended_by_name">-</span>
                        </div>
                        <div class="r">
                            <label>Re-alarming Expiry:</label>
                            {{ alarmingTimeout }}
                            <span v-if="!info.sensor.realarming_timeout">-</span>
                        </div>
                    </template>

                </div>
            </div>
		</div>

        <div class="offcanvas-footer" v-if="!_.isEmpty(info) && Number(info.sensor.realarming_delay) > 0 && Number(info.persistent_alarming) == 1 && showRealarmButton">
			<button class="btn btn-lg btn-block" :class="{'btn-dark':info.sensor.realarming_suspended_unix==null,'btn-success':info.sensor.realarming_suspended_unix}" v-on:click="showConfirm=true">
				<span v-if="!isSaving">{{info.sensor.realarming_suspended_unix==null ? 'Stop ' : 'Resume '}} Re-alarming</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>
        <alert v-if="showConfirm">
            <div slot="title">
                Confirm
            </div>
            Re-alarming will be {{info.sensor.realarming_suspended_unix==null ? 'stopped' : 'started'}} for this alarm event.<br/>Confirm to {{info.sensor.realarming_suspended_unix==null ? 'suspend' : 'resume'}} re-alarming
            <div slot="buttons">
                <button class="btn btn-default btn-block" @click="showConfirm=false">Cancel</button>
                <button class="btn btn-danger btn-block" @click="save">Confirm</button>
            </div>
        </alert>

        <alert v-if="!_.isEmpty(error)">
            <div slot="title">Error</div>
            {{error}}
            <div slot="buttons">
                <button class="btn btn-default btn-block" @click="error='';showConfirm=false;">Ok</button>
            </div>
        </alert>
	</div>
</template>

<style lang="scss" scoped>
//@import "../assets/_vars.scss";
label{
    font-size:0.8em;
    display:block;
    margin-bottom:0px;
}
.heading{
    background:#eee;
    padding:15px;
    margin:10px -15px;
    font-weight:bold;
}
.r{
    margin:5px 0;
}
</style>

<script>
import Loading from '../Loading.vue';
import Alert from '../Alert.vue';
export default{
    props:['state','sensorId'],
    components:{
        Loading,
        Alert
    },
    data(){
        return {
            loading:false,
            info:null,
            isSaving:false,
            showConfirm:false,
            error:'',
            showRealarmButton:true
        }
    },
    computed:{
        alarmingTimeout(){
            if( Number(this.info.sensor.realarming_timeout) == 0 ){
                return 'Indefinite';
            }else{
                return this.unixToDateTime(Number(this.info.sensor.realarming_timeout)+Number(this.info.sensor.initial_alarm_attempt_unix));
            }
        },
        contact_sensor_label(){
            var labels_ = this.info.contact_labels.filter(label => {
                return Number(label.id)==Number(this.info.sensor.contact_sensor_label_id);
            });

            if(labels_[0]){
                var label = labels_[0];
                if(Number(this.info.sensor.latest_reading)==0){
                    return label.reading_0_label;
                }else{
                    return label.reading_1_label;
                }
            }else{
                return this.info.sensor.latest_reading+'Signal';
            }
        },
        alarm_on(){
            var labels_ = this.info.contact_labels.filter(label => {
                return Number(label.id)==Number(this.info.sensor.contact_sensor_label_id);
            });

            if(labels_[0]){
                var label = labels_[0];
                if(Number(this.info.sensor.threshold_high)==0 && Number(this.info.sensor.threshold_low)==0){
                    return label.reading_1_label;
                }else if(Number(this.info.sensor.threshold_high)==1 && Number(this.info.sensor.threshold_low)==1){
                    return label.reading_0_label;
                }else{
                    return 'None';
                }
            }else{
                if(Number(this.info.sensor.threshold_high)==0 && Number(this.info.sensor.threshold_low)==0){
                    return 'Reading 1';
                }else if(Number(this.info.sensor.threshold_high)==1 && Number(this.info.sensor.threshold_low)==1){
                    return 'Reading 0';
                }else{
                    return 'None';
                }
            }
        }
    },
    methods:{
        unixToDateTime(time){
            if( typeof time == 'undefined' || time == '' || time == null || Number(time) == 0 ){
                return '';
            }else{
                return this.moment.unix(time).format('DD/MM/YYYY HH:mm');
            }
        },
        closeOffcanvas(){
			this.$parent.alarmInformationSensorId = null;
		},
        save(){
            var self = this;
            if( this.info.sensor.realarming_suspended_unix === null ){
                this.axios.get( this.controller('remedial_actions/suspend_realarming/'+this.sensorId) ).then(function(response){
                    var json = response.data;
                    if( json.status == 'error' ){
                        self.error = json.msg;
                    }else{
                        self.showRealarmButton=false;
                        self.getInfo();
                    }
                    self.showConfirm=false;
                });
            }else{
                this.axios.get( this.controller('remedial_actions/resume_realarming/'+this.sensorId) ).then(function(response){
                    var json = response.data;
                    if( json.status == 'error' ){
                        self.error = json.msg;
                    }else{
                        self.showRealarmButton=false;
                        self.getInfo();
                    }
                    self.showConfirm=false;
                });
            }
        },
        getInfo(){
            //load data
            var self = this;
            this.loading=true;
            this.axios.get( this.controller('remedial_actions/alarm_information/'+this.sensorId) ).then(function(response){
                self.info = response.data;
                self.loading=false;
            });
        }
    },
    watch:{
        state(a){
            if(a == 'open'){
                this.showRealarmButton=true;
                this.getInfo();
            }
        }
    }
}
</script>