<template>
<div>
	<div class="container-fluid grey py-3">
		<label>Filter</label>
		<select class="form-control" v-model="filter">
			<option value="">All</option>
			<option value="2">Normal</option>
			<option value="3">In Alarm</option>
			<option value="5">Enter Alarm</option>
		</select>
	</div>
	<div class="container-fluid">
		<div class="row text-center">
			<table class="col table table-striped">
				<thead>
					<tr>
						<th colspan="3">AM</th>
					</tr>
					<tr>
						<th>Time</th>
						<th>Reading</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="reading in am" :key="reading.reading_id">
						<td>{{ moment.unix(reading.reading_timestamp_unix).format('HH:mm:ss') }}</td>
						<td>
							<template v-if="!isContactSensor(sensor.sensor_type_id)">{{Number(reading.reading).toFixed(2)}}<span v-html="reading.display_unit"></span></template>
							<template v-if="isContactSensor(sensor.sensor_type_id)">{{contactSensorLabel(sensor.contact_sensor_label_id,reading.reading)}}</template>
						</td>
						<td>
							<template v-if="Number($store.state.user.preferences.realtime_sensors.sensor_node_alarm_status) == 1">
								<img :src="'./assets/misc_icons/exclaim.png'" v-if="['5','3'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/warning.png'" v-if="['4'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/persistent-alarm-icon-red.png'" v-if="['10'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/persistent-alarm-icon-grey.png'" v-if="['11'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/tick.png'" v-if="['1'].indexOf(reading.alarm_status_id) > -1"/>
							</template>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="col table table-striped">
				<thead>
					<tr>
						<th colspan="3">PM</th>
					</tr>
					<tr>
						<th>Time</th>
						<th>Reading</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="reading in pm" :key="reading.reading_id">
						<td>{{ moment.unix(reading.reading_timestamp_unix).format('HH:mm:ss') }}</td>
						<td>
							<template v-if="!isContactSensor(sensor.sensor_type_id)">{{Number(reading.reading).toFixed(2)}}<span v-html="reading.display_unit"></span></template>
							<template v-if="isContactSensor(sensor.sensor_type_id)">{{contactSensorLabel(sensor.contact_sensor_label_id,reading.reading)}}</template>
						</td>
						<td>
							<template v-if="Number($store.state.user.preferences.realtime_sensors.sensor_node_alarm_status) == 1">
								<img :src="'./assets/misc_icons/exclaim.png'" v-if="['5','3'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/warning.png'" v-if="['4'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/persistent-alarm-icon-red.png'" v-if="['10'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/persistent-alarm-icon-grey.png'" v-if="['11'].indexOf(reading.alarm_status_id) > -1"/>
								<img :src="'./assets/misc_icons/tick.png'" v-if="['1'].indexOf(reading.alarm_status_id) > -1"/>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
</template>

<script>
export default{
	props:['data','currentSelection','sensors','contact_labels'],
	data(){
		return{
			filter:''
		}
	},
	computed:{
		filtered(){
			var _this = this;
			return this.data.filter(r=>{
				if(_this.filter==''){
					return true;
				}else{
					if(_this.filter == r.alarm_status_id){
						return true;
					}else{
						return false;
					}
				}
			});
		},
		am(){
			return this.filtered.filter(r=>{
				if( this.moment.unix(r.reading_timestamp_unix).format('a') == 'am' ){
					return true;
				}else{
					return false;
				}
			})
		},
		pm(){
			return this.filtered.filter(r=>{
				if( this.moment.unix(r.reading_timestamp_unix).format('a') == 'pm' ){
					return true;
				}else{
					return false;
				}
			})
		},
		sensor(){
			return this.sensors.filter(sensor => {
				return Number(this.currentSelection.id)==Number(sensor.id);
			})[0];
		}
	},
	methods:{
		contactSensorLabel(label_id,reading){
			var self=this;
			var label_ = this.contact_labels.filter(label => {
				return Number(label.id) == Number(self.sensor.contact_sensor_label_id);
			})[0];
			if(!label_){
				label_ = {
					reading_0_label: '0Signal',
					reading_1_label: '1Signal',
				};
			}
			if( Number(reading) == 0 ){
				return label_.reading_0_label;
			}else{
				return label_.reading_1_label;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.container-fluid.grey{
	overflow:hidden;
	background:#ddd;
}
</style>