<template>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center flex-row">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeGraph"><font-awesome-icon icon="chevron-left" /></button>
				<div class="mx-2" style="white-space:nowrap;">
					<button type="button" class="btn btn-light mr-1" :class="{active: currentView=='graph'}" v-on:click="currentView='graph'"><font-awesome-icon icon="chart-line" /></button>
					<button type="button" class="btn btn-light" :class="{active: currentView=='table'}" v-on:click="currentView='table'"><font-awesome-icon icon="list-ul" /></button>
				</div>
				<select class="form-control" v-model="currentSelectedValue">
					<optgroup v-for="(page,index) in selectList" :label="moment(page.date).format('DD/MM/YYYY')" :key="index">
						<option v-for="(sensor,index2) in page.sensors" :key="index2" :value="sensor.value">{{sensor.id}}: {{sensor.location}}</option>
					</optgroup>
				</select>
				<div class="btn-group ml-2">
					<button type="button" name="button" class="btn btn-light px-3" @click="currentSelectedValue--" :disabled="currentSelectedValue==0"><font-awesome-icon icon="caret-left"/></button>
					<button type="button" name="button" class="btn btn-light px-3" @click="currentSelectedValue++" :disabled="currentSelectedValue==highestPossibleValue"><font-awesome-icon icon="caret-right"/></button>
				</div>
			</div>
		</nav>
		<div class="offcanvas-body" :class="{scrollx:currentView=='graph',scrolly:currentView=='table'}">
			<GChart :class="{'d-none':_.isEmpty(chartData) || currentView!='graph'}" style="position:relative;"
					:settings="{ packages: ['corechart'] }"
					type="LineChart"
					:data="chartData"
					:options="chartOptions"
					@ready="onChartReady"
					ref="graphContainer"
					:events="chartEvents"
				/>
			
			<table-view :data="rawData" :currentSelection="currentSelection" :sensors="sensors" :contact_labels="contact_labels" v-if="!_.isEmpty(rawData) && currentView=='table'"/>
			<div v-if="loading" class="h-100 d-flex align-items-center justify-content-center">
				<loading :state="true" size="1.5"/>
			</div>
			<div v-if="responseMessage" class="h-100 d-flex align-items-center justify-content-center">
				<h4 class="text-center mx-3">{{responseMessage}}</h4>
			</div>
		</div>

		<div class="offcanvas-footer d-flex justify-content-between align-items-center" v-if="state=='open'">
			<div class="pl-3">{{moment(currentSelection.date,"YYYY-MM-DD").format("DD/MM/YYYY")}}</div>
			<!--<div v-if="fetchingData">Fetching data <font-awesome-icon icon="spinner" spin/></div>
			<button class="btn btn-light" @click="$refs.graph.unzoom()">Re-center</button>-->
		</div>
	</div>
</template>

<script>
import Loading from '../Loading.vue';
import TableView from './DailyGraphTable.vue';
import { GChart } from 'vue-google-charts';
const $ = require('jquery');

export default{
	components:{
		Loading,
		TableView,
		GChart
	},
	props:['state','sensors','dateRange'],
	data(){
		return {
			rawData: [],
			dateRangeCopy:null,
			//graphData: {},
			highestPossibleValue: null,
			currentSelectedValue: 0,
			loading: false,
			//this is user to display a message when the user drags out of the current graph range and more data is required
			fetchingData:false,
			responseMessage:'',
			//this holds the cancellable promise used to cancel axios request
			GraphRequest:null,
			currentView:'graph',
			width: '100%',
			//when the graph is dragged, the data reload is set in a timeout
			//this is used to try to catch the last event (when the drag stops)
			dragTimeout:null,

			GoogleGraphObject:null,
			ChartObject:null,
			chartEvents:{
				'ready': () => {
					// handle event here
					//this.placeMarkers();
				}
			},
			graphHeight:0,
			graphWidth:0,
			chartData: [],
			chartOptions:{
				width: 0,
				height: 0,
				series: {
					0: { color: 'green' },
					1: { color: 'blue' },
					2: { color: 'red' },
				},
				chartArea: {width:0,left:50,top:20,bottom:40,height:0},
				animation:{
					duration: 100,
					easing: 'inAndOut',
					startup: true
				},
				intervals: { 'style':'area' },
				legend: { position: 'top', alignment: 'end' },
				explorer: {
					keepInBounds: true,
					maxZoomIn: .01,
					maxZoomOut: 1
				},
				vAxis: {
					viewWindowMode:'explicit',
					viewWindow: {
						max:250,
						min:-200
					},
					ticks: null
				},
				hAxis:{
					viewWindow: {
						max:null,
						min:null
					},
					gridlines: {
						count: -1,
						units: {
							days: {format: ['d/M/yy']},       // <-----
							hours: {format: ['HH:mm', 'ha']}, // <-----
						}
					},
					minorGridlines: {
						count: -1,
						units: {
							hours: {format: ['HH:mm', 'ha']},       // <-----
							minutes: {format: ['HH:mm a', ':mm']} // <-----
						}
					}
				}
			},
			contact_labels:[]
		}
	},
	mounted(){
		this.setGraphSize();
		var _this = this;
		window.addEventListener("resize", ()=>{
			setTimeout(function(){
				_this.setGraphSize();
			},200);
		});
	},
	methods:{
		closeGraph(){
			this.rawData = [];
			this.$emit('close');
		},
		setGraphSize(){
			this.graphHeight = (window.innerHeight-120);
			this.graphWidth = (window.innerWidth-20);

			this.chartOptions.chartArea.width=this.graphWidth;
			this.chartOptions.width=this.graphWidth;
			this.chartOptions.chartArea.height=this.graphHeight;
			this.chartOptions.height=this.graphHeight;
		},
		onChartReady (chart, google) {
			//store this for use later
			this.GoogleGraphObject = google;
			this.ChartObject = chart;
			//window
		},
		fetchGraphData(overwrite){
			var self = this;
			if( typeof(overwrite) == 'undefined' ){
				overwrite=true;
			}

			this.rawData = [];
			this.chartData = [];

			var f = new FormData();
			f.append('sensor_id', this.currentSelection.id );
			f.append('ts_from', this.moment( this.currentSelection.date ).unix() );

			if(overwrite){
				//show loading
				this.loading=true;
				//clear graph data & response message
				this.rawData = {};
				this.responseMessage = '';
			}

			//cancel existing graph requests if promise exists
			if(this.GraphRequest){
				this.GraphRequest.cancel();
			}

			//Create new cancellable request
			this.GraphRequest = this.axios.CancelToken.source();

			this.axios.post(this.$root.controllers_base_path+'realtime_sensors/generate_sensor_report?'+this.moment().unix(),f,{
				cancelToken: this.GraphRequest.token
			}).then(function(response){
				if(response.data.status != 'failed'){
					if(overwrite){
						self.rawData = response.data.data;
						self.contact_labels = response.data.contact_labels;
						self.drawGraph();
					}
				}else{
					if(overwrite){
						self.responseMessage = response.data.message;
					}
				}

				//reset cancelToken to null
				self.GraphRequest = null;

				//hide loading spinner
				self.loading=false;
				self.fetchingData=false;
			});
		},
		drawGraph(){
			/*
			Do we have some data to work with
			*/
			if( this.rawData.length > 0 ){
				//used to display the correct range on graph
				var lowest_value = null;
				var highest_value = null;

				this.chartData = new this.GoogleGraphObject.visualization.DataTable();
				
				this.chartData.addColumn('date', 'Date');
				this.chartData.addColumn('number', 'Reading');
				this.chartData.addColumn('number', 'Low Threshold');
				this.chartData.addColumn('number', 'High Threshold');
				
				//is this contact sensor(s)?
				//if so we need to show labels rather than the values
				var sensor = this.sensors.filter(sensor => {
					return Number(this.currentSelection.id) == Number(sensor.id);
				})[0];
				if(this.isContactSensor(sensor.sensor_type_id)){
					//get the labels for the sensors label_id
					var labels = this.contact_labels.filter(label => {
						return Number(label.id)==Number(sensor.contact_sensor_label_id);
					})[0];
					if(!labels){
						labels = {
							reading_0_label: '0',
							reading_1_label: '1',
						};
					}

					this.chartOptions.vAxis.ticks=[
						{v: 0, f: labels.reading_0_label},
						{v: 1, f: labels.reading_1_label}
					];
				}else{
					this.chartOptions.vAxis.ticks=null;
				}

				var i = 0;
				var rows=[];
				this.rawData.forEach(sensor => {
					if(sensor){
						if(!rows[i]){
							rows[i]=[];
						}

						rows[i][0]=new Date(sensor.reading_timestamp_unix*1000);
						rows[i][1]= +Number(sensor.reading).toFixed(2);
						rows[i][2]= +Number(sensor.threshold_low).toFixed(2);
						rows[i][3]= +Number(sensor.threshold_high).toFixed(2);

						//window.console.log(this_row)
						//update the lowest / highest values
						if( lowest_value > rows[i][1] || !lowest_value ){
							lowest_value = rows[i][1];
						}
						if( lowest_value > rows[i][2] ){
							lowest_value =rows[i][2];
						}
						if( highest_value <  rows[i][1] || !highest_value ){
							highest_value = rows[i][1];
						}
						if( highest_value < rows[i][3] ){
							highest_value = rows[i][3];
						}
					}
					i++;
				});

				//create the row
				this.chartData.addRows(rows);

				//set the chart view max and min
				var pad = (highest_value-lowest_value)*0.1;
				this.chartOptions.vAxis.viewWindow.min = (lowest_value-pad).toFixed(2);
				this.chartOptions.vAxis.viewWindow.max = (highest_value+pad).toFixed(2);

				//set the date time format for the tooltip which appears when clicking on a point
				var formatter = new this.GoogleGraphObject.visualization.DateFormat({ 
					pattern: 'dd/MM/YY HH:mm:ss'
				}); 
				formatter.format(this.chartData , 0);
			}
		},
		placeMarkers(){
			//$('.alarm',this.$refs.graphContainer.$el).remove();
			var _this=this;
			
			var cli = _this.ChartObject.getChartLayoutInterface();
			//var chartArea = cli.getChartAreaBoundingBox();

			_this.alarms.forEach(alarm => {
				var y = Math.floor(cli.getYLocation(alarm[1])) - 30 + "px";
				var x = Math.floor(cli.getXLocation(alarm[0])) - 10 + "px";

				//add the alarm div
				if( $('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).length > 0 ){
					$('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).stop().animate({'left':x,'top':y},200);
				}else{
					$(_this.$refs.graphContainer.$el).append('<div class="alarm" data-ref="'+alarm[0]+'-'+alarm[1]+'" style="position:absolute;width:20px;height:20px;text-align:center;left:'+x+';top:'+y+';"><img src="assets/misc_icons/annotations_1_0_0_0.png" style="max-width:20px;max-height:20px;"/></div>');
				}
			});
		}
	},
	computed:{
		/*
		graph(){
			if( !this._.isEmpty(this.rawData) ){

				var return_data = [];
				var i = 0;
				this.rawData.forEach(sensor => {
				
					if(sensor){
						if(!return_data[i]){
							return_data[i]=[];
						}
						var t = new Date(sensor.reading_timestamp_unix*1000);
						return_data[i][0]=t;
						return_data[i][1]=Number(sensor.reading);
						return_data[i][2]=Number(sensor.threshold_low);
						return_data[i][3]=Number(sensor.threshold_high);
					}
					i++;
				});
				return return_data;
			}else{
				return [];
			}

		},
		*/
		currentSelection(){
			var ret = {};
			this.selectList.forEach(date=>{
				date.sensors.forEach(sensor=>{
					if(sensor.value == this.currentSelectedValue){
						ret = sensor;
						ret.date = date.date;
					}
				});
			});
			return ret;
		},
		selectList(){
			var current_date = this.dateRange.from;
			var end_date = this.moment( this.dateRange.to ).add(24,'hours').format('YYYY-MM-DD');
			var arr = [];
			var i = 0;
			while(current_date != end_date){
				var date_object = {
					'date': current_date,
					'sensors': []
				};

				this.sensors.forEach(sensor=>{
					date_object.sensors.push({
						'value': i,
						'id': sensor.id,
						'location': sensor.location_name
					});

					//update highestPossibleValue as useful for disabling "next page" button
					//eslint-disable-next-line
					this.highestPossibleValue = i;

					i++;
				});
				arr.push(date_object);

				current_date = this.moment( current_date ).add(1,'day').format('YYYY-MM-DD');
			}
			return arr;
		}
	},
	watch:{
		state(a){
			if( a == "open"  ){
				this.fetchGraphData();
				this.dateRangeCopy=this._.cloneDeep(this.dateRange);

				this.rawData = [];
				this.chartData = [];
			}
		},
		currentSelection(){
			if(this.state=='open'){
				this.fetchGraphData();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.offcanvas-body{
	//height:calc(100vh - 105px) !important;
	position:relative;
	/*&.scrollx{
		overflow-y:hidden;
		overflow-x:scroll;
	}
	&.scrolly{
		overflow-x:hidden;
		overflow-y:scroll;
	}*/

	.no_results{
		position:absolute;
		z-index:2;
		background-color:rgba(255,255,255,0.9);
		width:100%;
		height:calc(100% - 45px);
		top:0;
	}
}
.offcanvas-footer{
	background-color:#eee;
	height:45px !important;
	.btn{
		height:45px;
	}
}
.dygraph-legend{
	text-align:right !important;
}
</style>
