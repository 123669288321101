<template>
<div>
	<div class="container-fluid py-3">
		<select class="form-control mb-2" v-model="third_party">
			<option value="0">t-Scan Certificates</option>
			<option value="1">UKAS Calibration Certificates</option>
		</select>
	
		<search v-model="search"/>
	</div>
	
	<div v-if="filteredDocuments.length == 0 && loading == false" class="bg-white container-fluid text-center py-3">
		No documents
	</div>
	<loading :state="loading"></loading>
	<document-list :documents="filteredDocuments"/>
</div>
</template>

<script>
import DocumentList from '../../components/Settings/DocumentCenter/List.vue';
import Loading from '../../components/Loading.vue';
import Search from '../../components/FormElements/Search.vue';

export default{
	components:{
		DocumentList,
		Loading,
		Search
	},
	data(){
		return {
			third_party:0,
			documents:[],
			search:'',
			loading: false
		}
	},
	computed:{
		filteredDocuments(){
			var filtered = this.documents.filter(doc=>{
				return (doc.third_party_original == this.third_party ? true : false);
			});
			
			return this.searchArrayObjects(filtered,this.search);
		}
	},
	methods:{
		getDocuments(){
			this.loading=true;
			var self = this;
			this.axios.get(this.controller('settings/document_center')).then(response=>{
				self.documents = response.data.data;
				self.loading=false;
			});
		}
	},
	mounted(){
		this.getDocuments();
		
		this.$emit('breadcrumb',[
			['Settings','/settings'],
			['Document Center','/settings/document-center']
		]);
	}
}
</script>
