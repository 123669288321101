import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
var jwt = require('jwt-decode');
Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [new VuexPersistence().plugin],
	state: {
		user: {},
		session:{},
		token:'',
		deniedAlert: false
	},
	mutations:{
		setUser(state,user){
			state.user = user;
		},
		setSession(state,session){
			state.session = session;
		},
		setToken(state,token){
			state.token = token;
		},
		setDeniedAlert(state,v){
			state.deniedAlert = v;
		}
	},
	actions:{
		setToken(context,token){
			return new Promise((resolve) => {
				context.commit('setToken',token);

				//extract user and store
				var x = this._vm.$jwt.decode(token);
				if(x && typeof x.user == 'object'){
					context.commit('setUser',x.user);
				}

				if(x && typeof x.session == 'object'){
					context.commit('setSession',x.session);
				}
				resolve();
			});
		},
		refreshUser(context){
			//extract user and store
			var x = this._vm.$jwt.decode(context.state.token);
			context.commit('setUser',x.user);
		},
		destroyToken(context){
			context.commit('setToken','');
			context.commit('setUser',{});
		}
	},
	getters:{
		token: state=>{
			if(state.token){
				try{
					return jwt(state.token);
				}catch(err){
					console.log(err)
				}
			}
		}
	}
});
