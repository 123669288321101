export default {
    path: ()=>{
        var base = window.location.href;
        var domain = base.split('//')[1].split('/')[0];
        var path;
        switch(domain){
            case "mtest.tscanweb.com":
                path = 'https://test2.tscanweb.com/mobile/';
            break;
            case "m.tscanweb.com":
                path = 'https://www.tscanweb.com/mobile/';
            break;
            case "192.168.2.4:8080":
                path = 'http://192.168.2.4:10007/mobile/';
            break;
            // case "192.168.2.109:10007":
            // 	path = 'http://192.168.2.109:10007/mobile/';
            // break;
            // default:
            // 	path = `${base.split('//')[0]}//${domain}/mobile/`;
            // break;
        }

        return path;
    }
}
