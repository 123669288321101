<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="close"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Create New Group</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<form-input label="New Group Name" v-model="newGroupName" :error="validationErrors.name"/>
			<div class="form-group">
				<label>SMS Notifications</label>
				<select class="form-control" v-model="sms_notifications">
					<option v-for="(option, index) in [{label:'Enabled',value:1},{label:'Disabled',value:0}]" :value="option.value" :key="index">{{option.label}}</option>
				</select>
			</div>
		</div>
	</div>
	<div class="offcanvas-footer" v-if="showSave">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveGroup)" :disabled="!allowChanges">Save Changes</button>
	</div>
	
	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
	
	<alert v-if="alertMessage">
		<div slot="title">Error</div>
		<div v-html="alertMessage"></div>
		<button slot="buttons" class="btn btn-block btn-default" @click="alertMessage=''">Ok</button>
	</alert>
</div>
</template>

<script>
import FormInput from '../../FormElements/Input.vue';
import Alert from '../../Alert.vue';

export default{
	props:['state'],
	components:{
		FormInput,
		Alert
	},
	data(){
		return {
			allowChanges: false,
			newGroupName: '',
			validationErrors:{},
			alertMessage:'',
			sms_notifications:0
		}
	},
	computed:{
		showSave(){
			if(this.newGroupName.length > 0){
				
				if( this._.isEmpty(this.validationErrors) ){
					return true;
				}else{
					return false;
				}
				
			}else{
				return false;
			}
		}
	},
	methods:{
		close(){
			this.$emit('close');
		},
		saveGroup(){
			var self = this;
			var f = new FormData();
			f.append('name',this.newGroupName);
			f.append('sms_notifications',this.sms_notifications);
			f.append('for_alarm_group',0);
			
			this.axios.post( this.controller('settings/add_group') , f).then(res=>{
				if(res.data.status == 'failed'){
					self.alertMessage = res.data.message;
				}else{
					self.newGroupName = '';
					self.sms_notifications = 0;
					self.$emit('reload-alarm-groups');
					self.$emit('close');
				}
			});
		}
	},
	watch:{
		newGroupName(){
			//run validation tasks
			this.validationErrors = {};
			
			if( this.newGroupName.length > 45 ){
				this.validationErrors.name = 'Too long, max 45 characters.';
			}
		}
	}
}
</script>