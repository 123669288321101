<template>
	<div class="offcanvas" :class="data.state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">{{data.title}} {{data.selectedRemedial}}</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="list-group">
				<a href="#" class="list-group-item" v-for="action in data.remedial_actions" :key="action.id" v-on:click="selectedAction=action.remedial_action" :class="{ disabled: loading==true, active:selectedAction==action.remedial_action }">{{action.remedial_action}}</a>
				<div class="list-group-item bg-light">
					<form-input label="Custom Entry" v-model="customAction" :error="(customAction.length > 255 ? 'Too long, max 255 characters.' : '')"/>
				</div>
			</div>
		</div>

		<div class="offcanvas-footer" v-if="(selectedAction != '' || customAction) && data.state == 'open'">
			<button class="btn btn-success btn-lg btn-block" v-on:click="applyAction" :disabled="customAction.length > 255">Confirm</button>
		</div>

		<alert v-if="responseMessage">
			<div slot="title">Error</div>
			{{responseMessage}}
			<div slot="buttons">
				<button class="btn btn-default btn-block" @click="responseMessage=''">Ok</button>
			</div>
		</alert>

	</div>
</template>

<script>
import FormInput from './FormElements/Input.vue';
import Alert from './Alert.vue';

export default{
	components:{
		FormInput,
		Alert
	},
	props:['data'],
	data(){
		return {
			loading: false,
			customAction: "",
			selectedAction: "",
			responseMessage: ''
		}
	},
	methods:{
		closeOffcanvas(){
			this.$emit('close');
		},
		applyAction(){

			if(this.customAction != '' && this.customAction.length > 255){
				return false;
			}

			var action = (this.customAction != '' ? this.customAction : this.selectedAction);

			if(this.loading == false){
				this.loading = true;

				var f = new FormData();
				f.append('remedial_action_id', this.data.selectedRemedial);
				f.append('action',action);

				var self = this;
				this.axios.post(this.$root.controllers_base_path+'remedial_actions/apply_action',f).then(function(response){
					if(response.data.status == "success"){
							self.$parent.getRemedials().then(function(){
								self.closeOffcanvas();
							});
					}else{
							self.responseMessage = response.data.message;
					}
					self.loading=false;
				});

			}
			this.loading=true;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../assets/_vars.scss';
.list-group-item.active{
	background:$green;
}
</style>
