export default {
	routes:{
			'/super-admin': [4,5,9],
			'/settings': [3,4,5,7,9]
	},
	userAllowedAccess: function(route, group){
		let segments = route.split('/',2);
		let key = '/'+segments[1];
		if(
			typeof this.routes[ key ] == "object" &&
			this.routes[ key ].indexOf( Number(group) ) == -1
		){
			return false;
		}else{
			return true;
		}
	}
};
