<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="close"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">{{name}} Sensors</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<search-pagination :array="sensorList" v-on:data="results=>{pageData=results}"/>
		<div class="container-fluid py-3">
			<button class="btn btn-default btn-block" @click="showDisabled=!showDisabled">{{(showDisabled ? 'Sensors in Group' : 'Add Sensors')}}</button>
		</div>
		<loading :state="loading"/>
		
		<ul class="list-group">
			<li class="list-group-item" v-for="sensor in pageData" :key="sensor.id">
				<div class="d-flex justify-content-between">
					<div>
						<b>{{sensor.id}}</b><span v-if="sensor.entities.length > 1" style="font-size:0.8em;color:#555;"> [composite]</span><br/>
						<div class="mw-100" style="font-size:0.8em;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;">
							{{sensor.name}}
							<div v-if="sensor.sdr_group_id > 0 && sensor.sdr_group_id != group">
								<font-awesome-icon icon="bell" /> {{sensor.sdr_group_name}}
							</div>
						</div>
					</div>
					<switch-input v-model="sensor._in_group" v-on:input="v=>{
						confirmFunction=()=>{
							$refs.auth.check(()=>{
								//because this has values being passed it has to be wrapped in another func
								//to avoid it trying to execute rather than pass as callback
								showConfirm=false;
							},()=>{
								revertToggle(sensor);
							});
						};
						confirmRevert=()=>{
							revertToggle(sensor);
						};
						confirmMessage=`${sensor.name} is currently in ${sensor.sdr_group_name}. Continuing will remove it from ${sensor.sdr_group_name} and add it to this SDR group.`;
						if( Number(sensor.sdr_group_id) != Number(group) && Number(sensor._in_group) == 1 ){ 
							confirmSwitch(sensor);
						}
					}" :ref="sensor.id"/>
				</div>
				<ul v-if="sensor.entities.length > 1" class="entitity_list">
					<li v-for="entity in sensor.entities" :key="entity.id">
						{{entity.id}} {{entity.public_name}}
					</li>
				</ul>
			</li>
			<li v-if="pageData.length == 0 && !loading" class="list-group-item text-center">
				No Sensors
			</li>
		</ul>

		<div class="offcanvas-footer" v-if="changed">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)">Save Changes</button>
		</div>
	</div>

	<alert v-if="showAlert">
		<div slot="title">Save Error</div>
		{{message}}
		<div slot="buttons">
			<button class="btn btn-default btn-block" @click="showAlert=false">Ok</button>
		</div>
	</alert>
	
	<alert v-if="showConfirm">
		<div slot="title">Are you sure?</div>
		{{confirmMessage}}
		<div slot="buttons">
			<button class="btn btn-success btn-block" @click="confirmFunction">Continue</button>
			<button class="btn btn-default btn-block" @click="()=>{showConfirm=false;confirmFunction={};confirmRevert();}">Cancel</button>
		</div>
	</alert>


	<unsaved-changes :show="showUnsavedChanges" v-on:close="forceCloseOffcanvas" v-on:hide="showUnsavedChanges=false" v-on:save="$refs.auth.check(saveChangesAndClose)"/>
	
	<password-modal ref="auth"/>
</div>
</template>

<style lang="scss" scoped>
.entitity_list{
	padding:5px;
	background:#eee;
	border:1px solid #ccc;
	font-size:0.8em;
	list-style-type:none;
}
</style>

<script>
import SearchPagination from '../../SearchPagination.vue';
import Loading from '../../Loading.vue';
import SwitchInput from '../../FormElements/Switch.vue';
import Alert from '../../Alert.vue';
import UnsavedChanges from '../../UnsavedChangesAlert.vue';

export default{
	props:['state','group','name'],
	components:{
		SearchPagination,
		Loading,
		Alert,
		SwitchInput,
		UnsavedChanges
	},
	data(){
		return {
			sensors: [],
			search:'',
			pageData:[],
			loading: false,
			Request: null,
			sensorsCopy:[],
			showConfirm:false,
			confirmMessage:'',
			confirmFunction:{},
			confirmRevert:{},
			message: '',
			showAlert:false,
			showDisabled:false,
			showUnsavedChanges:false
		}
	},
	watch:{
		state(a){
			if( a == 'open' ){
				this.getSensors();
			}
		},
		sensors: {
			handler(){
				
				//add some attributes before creating the clone
				var arr = this.sensors.map(sensor=>{
					sensor._in_group = ( sensor.sdr_group_id == this.group ? "1" : "0" );
					sensor._in_group_prechange = sensor._in_group;
					return sensor;
				});

				this.sensorsCopy = this._.cloneDeep(arr);
			},
			deep: true
		}

	},
	computed:{
		changed(){
			if( !this._.isEqual(this.sensors,this.sensorsCopy) ){
				return true;
			}else{
				return false;
			}
		},
		sensorList(){
			return this.sensorsCopy.filter(sensor=>{
				return (sensor._in_group == "1" || sensor._in_group_prechange == "1" || this.showDisabled == true);
			});
		}
	},
	methods:{
		close(){
			if( this.changed ){
				this.showUnsavedChanges = true;
			}else{
				this.forceCloseOffcanvas();
			}
		},
		forceCloseOffcanvas(){
			this.showUnsavedChanges=false;
			this.$emit('close');
		},
		saveChangesAndClose(){
			this.saveChanges().then(()=>{
				this.forceCloseOffcanvas();
			});
		},
		confirmSwitch(sensor){
			if(Number(sensor.sdr_group_id) > 0 && Number(sensor.sdr_group_id) != Number(this.group)){
				//alert
				this.showConfirm=true;
			}else{
				this.confirmFunction();
			}
		},
		revertToggle(sensor){
			sensor._in_group = (Number(sensor._in_group) == 1 || sensor._in_group == true ? "0" : "1");
		},
		saveChanges(){
			return new Promise(resolve=>{
				var changed_sensors = this._.differenceWith(this.sensorsCopy,this.sensors,this._.isEqual);

				//active loading spinner
				//this.loading.push(sensor.id);
				var self = this;
				var f = new FormData();
				f.append('sensors',JSON.stringify(changed_sensors));
				f.append('alarm_group',this.group);

				this.axios.post(this.controller('settings/save_sdr_sensors'),f).then(function(response){
					if( response.data.status == "success" ){
						//emit reload list
						self.getSensors();
						resolve();
					}else{
						//alert message
						self.message = response.data.message;
						self.showAlert = true;
					}
				});
			});
		},
		getSensors(){
			if( this.Request ){
				this.Request.cancel();
			}
			this.Request = this.axios.CancelToken.source();

			var f = new FormData();
			f.append('group_id',this.group);
			this.loading = true;
			var self = this;
			this.axios.post(this.controller('settings/get_all_sensors_for_sdr_group'),f,{
				cancelToken: this.Request.token
			}).then(function(response){
				self.sensors = response.data.data;
				self.$emit('reload-alarm-groups');
				self.loading = false;
				self.Request = null;
			});
		}
	}
}
</script>
