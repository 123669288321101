<template>
	<div class="remedials">
		<offcanvas :data="offcanvas" v-on:close="offcanvas.state='closed'"></offcanvas>
		<div class="accordion has_caret" id="remedialsFilters">
			<div class="card">
				<div class="card-header" :class="{collapsed:!showFilters,open:showFilters}" @click="showFilters=!showFilters">
					<div class="d-flex justify-content-between align-items-center">
						<div>Filters</div>
						<font-awesome-icon icon="chevron-right"/>
					</div>
				</div>

				<div class="collapse" :class="{show:showFilters}">
					<div class="card-body">
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label for="">From</label>
									<input type="date" class="form-control" v-model="date.from" placeholder="from">
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label for="">To</label>
									<input type="date" class="form-control" v-model="date.to" placeholder="to">
								</div>
							</div>
						</div>
						<switch-input v-model="searchAll" v-if="userIs('adminUp')">
							<div>View alarm events for all alarm groups</div>
						</switch-input>
					</div>
				</div>
			</div>
		</div>


		<search-pagination :array="remedials" v-on:data="results=>{pageData=results}" :sortby="['actual_reading','alarm_group_name','location_name','reading_timestamp_unix:reading_time','sensor_id']"/>

		<div v-if="pageData.length == 0 && loading == false" class="bg-white container-fluid text-center py-3">
			No alarm events
		</div>

		<loading :state="loading"></loading>

		<div class="list">
			<div class="accordion" id="remedialsAccordion">
				<div class="card" v-for="remedial in pageData" :key="remedial.remedial_actions_id">
					<div class="card-header" :id="'heading'+remedial.remedial_actions_id" data-toggle="collapse" :data-target="'#remedial'+remedial.remedial_actions_id" aria-expanded="false" :aria-controls="'remedial'+remedial.remedial_actions_id" v-on:click="offcanvas.selectedRemedial = remedial.remedial_actions_id">
						<div class="d-flex justify-content-between">
							<div>
								<b>{{remedial.sensor_id}}</b><br/>
								<small>{{remedial.location_name}}<br/>
								<i class="far fa-fw fa-clock" aria-hidden="true"></i> {{remedial.reading_timestamp_unix | unixToDate}}</small>
							</div>
							<div class="text-danger">
								<template v-if="!isContactSensor(remedial.sensor_type_id)">
									{{Number(remedial.actual_reading).toFixed(1)}}<span v-html="remedial.display_unit"></span>
								</template>
								<template v-if="isContactSensor(remedial.sensor_type_id)">
									{{contact_label(remedial.actual_reading,remedial.contact_sensor_label_id)}}
								</template>
							</div>
						</div>
					</div>

					<div :id="'remedial'+remedial.remedial_actions_id" class="collapse" aria-labelledby="'heading'+remedial.remedial_actions_id" data-parent="#remedialsAccordion">
						<div class="card-body">
							<div class="d-flex justify-content-between">
								<p>Alarm Group:</p>
								<p>{{ (remedial.alarm_group_name ? remedial.alarm_group_name : 'None') }}</p>
							</div>
							<div class="remedial_buttons">
								<button type="button" name="button" class="btn btn-secondary flex-grow-1 mr-1" style="white-space:nowrap;" v-on:click="$refs.auth.check(showApplyAction)" :disabled="!allowChanges">Apply Remedial Action</button>
								<button type="button" name="button" class="btn btn-secondary" v-on:click="graphState='open';graphAlarmEvent=remedial" style="white-space:nowrap;">View Graph</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
		<graph :state="graphState" :alarm="graphAlarmEvent" :contact_labels="contact_labels" v-on:close="graphState='closed'"/>
		<alarm-information :state="(alarmInformationSensorId ? 'open' : 'closed')" :sensorId="alarmInformationSensorId"/>
	</div>
</template>

<script>
import Offcanvas from '../components/RemedialActionOffcanvas.vue';
import PasswordModal from '../components/PasswordModal.vue';
import Loading from '../components/Loading.vue';
import SearchPagination from '../components/SearchPagination.vue';
import SwitchInput from '../components/FormElements/Switch.vue';
import Graph from '../components/AlarmEvents/Graph.vue';
import AlarmInformation from '../components/AlarmEvents/AlarmInformation.vue';

export default{
	components:{
		Offcanvas,
		PasswordModal,
		Loading,
		SearchPagination,
		SwitchInput,
		Graph,
		AlarmInformation
	},
	data(){
		return {
			showFilters: false,
			showPasswordModal: false,
			allowChanges:false,
			alarmInformationSensorId:null,
			offcanvas:{
				state: 'closed',
				title: 'Remedial',
				remedial_actions: [],
				selectedRemedial: ''
			},
			date:{
				from: '',
				to: ''
			},
			searchAll: 0,
			remedials: [],
			searchFilter: '',
			loading: false,
			pageData:[],
			Request: null,
			graphState:'closed',
			graphAlarmEvent:{},
			contact_labels:[]
		}
	},

	computed: {
		filteredRemedials(){
			//searchfilter
			return this.searchArrayObjects(this.remedials,this.searchFilter);
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Alarm Events','/alarm-events']
		]);

		//set initial date
		this.date.from = this.moment().subtract(3, 'months').format('YYYY-MM-DD');
		this.date.to = this.moment().format('YYYY-MM-DD');

		//if super user enable search all by default
		if( Number(this.$store.state.user.group) == 4 ){
			this.searchAll = 1;
		}
		
		//check for realarming cookie
		if( this.$cookies.isKey('realarm_suspension_sensor_id') ){
			//store locally
			this.alarmInformationSensorId = this.$cookies.get('realarm_suspension_sensor_id');
			//remove so it doesn't trigger the popup again
			this.$cookies.remove('realarm_suspension_sensor_id','/','tscanweb.com');
		}
	},
	watch:{
		date:{
			handler(){
				//re-fetch remedials
				if( this.date.from != '' && this.date.to != '' ){
					this.getRemedials();
				}
			},
			//watches nested variables
			deep: true
		},
		searchAll: function(){
			this.getRemedials();
		}
	},

	methods:{
		contact_label(reading,label_id){
			var label_ = this.contact_labels.filter(label => {
				return Number(label.id) == Number(label_id);
			})[0];

			if(label_){
				if(Number(reading)==0){
					return label_.reading_0_label;
				}else{
					return label_.reading_1_label;
				}
			}else{
				return reading+'Signal';
			}
		},
		getRemedials(){
			var self = this;
			var form = new FormData();
			form.append('date_from',this.date.from);
			form.append('date_to',this.date.to);
			form.append('remedial_actions_search_all',this.searchAll);
			this.loading=true;

			//cancel existing graph requests if promise exists
			if(this.Request){
				this.Request.cancel();
			}

			//Create new cancellable request
			this.Request = this.axios.CancelToken.source();

			return this.axios.post(this.controller('remedial_actions'), form, {
				cancelToken: this.Request.token
			}).then(function(response){
				self.remedials = response.data.data;
				self.contact_labels = response.data.contact_labels;
				self.loading=false;

				//reset cancelToken to null
				self.Request = null;
			})
		},
		showApplyAction(){
			var self = this;
			this.axios.get( this.$root.controllers_base_path+'remedial_actions/get_stored_remedial_actions/'+this.moment().unix() ).then(function(response){
				self.offcanvas.remedial_actions = response.data.data;
			});
			this.offcanvas.state = 'open';
		}
	},
	beforeDestroy(){
		//cancel existing graph requests if promise exists
		if(this.Request){
			this.Request.cancel();

			//reset cancelToken to null
			this.Request = null;
		}
	}

}
</script>

<style lang="scss" scoped>
@import '../assets/_vars.scss';
#remedialsAccordion{
	[data-toggle="collapse"]{
		&[aria-expanded="true"]{
			border-top-width:10px;
			background:$selected;
			color:#222;
		}
	}
}

#remedialsFilters{
	.col-6{
		.form-control{
			padding-left:5px;
			padding-right:5px;
			text-align:center;
		}
	}
	@media only screen and (max-width: 389px) {
		.col-6{
			max-width:100% !important;
			flex:0 0 100%;
		}
	}
	@media only screen and (min-width: 390px) {
		.col-6{
			&:first-of-type{
				padding-right:0;
			}
			&:last-of-type{
				padding-left:0;
			}
		}
	}
}

.remedial_buttons{
	@media only screen and (max-width: 369px) {
		display:block;
		button{
			width:100%;
			&:first-of-type{
				margin-bottom:10px;
			}
		}
	}
	@media only screen and (min-width: 370px) {
		display:flex;
	}
}
</style>
