<template>
<div class="full-bg d-flex align-items-center justify-content-center">
	<div class="px-3 text-center">t-Scan Web uses cookies for site functionality and analytical purposes.<br/><button class="btn btn-success mt-3" @click="accept">Accept</button></div>
</div>
</template>

<script>
export default{
	methods:{
		accept(){
			//set cookie
			this.$cookies.set('cb-enabled','accepted','1m');

			//emit acceptance
			this.$emit('accepted');
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/_vars.scss";
.full-bg{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:transparentize(#000,0.1);
	color:#fff;
	text-shadow:2px 2px 2px #000;
	z-index:1031;
}
</style>
