<template>
	<div class="container-fluid bg-white py-4">
		<div class="alert alert-secondary" v-if="msg">{{msg}}</div>
		<select-input label="Department" v-model="departments.selected" :options="department_options" />

		<button class="btn btn-success btn-block" :disabled="departments.selected==original_selection.department" v-on:click="save">Select Department</button>
	</div>
</template>

<script>
import SelectInput from '../../components/FormElements/Select.vue';

export default{
	components:{
		SelectInput
	},
	data(){
		return {
			msg:'',
			loading:{
				companies: false,
				departments: false
			},
			original_selection:{
				company: this.$store.state.user.company.id,
				department: this.$store.state.user.department.id
			},
			companies:{
				selected: null,
				options:[]
			},
			departments:{
				selected: null,
				options:[]
			}
		}
	},
	computed:{
		company_options(){
			var arr = [];
			this.companies.options.forEach(c=>{
				arr.push({
					label: `${c.company_name} (${c.id})`,
					value: c.id
				});
			});
			return arr;
		},
		department_options(){
			var arr = [];
			this.departments.options.forEach(d=>{
				arr.push({
					label: `${d.name} (${d.id})`,
					value: d.id
				});
			});
			return arr;
		}
	},
	beforeMount(){
		this.getDepartments();
	},
	methods:{
		getDepartments(){
			var self = this;
			var f = new FormData();
			f.append('company_id',this.companies.selected);

			this.loading.departments=true;

			if( this.original_selection.company != this.companies.selected ){
				this.departments.selected=null;
			}


			this.axios.post(this.controller("auth/get_departments"),f).then(function(response){
				self.departments.options = response.data.data;

				// set select department to first in list
				if(response.data.length > 0){
					if(self.departments.selected == null || self.departments.selected == ""){
						self.departments.selected = response.data.data[0].id;
						//self.$cookies.get('user').department.id = response.data.data[0].id;
					}
				}

				self.loading.departments=false;
			});
		},
		save(){
			var self = this;
			var f = new FormData();

			f.append('department',this.departments.selected);

			this.axios.post(this.controller("auth/change_department"),f).then(function(response){
				if(response.data.status == "success"){
					//update the local cookie then navigate to dashboard
					self.reloadUserCookie().then(function(){
						self.$router.push('/');
					});
				}
			});
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Department Changer','/department-changer']
		]);
		
		this.msg = this.$session.flash.get('msg');

		//set current company values
		this.departments.selected=this.$store.state.user.department.id;
	},
	watch:{
		companies:{
			handler(){
				this.getDepartments();
			},
			deep: true
		}
	}
}
</script>
