<template>
<div class="offcanvas" :class="{open: state}">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Add New User</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<form-input label="First Name" v-model="user.first_name" :error="validationErrors.first_name"/>
			<form-input label="Last Name" v-model="user.last_name" :error="validationErrors.last_name"/>
			<form-input label="Email Address" type="email" v-model="user.email_address" :error="validationErrors.email_address"/>
			
			<div class="form-group">
				<label>Permissions</label>
				<select class="form-control" v-model="user.group_id">
					<option v-for="option in user_groups" :value="option.id" :key="option.id">{{option.name}}</option>
				</select>
				<div class="error" v-if="validationErrors.group_id">{{validationErrors.group_id}}</div>
			</div>
		</div>
	</div>
	
	<div class="offcanvas-footer">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveNewUsers)" :disabled="!_.isEmpty(validationErrors) || !completed">Save New User</button>
	</div>
	
	<password-modal ref="auth" v-if="state"/>
	
	<alert v-if="alertMsg">
		<div slot="title">{{alertTitle}}</div>
		<div v-html="alertMsg"></div>
		<button class="btn btn-block btn-secondary" @click="alertAction()" slot="buttons">Ok</button>
	</alert>
</div>
</template>

<script>
import FormInput from '../../FormElements/Input.vue';
import Alert from '../../Alert.vue';

export default{
	props:['state'],
	components:{
		FormInput,
		Alert
	},
	data(){
		return {
			groups: [],
			validationErrors: {},
			user:{
				first_name: '',
				last_name: '',
				email_address: '',
				group_id: 1
			},
			alertMsg:'',
			alertTitle:'',
			alertAction:{}
		}
	},
	methods:{
		closeOffcanvas(){
			this.$emit('close')
		},
		saveNewUsers(){
			var f = new FormData();
			for( var key in this.user ){
				f.append(key, this.user[key]);
			}
			
			var self = this;
			
			this.axios.post( this.controller('settings/add_new_user'), f ).then(res=>{
				self.alertMsg = res.data.msg;
				if(res.data.status == 'failed'){
					//display error
					self.alertTitle = 'Error';
					self.alertAction = ()=>{
						self.alertTitle = '';
						self.alertMsg = '';
						self.alertAction = {};
					}
				}else{
					//emit reload user list and close offcanvas
					self.alertTitle = 'Important';
					self.alertAction = ()=>{
						self.alertTitle = '';
						self.alertMsg = '';
						self.alertAction = {};
						
						self.$emit('reload');
					}
				}
			});
		},
		validateForm(){
			//clear the array
			this.validationErrors = {};

			//first name validation
			if(this.user.first_name == ''){
				this.validationErrors.first_name = 'Cannot be empty';
			}

			//last name
			if(this.user.last_name == ''){
				this.validationErrors.last_name = 'Cannot be empty';
			}

			//login email
			if(!this.user.email_address){
				this.validationErrors.email_address = 'Cannot be empty';
			}else if(!this.isValidEmail(this.user.email_address)){
				this.validationErrors.email_address = 'Invalid address';
			}

			return false;
		}
	},
	computed:{
		user_groups(){
			return this.groups.filter(group=>{
				if( this.userIs('superAdmin') ){
					return [4,5,8,9].indexOf( Number(group.id) ) == -1;
				}else if( this.userIs('companyAdmin') ){
					return [4,5,0,8,9].indexOf( Number(group.id) ) == -1;
                }else{
					return [3,4,5,0,8,9].indexOf( Number(group.id) ) == -1;
				}
			})
		},
		completed(){
			if( this.user.first_name == '' || this.user.last_name == '' || this.user.email_address == '' ){
				return false;
			}else{
				return true;
			}
		}
	},
	mounted(){
		//get user groups
		var self = this;
		this.axios.get( this.controller('settings/user_groups') ).then(res=>{
			self.groups = res.data.data;
		});
	},
	watch:{
		user:{
			handler(){
				this.validateForm();
			},
			deep: true
		}
	}
}
</script>
