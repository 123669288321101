<template>
	<div class="login container-fluid d-flex align-items-center" style="height:100vh;">
		<div class="card mx-auto">
			<div class="card-body">
				<template v-if="allowLogin">
					<div class="text-center">
						<img :src="'./assets/large_logo_alpha.png'" style="max-height:40px;" class="my-2"/>
						<p style="font-size:0.8em;line-height:1.2em;">Please log in with your email address and password below:</p>
					</div>

					<div class="form-group">
						<input type="text" name="email" class="form-control" placeholder="Email Address" v-model="email" autocomplete="username" :disabled="disabled"/>
						<div class="alert alert-danger" v-if="validation.email!=''" v-html="validation.email"></div>
					</div>

					<div class="form-group">
						<input type="password" name="password" class="form-control" placeholder="Password" v-model="password" autocomplete="current-password" :disabled="disabled"/>
						<div class="alert alert-danger" v-if="validation.password!=''" v-html="validation.password"></div>
					</div>

					<div class="alert alert-danger" v-if="msg!=''" v-html="msg"></div>

					<button class="btn btn-success btn-block" v-on:click="login()" :disabled="disabled"><i class="fa fa-sign-in-alt"></i> Login</button>
				</template>

				<div v-if="!allowLogin">
					Too many failed login attempts. Please wait 5 minutes before trying again.
				</div>

				<hr class="my-3"/>

				<button class="btn btn-secondary btn-block btn-sm" @click="useDesktopSite()"><i class="fa fa-desktop"></i> Switch to Desktop Site</button>
			</div>
		</div>
	</div>
</template>

<script>
import changes_passworded from '../passworded.js';

export default{
	data(){
		return {
			'disabled': true,
			'email':'',
			'password':'',
			'validation':{
				'email':'',
				'password':''
			},
			'msg': ''
		}
	},
	computed:{
		allowLogin(){
			if(
				!this.$store.state.session.failed_logins || this.$store.state.session.failed_logins < 6
			){
				return true;
			}else if(
				this.$store.state.session.failed_logins > 5 &&
				this.moment().unix() > this.moment.unix(this.$store.state.session.last_failed_login).add(5,'m').unix()
			){
				return true;
			}else{
				return false;
			}
		}
	},
	mounted(){
		this.msg = this.$session.flash.get('message') || '';
		
		this.axios.post( this.controller('auth/ip_firewall_check') ).then(response=>{
			if(response.data.status == 'blocked'){
				this.disabled=true;
				this.msg = response.data.msg;
			}else{
				this.disabled=false;
				this.msg = '';
			}
		});
		
		//delete current cookie
		this.$store.dispatch('destroyToken');
	},
	methods:{
		login(){
			var self=this;
			var data = new FormData();
			data.append('email',this.email);
			data.append('password',this.password);

			this.axios.post(this.$root.controllers_base_path+'auth/login',data).then(function(response){
				//test for validation errors
				if( typeof response.data.validation != "undefined" ){
					self.validation.email = ( typeof response.data.validation.email != "undefined" ? response.data.validation.email : '');
					self.validation.password = ( typeof response.data.validation.password != "undefined" ? response.data.validation.password : '');
				}

				//no validation errors
				if(response.data.status == "failed"){
					self.msg = ( typeof response.data.msg != "undefined" ? response.data.msg : '');
				}else{
					//get the default settings for changes passworded module
					changes_passworded.init();

					//redirect to dashboard
					if(typeof response.data.status == 'undefined' || response.data.status != 'failed'){
						self.$store.dispatch('setToken', response.data).then(()=>{
							self.$router.push('/');
						});
					}


				}

			});
		}
	}
}
</script>
