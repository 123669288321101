var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offcanvas",class:_vm.state},[_c('nav',{staticClass:"navbar navbar-expand-sm navbar-light bg-light"},[_c('div',{staticClass:"w-100 d-flex align-items-center"},[_c('button',{staticClass:"btn btn-light mr-1",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1),_c('div',{staticClass:"offcanvas-title"},[_vm._v(_vm._s(_vm.name)+" Sensors")])])]),_c('div',{staticClass:"offcanvas-body"},[_c('search-pagination',{attrs:{"array":_vm.sensorList},on:{"data":results=>{_vm.pageData=results}}}),_c('div',{staticClass:"container-fluid py-3"},[_c('button',{staticClass:"btn btn-default btn-block",on:{"click":function($event){_vm.showDisabled=!_vm.showDisabled}}},[_vm._v(_vm._s((_vm.showDisabled ? 'Sensors in Group' : 'Add Sensors')))])]),_c('loading',{attrs:{"state":_vm.loading}}),_c('ul',{staticClass:"list-group"},[_vm._l((_vm.pageData),function(sensor){return _c('li',{key:sensor.id,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('b',[_vm._v(_vm._s(sensor.id))]),(sensor.entities.length > 1)?_c('span',{staticStyle:{"font-size":"0.8em","color":"#555"}},[_vm._v(" [composite]")]):_vm._e(),_c('br'),_c('div',{staticClass:"mw-100",staticStyle:{"font-size":"0.8em","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(sensor.name)+" "),(sensor.alarm_group_id > 0 && sensor.alarm_group_id != _vm.group)?_c('div',{staticClass:"mb-1"},[_c('font-awesome-icon',{attrs:{"icon":"bell"}}),_vm._v(" "+_vm._s(sensor.alarm_group_name)+" ")],1):_vm._e()])]),_c('switch-input',{ref:sensor.id,refInFor:true,on:{"input":v=>{
						//sets the function that will run if 'contine' is clicked
						_vm.confirmFunction=()=>{
							_vm.$refs.auth.check(()=>{
								//because this has values being passed it has to be wrapped in another func
								//to avoid it trying to execute rather than pass as callback
								
								//toggleActive(v,sensor)
								_vm.showConfirm=false;
							},()=>{
								_vm.revertToggle(sensor);
							});
						};
						//sets the function that will run if 'cancel' is clicked
						_vm.confirmRevert=()=>{
							_vm.revertToggle(sensor);
						};
						//sets the confirm message
						_vm.confirmMessage=`${sensor.name} is currently in ${sensor.alarm_group_name}. Continuing will remove it from ${sensor.alarm_group_name} and add it to this alarm group.`;
						//opens the confirm modal
						if( Number(sensor.alarm_group_id) != Number(_vm.group) && Number(sensor._in_group) == 1 ){ 
							_vm.confirmSwitch(sensor);
						}
						
					}},model:{value:(sensor._in_group),callback:function ($$v) {_vm.$set(sensor, "_in_group", $$v)},expression:"sensor._in_group"}})],1),(sensor.entities.length > 1)?_c('ul',{staticClass:"entitity_list"},_vm._l((sensor.entities),function(entity){return _c('li',{key:entity.id},[_vm._v(" "+_vm._s(entity.id)+" "+_vm._s(entity.public_name)+" ")])}),0):_vm._e()])}),(_vm.pageData.length == 0 && !_vm.loading)?_c('li',{staticClass:"list-group-item text-center"},[_vm._v(" No Sensors ")]):_vm._e()],2),(_vm.changed)?_c('div',{staticClass:"offcanvas-footer"},[_c('button',{staticClass:"btn btn-success btn-lg btn-block",on:{"click":function($event){return _vm.$refs.auth.check(_vm.saveChanges)}}},[_vm._v("Save Changes")])]):_vm._e()],1),(_vm.showAlert)?_c('alert',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Save Error")]),_vm._v(" "+_vm._s(_vm.message)+" "),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('button',{staticClass:"btn btn-default btn-block",on:{"click":function($event){_vm.showAlert=false}}},[_vm._v("Ok")])])]):_vm._e(),(_vm.showConfirm)?_c('alert',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Are you sure?")]),_vm._v(" "+_vm._s(_vm.confirmMessage)+" "),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('button',{staticClass:"btn btn-success btn-block",on:{"click":_vm.confirmFunction}},[_vm._v("Continue")]),_c('button',{staticClass:"btn btn-default btn-block",on:{"click":()=>{_vm.showConfirm=false;_vm.confirmFunction={};_vm.confirmRevert();}}},[_vm._v("Cancel")])])]):_vm._e(),_c('unsaved-changes',{attrs:{"show":_vm.showUnsavedChanges},on:{"close":_vm.forceCloseOffcanvas,"hide":function($event){_vm.showUnsavedChanges=false},"save":function($event){return _vm.$refs.auth.check(_vm.saveChangesAndClose)}}}),_c('password-modal',{ref:"auth"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }