<template>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Edit Sensor {{sensorCopy.id}}</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">

				<div>
					<label class="float-left">Date Added</label>
					<div class="float-right">{{date_added}}</div>
					<div style="float:none;clear:both;"></div>
				</div>
				<hr class="mt-0"/>

				<div class="form-group" v-if="!_isCompositeChild">
					<label>Location</label>
					<input type="text" class="form-control" v-model="sensorCopy.location_name"/>
					<div class="error" v-if="validationErrors.location_name">{{validationErrors.location_name}}</div>
				</div>

				<div class="row" v-if="!_isContactSensor">
					<div class="col-12">
						<label>Thresholds <small>({{sensorCopy.range_min}} to {{(sensorCopy.range_max ? sensorCopy.range_max : 'any ')}}<span v-html="sensorCopy.threshold_unit"></span>)</small></label>
					</div>
					<div class="form-group col">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text">Low</span>
							</div>
							<input type="text" class="form-control" v-model="sensorCopy.threshold_low"/>
							<div class="error" v-if="validationErrors.threshold_low">{{validationErrors.threshold_low}}</div>
						</div>
					</div>
					<div class="form-group col">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text">High</span>
							</div>
							<input type="text" class="form-control" v-model="sensorCopy.threshold_high"/>
							<div class="error" v-if="validationErrors.threshold_high">{{validationErrors.threshold_high}}</div>
						</div>
					</div>
				</div>
                <div v-if="sensorCopy.threshold_schedule" class="text-danger mb-3" style="font-size:0.8em;">
                    Scheduled threshold change in progress {{sensorCopy.threshold_schedule.threshold_low}}<span v-html="sensorCopy.alarming_display_unit"></span> - {{sensorCopy.threshold_schedule.threshold_high}}<span v-html="sensorCopy.alarming_display_unit"></span><br/>
                    Ending: {{scheduleEnd}} 
                </div>

				<div class="row" v-if="_isContactSensor">
					<div class="form-group col">
						<label>Alarm On</label>
						<select class="form-control" @input="setThresholds">
							<option value="-1" :selected="alarm_on == -1">None</option>
							<option value="0" :selected="alarm_on === 0">{{contact_label_options.reading_0_label}}</option>
							<option value="1" :selected="alarm_on == 1">{{contact_label_options.reading_1_label}}</option>
						</select>
					</div> 
				</div>

				<div class="row">
					<div class="form-group col">
						<label>Alarm Delay<br/><small>(0 - {{sensorCopy.max_delay/60}} mins)</small></label>
						<input type="text" class="form-control" v-model="sensorCopy.alarm_delay"/>
						<div class="error" v-if="validationErrors.alarm_delay">{{validationErrors.alarm_delay}}</div>
					</div>
					<div class="form-group col">
						<label>Log Interval<br/><small>(1 - 15 mins)</small></label>
						<select class="form-control" v-model="sensorCopy.log_interval">
							<option v-for="i in 15" :value="i" :key="i">{{i}} min{{(i>1?'s':'')}}</option>
						</select>
						<div class="error" v-if="validationErrors.log_interval">{{validationErrors.log_interval}}</div>
					</div>
				</div>

				<div class="row" v-if="userIs('adminUp') && !_isCompositeChild">
					<div class="form-group col">
						<label>Alarm Group</label>
						<select class="form-control" v-model="sensorCopy.alarm_group_id">
							<option value="0">Non-Alarmed</option>
							<option v-for="group in alarm_groups" :value="group.id" :key="group.id">{{group.name}}</option>
						</select>
						<div class="error" v-if="validationErrors.alarm_group">{{validationErrors.alarm_group}}</div>
					</div>
					<div class="form-group col">
						<label>SDR Group</label>
						<select class="form-control" v-model="sensorCopy.sdr_group_id">
							<option value="0">Non-Alarmed</option>
							<option v-for="group in sdr_groups" :value="group.id" :key="group.id">{{group.name}}</option>
						</select>
						<div class="error" v-if="validationErrors.sdr_group">{{validationErrors.sdr_group}}</div>
					</div>
				</div>

			</div>

		</div>

		<div class="offcanvas-footer" v-if="hasChanged && _.isEmpty(validationErrors)">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="isSaving || !allowChanges">
				<span v-if="!isSaving">Save Changes</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>

		<unsaved-changes :show="showAlert && state=='open'" v-on:close="forceCloseOffcanvas" :save-disabled="!hasChanged || !_.isEmpty(validationErrors)" v-on:hide="showAlert=false" v-on:save="$refs.auth.check(saveChanges)"/>

		<alert v-if="showResponse && state=='open'">
			<div slot="title">Important</div>
			{{responseMessage}}
			<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
		</alert>

		<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
	</div>
</template>

<script>
import Alert from '../Alert.vue';
import UnsavedChanges from '../UnsavedChangesAlert.vue';
import moment from 'moment'
export default{
	components:{
		Alert,
		UnsavedChanges
	},
	props:['state','sensor','alarm_groups', 'sdr_groups','contact_labels'],
	data(){
		return {
			showAlert:false,
			showResponse:false,
			responseMessage:'',
			loading: false,
			customAction: "",
			selectedAction: "",
			//make a copy of the sensor so we can check if it has been changed
			//copy made when offcanvas opened. see watch section below
			sensorCopy: {},
			validationErrors:{},
			SaveRequest: null,
			allowChanges:false
		}
	},
	computed:{
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		},
        scheduleEnd(){
            return moment.unix(this.sensorCopy.threshold_schedule.end_time_unix).format('DD/MM/YYYY HH:mm')
        },
		_isCompositeChild(){
			return this.isCompositeSensor(this.sensorCopy.sensor_type_id);
		},
		hasChanged(){
			return !this._.isEqual(this.sensor,this.sensorCopy);
		},
		_isContactSensor(){
			return this.isContactSensor(this.sensorCopy.sensor_type_id);
		},
		alarm_on(){
			if(this._isContactSensor){
				if( Number(this.sensorCopy.threshold_high) == 1 && Number(this.sensorCopy.threshold_low) == 1 ){
					return 0;
				}else if( Number(this.sensorCopy.threshold_high) == 0 && Number(this.sensorCopy.threshold_low) == 0 ){
					return 1;
				}else{
					return -1;
				}
			}else{
				return false;
			}
		},
		contact_label_options(){
			var self=this;
			var labels_ = this.contact_labels.filter(label => {
				return Number(label.id) == Number(self.sensorCopy.contact_sensor_label_id);
			})[0];

			if(labels_){
				return labels_;
			}else{
				return {
					reading_0_label: 'Reading 0',
					reading_1_label: 'Reading 1',
				};
			}
		},
		date_added(){
			if(this.sensorCopy.date_added_unix){
				return this.moment.unix(this.sensorCopy.date_added_unix).format('DD/MM/YY HH:mm');
			}else{
				return '';
			}
		}
	},
	methods:{
		setThresholds(e){
			if(Number(e.target.value) == -1){
				this.sensorCopy.threshold_low="0";
				this.sensorCopy.threshold_high="1";
			}else if(Number(e.target.value) === 0){
				this.sensorCopy.threshold_low="1";
				this.sensorCopy.threshold_high="1";
			}else if(Number(e.target.value) == 1){
				this.sensorCopy.threshold_low="0";
				this.sensorCopy.threshold_high="0";
			}
		},
		validateForm(){

			//clear the array
			this.validationErrors = {};

			if( !this._.isEmpty(this.sensorCopy) ){

				//location name
				if( this.sensorCopy.location_name.length > 255 ){
					this.validationErrors.location_name = 'Too long';
				}

				//Threshold Low
				if(this.sensorCopy.range_max !== null){
					if( !this._.isFinite( Number(this.sensorCopy.threshold_low) )){
						this.validationErrors.threshold_low = 'Not a number';
					}else if( Number(this.sensorCopy.threshold_low) < Number(this.sensorCopy.range_min) ){
						this.validationErrors.threshold_low = 'Value too low';
					}else if( Number(this.sensorCopy.threshold_low) > Number(this.sensorCopy.range_max) || Number(this.sensorCopy.threshold_low) > Number(this.sensorCopy.threshold_high) ){
						this.validationErrors.threshold_low = 'Value too high';
					}
				}else{
					if( !this._.isFinite( Number(this.sensorCopy.threshold_low) )){
						this.validationErrors.threshold_low = 'Not a number';
					}else if( Number(this.sensorCopy.threshold_low) < Number(this.sensorCopy.range_min) ){
						this.validationErrors.threshold_low = 'Value too low';
					}else if( Number(this.sensorCopy.threshold_low) > Number(this.sensorCopy.threshold_high) ){
						this.validationErrors.threshold_low = 'Value too high';
					}
				}

				//Threshold High
				if(this.sensorCopy.range_max !== null){
					if( !this._.isFinite( Number(this.sensorCopy.threshold_high) )){
						this.validationErrors.threshold_high = 'Not a number';
					}else if( Number(this.sensorCopy.threshold_high) < Number(this.sensorCopy.range_min) ){
						this.validationErrors.threshold_high = 'Value too low';
					}else if( Number(this.sensorCopy.threshold_high) > Number(this.sensorCopy.range_max) ){
						this.validationErrors.threshold_high = 'Value too high';
					}else if(Number(this.sensorCopy.threshold_high) < Number(this.sensorCopy.threshold_low)){
						this.validationErrors.threshold_high = 'Value too low';
					}
				}else{
					if( !this._.isFinite( Number(this.sensorCopy.threshold_high) )){
						this.validationErrors.threshold_high = 'Not a number';
					}else if( Number(this.sensorCopy.threshold_high) < Number(this.sensorCopy.range_min) ){
						this.validationErrors.threshold_high = 'Value too low';
					}else if( Number(this.sensorCopy.threshold_high) < Number(this.sensorCopy.threshold_low) ){
							this.validationErrors.threshold_high = 'Value too low';
					}
				}

				//Alarm Delay
				if( !this._.isFinite( Number(this.sensorCopy.alarm_delay) ) ){
					this.validationErrors.alarm_delay = 'Not a number';
				}else if( Number(this.sensorCopy.alarm_delay) < 0 ){
					this.validationErrors.alarm_delay = 'Must be zero or greater';
				}else if( Number(this.sensorCopy.alarm_delay)*60 > Number(this.sensorCopy.max_delay) ){
					this.validationErrors.alarm_delay = 'Must be '+(this.sensorCopy.max_delay/60)+' or less';
				}

				//Log Interval
				if( !this._.isFinite( Number(this.sensorCopy.log_interval) ) ){
					this.validationErrors.log_interval = 'Not a number';
				}else if( Number(this.sensorCopy.log_interval) <= 0 ){
					this.validationErrors.log_interval = 'Must be greater than zero';
				}else if( Number(this.sensorCopy.log_interval) > 15 ){
					this.validationErrors.log_interval = 'Must be less than 16';
				}

				//Alarm Group
				if( !this._.isFinite( Number(this.sensorCopy.alarm_group_id) ) || Number(this.sensorCopy.alarm_group_id) > 2147483647 || Number(this.sensorCopy.alarm_group_id) < 0 ){
					this.validationErrors.alarm_group = 'Invalid Selection';
				}

				//SDR Group
				if( !this._.isFinite( Number(this.sensorCopy.sdr_group_id) ) || Number(this.sensorCopy.sdr_group_id) > 2147483647 || Number(this.sensorCopy.sdr_group_id) < 0 ){
					this.validationErrors.sdr_group = 'Invalid Selection';
				}

			}
		},
		closeOffcanvas(){
			if( this.hasChanged ){
				this.showAlert = true;
			}else{
				this.$parent.offcanvasState = 'closed';
			}
		},
		forceCloseOffcanvas(){
			this.sensorCopy = {};
			this.showAlert = false;
			this.$parent.offcanvasState = 'closed';
		},
		createCloneSensor(){
			this.sensorCopy = this._.cloneDeep(this.sensor);
		},
		saveChanges(){
			var self = this;
			var f = new FormData();

			for( var key in this.sensorCopy ){
				f.append(key, this.sensorCopy[key]);
			}

			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			this.showAlert=false;

			this.axios.post(this.controller('realtime_sensors/save_sensor_settings'), f, {
				cancelToken: this.SaveRequest.token
			}).then(function(response){
				if(response.data.status == 'failed' && !response.data.redirect){
					alert(response.data.message);
				}else if(response.data.status == 'success'){
					//check for response message
					if( response.data.message != '' ){
						self.responseMessage = response.data.message;
						//show the response
						self.showResponse = true;
					}

					//update the selectedSensor variable in the parent view
					//this is required as the current view is bound the this objects data
					self.$emit('update-selected',self.sensorCopy);

					//reload sensor data
					self.$parent.fetchSensors();

				}

				self.SaveRequest = null;
			});
		}
	},
	watch:{
		state(n){
			if(n == "open"){
				// this._.cloneDeep to create a clone rather than a reference to the original
				this.createCloneSensor();
			}
		},
		sensorCopy:{
				handler(){
					this.validateForm();
				},
				deep:true
		}
	}
}
</script>
