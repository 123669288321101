<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Password</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<div class="alert alert-danger mb-3" v-if="message" v-html="message"></div>
			<form-input v-model="oldPassword" type="password" label="Current Password"/>
			<hr/>
			<password-strength-input v-model="password" v-on:strength="v=>password_strength=v" :disabled="oldPassword.length==0"/>
			<form-input v-model="confirmPassword" type="password" label="Confirm Password" :disabled="password_strength < 4" :error="(confirmPassword.length > 0 && !_.isEqual(password,confirmPassword) ? 'Mismatch' : '')"/>
		</div>
	</div>
	<div class="offcanvas-footer" v-if="hasChanged">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="isSaving||!allowChanges">
			<span v-if="!isSaving">Save Changes</span>
			<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
		</button>
	</div>

	<unsaved-changes :show="showWarning" v-on:close="forceCloseOffcanvas" v-on:hide="showWarning=false" :save-disabled="!hasChanged" v-on:save="$refs.auth.check(saveChanges)"/>

	<alert v-if="showResponse">
		<div slot="title">{{responseTitle}}</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
	</alert>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import PasswordStrengthInput from '../../components/FormElements/PasswordStrength.vue';
import FormInput from '../../components/FormElements/Input.vue';
import UnsavedChanges from '../../components/UnsavedChangesAlert.vue';
import Alert from '../../components/Alert.vue';

export default{
	props:['state'],
	components:{
		PasswordStrengthInput,
		FormInput,
		UnsavedChanges,
		Alert
	},
	computed:{
		hasChanged(){
			if( this.password_strength >= 4 && this.password == this.confirmPassword && this.oldPassword != '' ){
				return true;
			}else{
				return false;
			}
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		}
	},
	mounted(){
		this.message=this.$session.flash.get('message');
	},
	data(){
		return {
			password:'',
			oldPassword:'',
			password_strength:0,
			confirmPassword:'',
			showWarning:false,
			responseTitle:'',
			responseMessage:'',
			showResponse:false,
			SaveRequest: null,
			allowChanges: false,
			message:''
		}
	},
	methods:{
		reset(){
			this.oldPassword='';
			this.password='';
			this.password_strength=0;
			this.confirmPassword='';
			this.showWarning=false;
			this.responseTitle='';
			this.responseMessage='';
		},
		closeOffcanvas(){
			if( this.password != '' || this.confirmPassword != '' ){
				this.showWarning = true;
			}else{
				this.forceCloseOffcanvas();
			}
		},
		forceCloseOffcanvas(){
			this.reset();
			this.$emit('close');
		},
		saveChanges(){
			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			var f = new FormData();
			f.append('old',this.oldPassword);
			f.append('new',this.password);
			f.append('new_confirm',this.confirmPassword);

			var self = this;
			this.showWarning=false;

			this.axios.post( this.controller('auth/change_password') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				if( res.data.status == 'failed' ){
					self.responseTitle='Error';
					self.responseMessage=res.data.message;
					self.showResponse=true;
				}

				self.SaveRequest = null;
			});
		}
	}
}
</script>
