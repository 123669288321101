<template>
<nav>
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><router-link to="/"><font-awesome-icon icon="home" fixed-width /></router-link></li>
		<li class="breadcrumb-item" v-for="(path, index) in $root.$data.breadcrumb" :key="index">
			<span @click.capture="check">
				<router-link :to="path[1]" :disabled="index == $root.$data.breadcrumb.length-1 || path[2]=='disabled'">{{path[0]}}</router-link>
			</span>
		</li>
	</ol>
</nav>
</template>

<script>
export default{
	methods:{
		check(e){
			if( typeof e.target.attributes.disabled != "undefined" ){
				e.preventDefault();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
nav{
	.breadcrumb{
		font-size:0.8em;
		margin-bottom:0;
		a{
			color:#222;
			&[disabled]{
				color:#777;
				&:hover{
					text-decoration:none;
				}
			}
		}
	}
}
</style>