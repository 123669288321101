<template>
	<div>
		<nav class="navbar navbar-expand-sm navbar-light bg-light fixed-top p-0">
			<div class="w-100 d-flex justify-content-between">
				<button type="button" class="btn btn-light" data-toggle="left_menu" v-on:click="toggleMenu('left')"><font-awesome-icon icon="bars" fixed-width /></button>
				<a href="#">
					<img :src="'./assets/large_logo_alpha.png'" style="max-height:40px;" class="my-2"/>
				</a>
				<button type="button" class="btn btn-light" data-toggle="right_menu" v-on:click="toggleMenu('right')"><font-awesome-icon icon="user" fixed-width /></button>
			</div>
		</nav>

		<left-menu v-bind:state="this.$root.leftMenuState"></left-menu>
		<right-menu v-bind:state="this.$root.rightMenuState"></right-menu>
	</div>
</template>

<style lang="scss">
.navbar{
	&.fixed-top{
		box-shadow: 0 0 20px #000;
	}
}


.fade{
	z-index:1031;
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	width:0;
	background:rgba(0,0,0,0);
	-webkit-transition: background 250ms;
	-moz-transition: background 250ms;
	-o-transition: background 250ms;
	transition: background 250ms;

	&.show{
		width:100%;
		background:rgba(0,0,0,0.5);
	}
}

%menu {
	position:fixed;
	overflow:hidden;
	z-index:1032;
	top:57px;
	bottom:0;
	width:0;
	background:#eee;
	-webkit-transition: width 250ms ease-in-out;
	-moz-transition: width 250ms ease-in-out;
	-o-transition: width 250ms ease-in-out;
	transition: width 250ms ease-in-out;

	a{
		display:block;
		width:100%;
		padding:15px;
		color:#000;
		border-bottom:1px solid #ccc;
		text-decoration:none;
		white-space: nowrap;
	}
}

.menu{
	&.left,
	&.right{
		@extend %menu;

		&.show{
			width:80%;
		}
	}

	&.left{
		left:0;
	}
	&.right{
		right:0;
	}
}
</style>

<script>
import LeftMenu from './LeftMenu.vue';
import RightMenu from './RightMenu.vue';

export default{
	components:{
		LeftMenu,
		RightMenu
	}
}
</script>
