<template>
  <div class="sensor_entity" @click="()=>{ if(!((showClones && (numEntity+1)!=sensor.cloneNo))){$emit('toggle',entity)}; }" :class="{stale: entity._sensor_stale, active: (selectedSensorIds.indexOf(entity.id) > -1) && !((showClones && (numEntity+1)!=sensor.cloneNo)), disabled: (showClones && (numEntity+1)!=sensor.cloneNo), 'in-bounds': Number(entity._actual_reading) > Number(entity.threshold_low) && Number(entity._actual_reading) < Number(entity.threshold_high), 'out-of-bounds-high': Number(entity._actual_reading) > Number(entity.threshold_high), 'out-of-bounds-low': Number(entity._actual_reading) < Number(entity.threshold_low)}">
    <div class="alarm_icon">
      <template v-if="Number($store.state.user.preferences.realtime_sensors.sensor_node_alarm_status) == 1">
        <img :src="'./assets/misc_icons/exclaim.png'" v-if="['5'].indexOf(entity.latest_alarm_status) > -1"/>
        <img @click.stop="alarmInformation(entity.id)" class="icon_button" :src="'./assets/misc_icons/exclaim.png'" v-if="['3'].indexOf(entity.latest_alarm_status) > -1"/>
        <img :src="'./assets/misc_icons/warning.png'" v-if="['4'].indexOf(entity.latest_alarm_status) > -1"/>
        <img :src="'./assets/misc_icons/tick.png'" v-if="['1'].indexOf(entity.latest_alarm_status) > -1"/>
        <img @click.stop="alarmInformation(entity.id)" class="icon_button" :src="'./assets/misc_icons/persistent-alarm-icon-red.png'" v-if="['10'].indexOf(entity.latest_alarm_status) > -1"/>
        <img @click.stop="alarmInformation(entity.id)" class="icon_button" :src="'./assets/misc_icons/persistent-alarm-icon-grey.png'" v-if="['11'].indexOf(entity.latest_alarm_status) > -1"/>
      </template>
    </div>
    <div class="remedial_wrapper">
      <template v-if="Number($store.state.user.preferences.realtime_sensors.sensor_node_pending_remedials) == 1 && Number(entity.pending_remedial_count) > 0">
        <div class="d-inline-block remedial">{{entity.pending_remedial_count}}</div>
      </template>
    </div>

    <div class="text-center">
      <!-- reading and thresholds -->
      <div class="mb-0">
        <img v-if="entity._sensor_reading_output_pre" :src="'img/'+entity._sensor_reading_output_pre" style="height:15px;" class="mr-1"/>
        <span v-if="_.isFinite(Number(entity.latest_reading_adjustment)) && Number(entity.latest_reading_adjustment) != 0">&#177; </span>
        <img v-if="entity.sensor_type_id == 22" @click.stop="co2Setting = !co2Setting" :src="'./assets/misc_icons/switch.png'" class="switch_units" />
        <span v-html="entityReading" :class="{'in-bounds': Number(entity._actual_reading) > Number(entity.threshold_low) && Number(entity._actual_reading) < Number(entity.threshold_high), 'out-of-bounds-high': Number(entity._actual_reading) > Number(entity.threshold_high), 'out-of-bounds-low': Number(entity._actual_reading) < Number(entity.threshold_low)}"></span>
      </div>
      <small>
        <template v-if="!isContactSensor(entity.sensor_type_id)">
            <div v-if="!entity.threshold_schedule">
                <font-awesome-icon icon="chevron-down" fixed-width />
                <span v-html="thresholdLow"></span>
                <font-awesome-icon icon="chevron-up" fixed-width />
                <span v-html="thresholdHigh"></span>
            </div>
            <div v-if="entity.threshold_schedule">
                <font-awesome-icon icon="clock" fixed-width />
                <font-awesome-icon icon="chevron-down" fixed-width />
                <span v-html="entity.threshold_schedule.threshold_low"></span>
                <font-awesome-icon icon="chevron-up" fixed-width />
                <span v-html="entity.threshold_schedule.threshold_high"></span>
                <div style="font-weight:bold;color:red;">Scheduled threshold change in progess<br/>Ending: {{thresholdScheduleEnd}}</div>
            </div>
        </template>
        <template v-if="isContactSensor(entity.sensor_type_id)">
          Alarm On: {{alarmOn(entity.contact_sensor_label_id,entity.threshold_low,entity.threshold_high)}}
        </template>
      </small>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "SingleSensorEntity",
  props: [
    'entity',
    'sensor',
    'selectedSensorIds',
    'showClones',
    'numEntity',
    'contactLabels'
  ],
  data() {
    return {
      co2Setting: {
        type: Boolean,
        default: false
      }
    }
  },
  mounted() {
    this.co2Setting = this.entity.original_co2_setting
  },
  computed: {
    thresholdScheduleEnd(){
       return moment.unix(this.entity.threshold_schedule.end_time_unix).format('DD/MM/YYYY HH:mm') 
    },
    co2DisplayUnit() {
      return this.co2Setting ? 'PPM' : '%';
    },
    entityReading() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity._actual_reading) + this.co2DisplayUnit;
      }
      return this.entity._sensor_reading_output
    },
    thresholdHigh() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity.threshold_high);
      }
      return this.entity.threshold_high;
    },
    thresholdLow() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity.threshold_low);
      }
      return this.entity.threshold_low;
    },
  },
  methods: {
    alarmInformation(id) {
      this.$emit('alarmInformation', id);
    },
    prepCo2Value(value) {
      if (!this.co2Setting) {
        if (this.entity.original_co2_setting) {
          value = this._.round(value / 10000, 2);
        }
      } else {
        if (!this.entity.original_co2_setting) {
          value = this._.round(value * 10000, 2);
        }
      }
      return value
    },
    alarmOn(label_id,low_threshold,high_threshold){
      //return label_id+' '+low_threshold+' '+high_threshold;
      var label_ = this.contactLabels.filter(label => {
        return Number(label.id) == Number(label_id);
      })[0];
      if(label_){
        if( Number(high_threshold) == 1 && Number(low_threshold) == 1 ){
          return label_.reading_0_label;
        }else if( Number(high_threshold) == 0 && Number(low_threshold) == 0 ){
          return label_.reading_1_label;
        }else{
          return 'None';
        }
      }else{
        if( Number(high_threshold) == 1 && Number(low_threshold) == 1 ){
          return 'Reading 0';
        }else if( Number(high_threshold) == 0 && Number(low_threshold) == 0 ){
          return 'Reading 1';
        }else{
          return 'None';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/_vars.scss";

$single_stack_max_width: 360px;
@mixin bigScreen{
  @media only screen and (min-width: $single_stack_max_width) {
    @content;
  }
}

.composite_settings_cog{
  color:lighten(#000,50);
  margin-left:5px;
  margin-bottom:-3px;
  height:25px;
  width:25px;
  padding:6px;
  &:focus{
    color:#000;
  }
}

.sensor_entities{
  margin:5px -5px;

  @include bigScreen{
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }

  .sensor_entity{
    @include borderRadius();
    border:1px solid #ccc;
    padding:3px;
    flex-grow:1;
    position:relative;
    margin:5px;
    box-sizing:border-box;
    min-width:145px;
    
    &.stale{
        border-color:red;
    }

    &.active{
      &.out-of-bounds-high{
        background:$selected_high;
      }
      &.out-of-bounds-low{
        background:$selected_low;
      }
      &.in-bounds{
        background:$selected_in_bounds;
      }
    }

    &.disabled{
      color:#ccc;
      .in-bounds,
      .out-of-bounds-high,
      .out-of-bounds-low{
        color:#ccc !important;
      }
    }

    .alarm_icon{
      position:absolute;
      top:2px;
      left:3px;

      .icon_button{
        border:1px solid #ccc;
        background:#eee;
        padding:4px;
      }
    }

    .remedial_wrapper{
      position:absolute;
      top:0px;
      right:2px;

      .remedial{
        background:red;
        color:#fff;
        font-weight:bold;
        padding:3px 10px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        font-size:0.7em;
      }
    }

    .out-of-bounds-high{
      color:red;
    }
    .out-of-bounds-low{
      color:blue;
    }
    .in-bounds{
      color:$green;
    }
  }
}
.reading_adjustment{
  font-size:0.8em;
}

</style>
