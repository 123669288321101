<template>
	<div class="sensors">
		<div class="accordion has_caret" id="sensorFilters">
			<div class="card" style="overflow:visible !important;">
				<div class="card-header" :class="{collapsed:!showFilters,open:showFilters}" @click="showFilters=!showFilters">
					<div class="d-flex justify-content-between align-items-center">
						<div>Filters</div>
						<font-awesome-icon icon="chevron-right"/>
					</div>
				</div>

				<div class="collapse" :class="{show:showFilters}">
					<div class="card-body">
						<div class="form-group">
							<label>Group</label>
							<multiselect :options="groupsArray" v-model="alarm_groups.selected" :show-labels="false" :multiple="true" track-by="value" :custom-label="({label,value})=>{return label;}">
								<template slot="option" slot-scope="props">
									{{ props.option.label }}
								</template>
							</multiselect>
						</div>

						<div class="form-group">
							<label>Sort</label>
							<div class="d-flex">
								<div class="flex-grow-1 mr-1">
									<select-input v-model="sort.by" :options="sort_options" :searchable="false" @change="forceRerenderAfterChangingSort" />
								</div>
								<div><button class="btn btn-light h-100" v-on:click="toggleAscDesc(sort)"><font-awesome-icon :icon="'sort-amount-'+(sort.direction == 'ASC' ? 'up' : 'down')" fixed-width /></button></div>
							</div>
						</div>

						<div class="form-group" v-if="sort.by && secondary_sort_for.indexOf( sort.by ) > -1">
							<label>Secondary Sort</label>
							<div class="d-flex">
								<div class="flex-grow-1 mr-1">
									<select-input v-model="secondary_sort.by" :options="sort_options.filter(option => option.value != sort.by)" @change="forceRerenderAfterChangingSort" />
								</div>
								<div><button class="btn btn-light h-100" v-on:click="toggleAscDesc(secondary_sort)"><font-awesome-icon :icon="'sort-amount-'+(secondary_sort.direction == 'ASC' ? 'up' : 'down')" fixed-width /></button></div>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>

		<search-pagination :array="sortedSensors" v-on:data="results=>{pageData=results}"/>

		<div v-if="sortedSensors.length == 0 && loading == false" class="bg-white container-fluid text-center py-3">
			No sensors
		</div>
		<loading :state="loading"></loading>
		<ul class="list-group">
			<li class="list-group-item" v-for="(sensor,i) in pageData" :key="i" :class="{/*stale: sensor._sensor_stale == 1*/}" v-on:click="/*toggleSelect(sensor)*/">
				<SingleSensor :sensor="sensor" :selectedSensorIds="selectedSensorIds" :contact_labels="contact_labels" :showClones="showClones" v-on:toggle="id=>{toggleSelect(id)}" v-on:alarmInformation="id=>{alarmInformation(id)}" v-on:select-composite="sensor=>{selectComposite(sensor)}"/>
			</li>
		</ul>

		<offcanvas-bottom :selected="selectedSensors" :allow-comparison="allowSensorGraphComparison" :multipleContactSensorsSelected="multipleContactSensorsSelected" v-on:show-graph="showGraph" v-on:show-daily="dailyState='open'" v-on:change-range="setDateRange" :date-range="date" v-on:clearSelection="clearSelection"></offcanvas-bottom>

		<edit-offcanvas
			v-if="selectedSensors[0]"
			:state="offcanvasState"
			:sensor="selectedSensors[0]"
			:alarm_groups="alarm_groups.options"
			:sdr_groups="sdr_groups.options"
			:contact_labels="contact_labels"
			v-on:update-selected="updateSelectedSensor"
		></edit-offcanvas>

		<edit-composite-offcanvas
			:state="compositeOffcanvasState"
			:sensor="selectedCompositeSensor"
			:alarm_groups="alarm_groups.options"
			:sdr_groups="sdr_groups.options"
			v-on:update-selected-composite="updateSelectedCompositeSensor"
		></edit-composite-offcanvas>


		<graph :state="graphState" :sensors="selectedSensors" :date-range="date" v-on:close="graphState='closed'"/>
		<daily :state="dailyState" :sensors="selectedSensors" :date-range="date" v-on:close="dailyState='closed'"/>

		<alert v-if="!allowSensorGraphComparison && !comparisonWarningShown">
			<div slot="title">
				Information
			</div>
			You have selected sensors of different types. This means you can only view the daily report and will NOT be able to view them on a sensor graph.
			<div slot="buttons">
				<button class="btn btn-default btn-block" @click="comparisonWarningShown=true">Ok</button>
			</div>
		</alert>

		<alarm-information :state="(alarmInformationSensorId ? 'open' : 'closed')" :sensorId="alarmInformationSensorId"/>
	</div>
</template>

<style lang="scss" scoped>
@import "../assets/_vars.scss";
.list-group{
	.list-group-item{

		&.active{
			background:$selected;
			border-color:darken($selected,5);
			color:#000;
		}

		&.stale{
			border-left:5px solid red;
			border-right:5px solid red;
		}

		margin-bottom:5px;
	}
}

.dropdown{
	&.v-select{
		.dropdown-menu{
			position: relative !important;
		}
	}
}
.dropdown-toggle::after{
	display:none !important;
}
</style>

<script>
import Loading from '../components/Loading.vue';
import OffcanvasBottom from '../components/RealtimeSensors/OffcanvasBottom.vue';
import EditOffcanvas from '../components/RealtimeSensors/EditOffcanvas.vue';
import EditCompositeOffcanvas from '../components/RealtimeSensors/EditCompositeOffcanvas.vue';
import Graph from '../components/RealtimeSensors/GraphCanvas.vue';
import Daily from '../components/RealtimeSensors/DailyGraphCanvas.vue';
import SearchPagination from '../components/SearchPagination.vue';
import SelectInput from '../components/FormElements/Select.vue';
import Alert from '../components/Alert.vue';
import AlarmInformation from '../components/AlarmEvents/AlarmInformation.vue';
import SingleSensor from '../components/RealtimeSensors/SingleSensor.vue';

export default{
	components:{
		Loading,
		OffcanvasBottom,
		EditOffcanvas,
		Graph,
		Daily,
		SearchPagination,
		SelectInput,
		Alert,
		AlarmInformation,
		SingleSensor,
		EditCompositeOffcanvas
	},
	data(){
		return {
			selectedCompositeSensor: null,
			compositeOffcanvasState: 'closed',
			showFilters: false,
			alarmInformationSensorId:'',
			offcanvasState: 'closed',
			graphState:'closed',
			dailyState:'closed',
			selectedSensors:[],
			selectedSensorIds:[],
			loading: false,
			interval:null,
			date:{
				from: '',
				to: ''
			},
			alarm_groups:{
				selected:[],
				options:[]
			},
			sdr_groups:{
				options:[]
			},
			sensors:[],
			contact_labels:[],
			sort_options:[
				{label: 'Reading Time', value: 0},
				{label: 'Reading Value', value: 1},
				{label: 'Out of Bounds', value: 2},
				{label: 'Location Name', value: 3},
				{label: 'Sensor ID', value: 4},
				{label: 'Sensor Type', value: 5},
				{label: 'Sensor Stale', value: 6},
				{label: 'Alarm Status', value: 7},
				{label: 'Pending Remedials', value: 8},
				{label: 'Battery Level', value: 9},
				{label: 'Alarm Group', value: 10},
			],
			secondary_sort_for:[
				2,3,5,6,7,8,9,10
			],
			sort:{
				by: 0,
				direction: 'ASC'
			},
			secondary_sort:{
				by: null,
				direction: 'ASC'
			},
			pageData:[],
			comparisonWarningShown:false,
			clonesCreated: false
		}
	},
	computed:{
		allowSensorGraphComparison(){
			//compare selection and if all are same unit type allow comparison.
			var allow = true;
			var first_type = null;//they should all be the same as this one.
			this.selectedSensors.forEach(sensor=>{
				if(!first_type){
					first_type = sensor.display_unit;
				}else{
					if(first_type != sensor.display_unit){
						allow = false;
					}
				}
			});

			return allow;
		},
		multipleContactSensorsSelected(){
			var contact_sensors = 0;
			this.selectedSensors.forEach(sensor=>{
				if(this.isContactSensor(sensor.sensor_type_id)){
					contact_sensors++;
				}
			});

			//are there more than one contact sensors?
			if(contact_sensors > 1){
				return true;
			}else{
				return false;
			}
		},
		sortedSensors(){
			if( this.sort.by == null ){
				return this.filteredSensors;
			}else{
				var filtered = this.filteredSensors;
				return filtered.sort((a,b) => {
					var ret = null;
					var c,d;

					//which entity to sort by?
					var sort_a_entity = Number(a.cloneNo)-1;
					var sort_b_entity = Number(b.cloneNo)-1;

					//primary sort
					switch( this.sort.by ){
						default:
						case 0:
							// eslint-disable-next-line
							ret = this._sort( Number(a.reading_timestamp_unix), Number(b.reading_timestamp_unix), this.sort.direction );
						break;

						case 1:
							//by reading value
							// eslint-disable-next-line
							ret = this._sort( Number(a.entities[sort_a_entity]._actual_reading), Number(b.entities[sort_b_entity]._actual_reading), this.sort.direction );
						break;

						case 2:
							c,d=null;

							//by out of bounds
							if( Number(a.entities[sort_a_entity]._actual_reading) > Number(a.entities[sort_a_entity].threshold_high) ){
								c = 1;
							}else if( Number(a.entities[sort_a_entity]._actual_reading) < Number(a.entities[sort_a_entity].threshold_low) ){
								c = -1;
							}else{
								c = 0;
							}

							if( Number(b.entities[sort_b_entity]._actual_reading) > Number(b.entities[sort_b_entity].threshold_high) ){
								d = 1;
							}else if( Number(b.entities[sort_b_entity]._actual_reading) < Number(b.entities[sort_b_entity].threshold_low) ){
								d = -1;
							}else{
								d = 0;
							}

							// eslint-disable-next-line
							ret = this._sort( c, d, this.sort.direction );
						break;

						case 3:
							//by location name
							// eslint-disable-next-line
							ret = this._sort( a.name, b.name, this.sort.direction );
						break;

						case 4:
							//by sensor id
							// eslint-disable-next-line
							ret = this._sort( Number(a.id), Number(b.id), this.sort.direction );
						break;

						case 5:
							//by sensor type
							// eslint-disable-next-line
							ret = this._sort( Number(a.entities[sort_a_entity].sensor_type_id), Number(b.entities[sort_b_entity].sensor_type_id), this.sort.direction );
						break;

						case 6:
							//by sensor stale
							// eslint-disable-next-line
							ret = this._sort( a.entities[sort_a_entity]._sensor_stale, b.entities[sort_b_entity]._sensor_stale, this.sort.direction );
						break;

						case 7:
							//by alarm status
							// eslint-disable-next-line
							ret = this._sort( Number(a.entities[sort_a_entity].latest_alarm_status), Number(b.entities[sort_b_entity].latest_alarm_status), this.sort.direction );
						break

						case 8:
							//by pending remedials
							// eslint-disable-next-line
							ret = this._sort( Number(a.entities[sort_a_entity].pending_remedial_count), Number(b.entities[sort_b_entity].pending_remedial_count), this.sort.direction );
						break;

						case 9:
							//by battery level
							// eslint-disable-next-line
							ret = this._sort( this.batterySortLevel(a.entities[sort_a_entity]), this.batterySortLevel(b.entities[sort_b_entity]), this.sort.direction );
						break;

						case 10:
							//by alarm group
							// eslint-disable-next-line
							ret = this._sort( a.alarm_group_name, b.alarm_group_name, this.sort.direction );
						break;
					}

					if(ret != null)return ret;

					//secondary sort
					if( this.secondary_sort.by ){
						switch( this.secondary_sort.by ){
							default:
							case 0:
								// eslint-disable-next-line
								ret = this._sort( Number(a.reading_timestamp_unix), Number(b.reading_timestamp_unix), this.secondary_sort.direction );
							break;

							case 1:
								//by reading value
								// eslint-disable-next-line
								ret = this._sort( Number(a.entities[sort_a_entity]._actual_reading), Number(b.entities[sort_b_entity]._actual_reading), this.secondary_sort.direction );
							break;

							case 2:
								c,d=null;

								//by out of bounds
								if( Number(a.entities[sort_a_entity]._actual_reading) > Number(a.entities[sort_a_entity].threshold_high) ){
									c = 1;
								}else if( Number(a.entities[sort_a_entity]._actual_reading) < Number(a.entities[sort_a_entity].threshold_low) ){
									c = -1;
								}else{
									c = 0;
								}

								if( Number(b.entities[sort_b_entity]._actual_reading) > Number(b.entities[sort_b_entity].threshold_high) ){
									d = 1;
								}else if( Number(b.entities[sort_b_entity]._actual_reading) < Number(b.entities[sort_b_entity].threshold_low) ){
									d = -1;
								}else{
									d = 0;
								}
								// eslint-disable-next-line
								ret = this._sort( c, d, this.secondary_sort.direction );
							break;

							case 3:
								//by location name
								// eslint-disable-next-line
								ret = this._sort( a.name, b.name, this.secondary_sort.direction );
							break;

							case 4:
								//by sensor id
								// eslint-disable-next-line
								ret = this._sort( Number(a.id), Number(b.id), this.secondary_sort.direction );
							break;

							case 5:
								//by sensor type
								// eslint-disable-next-line
								ret = this._sort( Number(a.entities[sort_a_entity].sensor_type_id), Number(b.entities[sort_b_entity].sensor_type_id), this.secondary_sort.direction );
							break;

							case 6:
								//by sensor stale
								// eslint-disable-next-line
								ret = this._sort( a.entities[sort_a_entity]._sensor_stale, b.entities[sort_b_entity]._sensor_stale, this.secondary_sort.direction );
							break;

							case 7:
								//by alarm status
								// eslint-disable-next-line
								ret = this._sort( Number(a.entities[sort_a_entity].latest_alarm_status), Number(b.entities[sort_b_entity].latest_alarm_status), this.secondary_sort.direction );
								break

							case 8:
								//by pending remedials
								// eslint-disable-next-line
								ret = this._sort( Number(a.entities[sort_a_entity].pending_remedial_count), Number(b.entities[sort_b_entity].pending_remedial_count), this.secondary_sort.direction );
								break;

							case 9:
								//by battery level
								// eslint-disable-next-line
								ret = this._sort( this.batterySortLevel(a.entities[sort_a_entity]), this.batterySortLevel(b.entities[sort_b_entity]), this.secondary_sort.direction );
							break;

							case 10:
								//by alarm group
								// eslint-disable-next-line
								ret = this._sort( a.alarm_group_name, b.alarm_group_name, this.secondary_sort.direction );
							break;
						}
					}

					if(ret != null){
						return ret;
					}else{
						return 0;
					}


				});
			}
		},
		filteredSensors(){
			var self = this;
			var return_data=[];
			for(const id in this.sensors){
				if( self.alarm_groups.selected == '' || self.selectedIds.indexOf(self.sensors[id].alarm_group_id) > -1 ){
					return_data.push(self.sensors[id]);
				}
			}
			return return_data;
		},
		groupsArray(){
			return this.alarm_groups.options.filter((option)=>{
				if(Number(option.user_has_group) == 1){
					return true;
				}else{
					return false;
				}
			}).map((option)=>{
				return {label: option.name, value: option.id};
			});
		},
		selectedIds(){
			return this.alarm_groups.selected.map((option)=>{
				return option.value;
			});
		},
		showClones(){
			return ([1,5,8].indexOf(Number(this.sort.by)) > -1);
		}
	},
	methods:{
		selectComposite(sensor){
			this.selectedCompositeSensor = sensor;
			this.compositeOffcanvasState = 'open';
		},
		alarmInformation(sensor_id){
			this.alarmInformationSensorId=sensor_id;
		},
		batterySortLevel(sensor){
			var battery_level_sort = 0;
			if( Number(sensor.has_power_level) == 1 && sensor.sensor_power_level !== null ){
				if( Number(sensor.hardware_manufacturer_id) == 1 || Number(sensor.hardware_manufacturer_id) == 4 ){
					battery_level_sort = Number(sensor.sensor_power_level);
				}else if( Number(sensor.hardware_manufacturer_id) == 2 ){
					if(Number(sensor.sensor_power_level) == 1){
						battery_level_sort = 0; //bad
					}else{
						battery_level_sort = 2; //good
					}
				}
			}
			return battery_level_sort;
		},
		fetchSensors(){
			var self = this;
			return this.axios.get(this.controller('realtime_sensors')).then(function(response){
				self.sensors = response.data.data;
				self.contact_labels = response.data.contact_labels;
				self.clonesCreated=false;//refreshing the data removes all the clones so this variable needs to be set accordingly
				self.clones();
				self.loading=false;
			});
		},
		toggleSelect(sensor){
			var _this = this;
			var pos = this.selectedSensorIds.indexOf(sensor.id);
			if( pos > -1 ){
				this.selectedSensorIds.splice(pos,1);
			}else{
				this.selectedSensorIds.push(sensor.id);
			}

			//rebuild selectedSensors array
			this.selectedSensors = [];
			var found = [];
			for(const sensor in this.sensors){
				for(const entity in _this.sensors[sensor].entities){
					if( _this.selectedSensorIds.indexOf(_this.sensors[sensor].entities[entity].id) > -1 && found.indexOf(_this.sensors[sensor].entities[entity].id) < 0 ){
						_this.selectedSensors.push(_this.sensors[sensor].entities[entity]);
						found.push(_this.sensors[sensor].entities[entity].id);
					}
				}
			}
		},
		updateSelectedSensor(sensor){
			this.selectedSensors[0]= this._.cloneDeep(sensor);
		},
		updateSelectedCompositeSensor(sensor){
			this.selectedCompositeSensor= this._.cloneDeep(sensor);
		},
		clearSelection(){
			this.selectedSensors=[];
			this.selectedSensorIds=[];
		},
		fetchAlarmGroups(){
			var self = this;
			this.axios.get(this.controller('realtime_sensors/alarm_groups')).then(function(response){
				self.alarm_groups.options = response.data.data.alarm_groups;
				self.sdr_groups.options = response.data.data.sdr_groups;
			});
		},
		toggleAscDesc(filterSort){
			if(filterSort.direction == "ASC"){
				filterSort.direction = "DESC";
			}else{
				filterSort.direction = "ASC";
			}
			this.forceRerenderAfterChangingSort();
		},
		_sort(a,b,direction){
			var dir;
			if( a < b ){
				dir = (direction == "ASC" ? 1 : -1);
			}else if( a > b ){
				dir = (direction == "ASC" ? -1 : 1);
			}
			return dir;
		},
		forceRerenderAfterChangingSort(){
			//create sensor clones if they are needed
			this.clones();

			/*
			This is a bit hacky but required to force detection of re-ordering in the pagination plugin
			the inbuilt watch feature doesn't detect change in order alone.
			*/
			var x = this.sensors;
			this.sensors = null;
			this.sensors = x;
		},
		clones(){
			//do we need to clone sensors - for composite sensors, some sorting options require rows to be duplicated to appear in more than one position
			if( this.showClones ){
				this.addClones();
			}else{
				this.removeClones();
			}
		},
		addClones(){
			//check if the clones already exist??
			if( this.clonesCreated == false ){
				//create clones
				for( const id in this.sensors ){
					if(this.sensors[id].entities.length > 1){
						//this sensor is composite, so create a clone for each entity
						for(var i = 1; i<this.sensors[id].entities.length; i++){
							var clone_number = i+1;
							this.sensors[`${id}_${clone_number}`]=this._.cloneDeep(this.sensors[id]);
							this.sensors[`${id}_${clone_number}`].cloneNo=clone_number;
						}
					}
				}
				this.clonesCreated = true;
			}
		},
		removeClones(){
			//remove all clones; sensors with a cloneNo > 1
			for(const i in this.sensors){
				if( Number(this.sensors[i].cloneNo) > 1 ){
					delete this.sensors[i];
				}
			}
			this.clonesCreated = false;
		},
		showGraph(){
			this.graphState='open';
		},
		setDateRange(obj){
			if( obj.from ){
				this.date.from = obj.from;
				if( this.moment(this.date.from,'YYYY-MM-DD').unix() > this.moment(this.date.to,'YYYY-MM-DD').unix() ){
					this.date.to=this.date.from;
				}
			}else if( obj.to ){
				this.date.to = obj.to;
				if( this.moment(this.date.from,'YYYY-MM-DD').unix() > this.moment(this.date.to,'YYYY-MM-DD').unix() ){
					this.date.from=this.date.to;
				}
			}
		}
	},
	mounted(){
		//only set loading first request (it will keep pushing content down the page)
		this.loading=true;
		this.fetchSensors();

		//populate the alarm groups array
		this.fetchAlarmGroups();

		//default date range
		this.date.from = this.moment().format('YYYY-MM-DD');
		this.date.to = this.moment().format('YYYY-MM-DD');

		//check user sorting preferences
		if( Number(this.$store.state.user.preferences.realtime_sensors.sensor_node_save_sorting) == 2 ){
			var settings = this.$store.state.user.preferences.realtime_sensors.sensor_node_sorting_override;
			this.sort.by = Number(settings.sort_by);
			this.sort.direction = settings.sort_direction.toUpperCase();

			if( Number(settings.secondary_flag) == 1 ){
				this.secondary_sort.by = Number(settings.secondary_sort_by);
				this.secondary_sort.direction = settings.secondary_sort_direction.toUpperCase();
			}
		}

		this.$emit('breadcrumb',[
			['Realtime Sensors','/realtime-sensors']
		]);

		var self = this;
		this.interval = setInterval(function(){
			self.fetchSensors();
		},30000);
	},
	beforeDestroy(){
		clearInterval(this.interval);
	}

}
</script>
