<template>
<div style="white-space:nowrap !important;">
	<div class="btn-group" v-if="numberOfPages > 1">
		<button type="button" name="button" class="btn btn-light" v-on:click="previousPage" :disabled="currentPage == 1"><font-awesome-icon icon="caret-left"/></button>
		<button type="button" name="button" class="btn btn-secondary">{{currentPage}}<span v-if="numberOfPages > 1">/{{ (numberOfPages > 0 ? numberOfPages : 1) }}</span></button>
		<button type="button" name="button" class="btn btn-light" v-on:click="nextPage" :disabled="currentPage == numberOfPages"><font-awesome-icon icon="caret-right"/></button>
	</div>
	<button class="ml-2 btn px-1 text-secondary" style="font-size:0.8em;" @click="showAlert=true"><font-awesome-icon icon="ellipsis-v" /></button>
	<alert v-if="showAlert">
		<div slot="title">Filters</div>
		<div class="form-group">
			<label>Results per page</label>
			<select class="form-control" v-model="itemsPerPage" v-on:change="showAlert=false">
				<option v-for="n in [2,5,10,25,50]" :value="n" :key="n">{{n}}</option>
			</select>
		</div>
		<div v-if="sortby && sortby.length > 0">
			<label>Sort</label>
			<div class="d-flex">
				<div class="flex-grow-1 mr-1">
					<select class="form-control" v-model="selectedSortBy">
						<option v-for="o in filteredSortBy" :value="o.value" :key="o.value">{{o.label}}</option>
					</select>
				</div>
				<div><button class="btn btn-light h-100" v-on:click="toggleAscDesc()"><font-awesome-icon :icon="'sort-amount-'+(sortDirection == 'ASC' ? 'up' : 'down')" fixed-width /></button></div>
			</div>
		</div>
		<div slot="buttons">
			<button class="btn btn-secondary btn-block" @click="()=>{if(itemsPerPage==''){itemsPerPage=1;}showAlert=false;}">Close</button>
		</div>
	</alert>
</div>
</template>

<script>
import Alert from './Alert.vue';
export default{
	components:{
		Alert
	},
	props:{
		array: Array,
		perPage:{
			type: [Number,String],
			default: 25
		},
		filters: Array,
		sortby: Array
	},
	data(){
		return {
			currentPage:1,
			itemsPerPage:1,
			numberOfPages:1,
			showAlert:false,
			sortDirection:'ASC',
			selectedSortBy:''
		}
	},
	methods:{
		nextPage(){
			this.currentPage++;
		},
		previousPage(){
			this.currentPage--;
		},
		setVariables(){
			this.numberOfPages = Math.ceil( Number(this.array.length)/this.itemsPerPage );
			
			//check current page number still in range of number of pages
			if(this.currentPage > this.numberOfPages){
				this.currentPage = 1;
			}
			
			this.$emit('data',this.resultSet);
		},
		toggleAscDesc(){
			if(this.sortDirection == "ASC"){
				this.sortDirection = "DESC";
			}else{
				this.sortDirection = "ASC";
			}
		}
	},
	computed:{
		filteredSortBy(){
			
			return this.sortby.map(val=>{
				var l = '';
				var v = '';
				if( val.indexOf(':') > -1 ){
					l = val.split(':')[1];
					v = val.split(':')[0];
				}else{
					l = val;
					v = val;
				}
				
				return {
					'label': l.replace(/_/g,' '),
					'value': v
				};
			});
		
		},
		resultSet(){
			var start = (this.currentPage*this.itemsPerPage)-this.itemsPerPage;
			var end = (this.currentPage*this.itemsPerPage);
			var arr = this.array;
			if(this.selectedSortBy != ''){
				return arr.sort((a,b)=>{
					if( typeof a[this.selectedSortBy] != 'undefined' ){
						
						var first = (this._.isFinite(Number(a[this.selectedSortBy])) ? Number(a[this.selectedSortBy]) : a[this.selectedSortBy]);
						var second = (this._.isFinite(Number(b[this.selectedSortBy])) ? Number(b[this.selectedSortBy]) : b[this.selectedSortBy]);
					
						if( first > second ){
							return (this.sortDirection == "ASC" ? 1 : -1);
						}else if( first < second ){
							return (this.sortDirection == "ASC" ? -1 : 1);
						}else{
							return 0;
						}
					}else{
						return 0;
					}
				}).slice(start,end);
				
			}else{
				return arr.slice(start,end);
			}
			
		}
	},
	watch:{
		array:{
			handler(a,b){
				this.setVariables();
				if(a.length != b.length){ 
					this.currentPage = 1;
				}
			},
			deep: true
		},
		perPage(){
			this.itemsPerPage=this.perPage;
			this.setVariables();
		},
		itemsPerPage(){
			this.setVariables();
		},
		sortBy(){
			this.setVariables();
		}
	},
	mounted(){
		this.itemsPerPage = Number(this.perPage);
	},
	beforeUpdate(){
		this.setVariables();
	}
}
</script>
