<template>
<transition name="modal">
	<div class="modal-mask">
		<div class="modal-wrapper">

		<div class="modal-dialog" role="document">
				<div class="modal-content">
						<div class="modal-header">
								<h5 class="modal-title w-100"><slot name="title"></slot></h5>
						</div>
						<div class="modal-body">
								<slot></slot>
						</div>
						<div class="modal-footer">
								<slot name="buttons"></slot>
						</div>
				</div>
		</div>

		</div>
	</div>
</transition>
</template>

<script>
export default{
	props:['data'],
	methods:{
		hide(){
			this.$parent.showAlert = false;
		}
	}
}
</script>
