<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="close"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Edit Group</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<form-input v-model="groupClone.name" label="SDR Group Name" :error="validationErrors.name" :not-saved="notSaved.indexOf('group_name') > -1"/>
			<div class="form-group">
				<label>SMS Notifications <span v-if="notSaved.indexOf('sms_notifications') > -1" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span></label>
				<select class="form-control" v-model="groupClone.sms_notifications">
					<option v-for="(option, index) in [{label:'Enabled',value:1},{label:'Disabled',value:0}]" :value="option.value" :key="index">{{option.label}}</option>
				</select>
			</div>
		</div>
	</div>
	<div class="offcanvas-footer" v-if="showSave">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="!allowChanges">Save Changes</button>
	</div>

	<alert v-if="showAlert">
		<div slot="title">Save Error</div>
		{{message}}
		<div slot="buttons">
			<button class="btn btn-default btn-block" @click="showAlert=false">Ok</button>
		</div>
	</alert>

	<unsaved-changes :show="showUnsavedChanges && state=='open'" v-on:close="forceCloseOffcanvas" v-on:hide="showUnsavedChanges=false" v-on:save="$refs.auth.check(saveChanges)"/>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>
<script>
import FormInput from '../../FormElements/Input.vue';
import Alert from '../../Alert.vue';
import UnsavedChanges from '../../UnsavedChangesAlert.vue';

export default{
	props:['state','group'],
	data(){
		return {
			groupClone:{},
			showSave:false,
			validationErrors:{},
			notSaved:[],
			message: '',
			showAlert:false,
			showUnsavedChanges:false,
			allowChanges:false
		}
	},
	components:{
		FormInput,
		Alert,
		UnsavedChanges
	},
	watch:{
		groupClone:{
			handler(){
				//run validation
				this.validationErrors = {};
				if( this.groupClone.name.length > 45 ){
					this.validationErrors.name = 'Too long';
				}

				if( !this._.isEqual( this.group , this.groupClone) && this._.isEmpty(this.validationErrors) ){
					this.showSave = true;
				}else{
					this.showSave = false;
				}
			},
			deep:true
		},
		state(a){
			if( a == 'open' ){
				//create new copy of group
				this.groupClone = this._.cloneDeep(this.group);
			}
		}
	},
	methods:{
		close(){
			if( this._.isEqual( this.group , this.groupClone) ){
				this.$emit('close');
			}else{
				//warn
				this.showUnsavedChanges=true;
			}
		},
		forceCloseOffcanvas(){
			//reset clone to origin values and close
			this.groupClone = this._.cloneDeep(this.group);
			this.showUnsavedChanges=false;
			this.$emit('close');
		},
		saveChanges(){
			var f = new FormData();
			f.append('alarm_group',this.groupClone.id);
			f.append('group_name',this.groupClone.name);
			f.append('sms_notifications',this.groupClone.sms_notifications);

			var self = this;
			this.showUnsavedChanges=false;

			this.axios.post(this.controller('settings/save_sdr_group_settings'),f).then(function(response){
				if( response.data.status == "success" ){
					//re-clone
					self.groupClone = self._.cloneDeep(self.group);

					//emit reload list
					self.$emit('reload-alarm-groups');
					//emit close
					self.$emit('close');
				}else{
					//alert message
					self.message = response.data.message;
					self.showAlert = true;
					self.notSaved = response.data.not_saved;
				}
			});



		}
	}
}
</script>
