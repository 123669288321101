<template>
<div class="accordion has_caret" id="auditFilters">
	<div class="card" style="overflow:visible !important;">
		<div class="card-header" id="filtersHeading" data-toggle="collapse" data-target="#filters" aria-expanded="false" aria-controls="filters">
			<div class="d-flex justify-content-between align-items-center">
				<div>Filters</div>
				<font-awesome-icon icon="chevron-right"/>
			</div>
		</div>

		<div id="filters" class="collapse" aria-labelledby="filters" data-parent="#auditFilters">
			<div class="card-body">
				
				<div class="row">
					<div class="col-6">
						<form-input type="date" v-model="dateRange.from" label="From"/>
					</div>
					<div class="col-6">
						<form-input type="date" v-model="dateRange.to" label="To"/>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</div>
</template>

<script>
import FormInput from '../FormElements/Input.vue';

export default{
	components:{
		FormInput
	},
	data(){
		return {
			dateRange:{
				to:'',
				from:''
			}
		}
	},
	watch:{
		dateRange:{
			handler(){
				this.$emit('change',this.dateRange);
			},
			deep: true
		}
	},
	mounted(){
		this.dateRange.from = this.moment().format('YYYY-MM-DD');
		this.dateRange.to = this.moment().format('YYYY-MM-DD');
	}
}
</script>