<template>
<div class="container-fluid py-3 bg-white">

	<select-input v-model="selectedUser" :options="filteredUsers" label="Select a user"/>
	
	<password-strength-input v-model="password" v-on:strength="v=>password_strength=v"/>
	<form-input v-model="confirmPassword" type="password" label="Confirm Password" :disabled="password_strength < 4" :error="(confirmPassword.length > 0 && !_.isEqual(password,confirmPassword) ? 'Mismatch' : '')"/>
	
	<button class="btn btn-lg btn-block btn-primary" :disabled="!_.isEqual(password,confirmPassword) || password_strength < 4 || !selectedUser ||  !allowChanges" @click="$refs.auth.check(savePassword)">Save</button>
	
	<alert v-if="showResponse">
		<div slot="title">{{responseTitle}}</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
	</alert>
	
	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import SelectInput from '../../components/FormElements/Select.vue';
import FormInput from '../../components/FormElements/Input.vue';
import Alert from '../../components/Alert.vue';
import PasswordStrengthInput from '../../components/FormElements/PasswordStrength.vue';

export default{
	components:{
		SelectInput,
		FormInput,
		Alert,
		PasswordStrengthInput
	},
	data(){
		return {
			users:[],
			password_strength:0,
			selectedUser:null,
			password:'',
			confirmPassword:'',
			showResponse:false,
			responseMessage:'',
			responseTitle:'Error',
			allowChanges: false
		}
	},
	computed:{
		filteredUsers(){
			return this.users.map(user=>{
				return {label:user.username,value:user.id};
			});
		}
	},
	methods:{
		savePassword(){
			var f = new FormData();
			f.append('user_id',this.selectedUser);
			f.append('new_password',this.password);
			f.append('confirm_password',this.confirmPassword);
			var self = this;
			
			this.axios.post(this.$root.controllers_base_path+'auth/update_user_password?'+this.moment().unix(),f).then(res=>{
				if( res.data.status == "success" ){
					self.responseTitle = 'Saved';
					self.responseMessage = 'User password saved successfully'
					self.showResponse = true;
				}else{
					//alert message
					self.responseTitle = 'Error';
					self.responseMessage = res.data.message;
					self.showResponse = true;
				}
			});
		}
	},
	mounted(){
		//get users
		var self = this;
		this.axios.get(this.controller('settings/passwords')).then(res=>{
			self.users = res.data.data;
		});
		
		this.$emit('breadcrumb',[
			['Settings','/settings'],
			['Passwords','/settings/passwords']
		]);
	}
}
</script>

<style lang="scss" scoped>
.progress{
	margin-top:-20px;
	height: 7px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	.progress-bar{
	}
}
</style>