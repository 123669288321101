<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="$emit('close')"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">{{contact.user_name}}</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<item title="Job" :content="(!_.isEmpty(contact.job_description) ? contact.job_description : 'None')"/>
			<item title="Phone" :content="(!_.isEmpty(contact.alarm_phone) ? contact.alarm_phone : 'None')" link-type="tel"/>
			<item title="Email" :content="(!_.isEmpty(contact.alarm_email) ? contact.alarm_email : 'None')" link-type="mailto"/>
			<item title="Status" :content="(contact.active == 1 ? 'Active' : 'In-active')+', Alarms '+(contact.on_holiday == 1 ? 'Disabled' : 'Enabled')"/>
			<div class="row">
				<div class="col">
					<item title="Stales" :content="(!_.isEmpty(contact.stale_notes) ? contact.stale_notes : '-')"/>
				</div>
				<div class="col">
					<item title="Gateway" :content="(!_.isEmpty(contact.gateway_notes) ? contact.gateway_notes : '-')"/>
				</div>
				<div class="col">
					<item title="Receiver" :content="(!_.isEmpty(contact.receiver_notes) ? contact.receiver_notes : '-')"/>
				</div>
				<div class="col">
					<item title="Battery" :content="(!_.isEmpty(contact.battery_notes) ? contact.battery_notes : '-')"/>
				</div>
			</div>
			<item title="Location" :content="(!_.isEmpty(contact.default_location) ? contact.default_location : 'None')"/>
			<item title="Notes" :content="(!_.isEmpty(contact.default_notes) ? contact.default_notes : 'None')"/>
		</div>
	</div>
</div>
</template>

<script>
import Item from './Item.vue';
export default{
	components:{
		Item
	},
	props:['contact','state']
}
</script>
