<template>
	<div class="switch-button-control my-3" :class="{'d-flex':$slots['default'],'justify-content-between align-items-center':$slots['default']}">
		<div class="switch-button-label flex-grow-1" v-if="$slots['default']">
			<slot></slot>
		</div>
		<div class="d-flex align-item-center">
			<transition name="fade"><div v-if="showState" class="mr-2">{{ (isEnabled ? 'on' : 'off') }}</div></transition>
			<div class="switch-button d-inline-block" :class="{ enabled: isEnabled, 'mx-auto': (center && !$slots['default']) }" @click="toggle" :style="{'--color': color}">
				<div class="button"></div>
			</div>
		</div>
		<div class="error" v-if="error != '' && typeof error != 'undefined'">{{error}}</div>
	</div>
</template>

<script>
export default{
	props: {
		value:{
			required: true
		},
		error:{},
		returnType:{
			required: false,
			default: 'string'
		},
		color: {
			type: String,
			required: false,
			default: "#06ab03"
		},
		center:{
			type: Boolean,
			required: false,
			default: true
		}
	},
	data(){
		return {
			isEnabled: null,
			showState:false,
			allowShowState:false
		}
	},
	methods: {
		toggle: function() {
			this.isEnabled = !this.isEnabled;
			if(this.returnType == 'string'){
				this.$emit("input", (this.isEnabled == false ? '0' : '1') );
			}else{
				this.$emit("input", this.isEnabled );
			}
		},
		setState(){
			this.isEnabled = ( this.value == false || this.value == 0 || this.value == "0" || this.value == null || typeof this.value == "undefined" ? false : true );

			if(this.allowShowState){
				this.showState=true;
				setTimeout(()=>{
					this.showState=false;
				},800);
			}
		},
		refresh(){
			//alias - easier to remember
			this.setState();
		}
	},
	mounted(){
		this.setState();
		this.allowShowState=true;
	},
	watch:{
		value(){
			this.refresh();
		}
	}
}
</script>

<style lang="scss" scoped>
.switch-button-control {

	.switch-button {
		$switch-button-height: 1.6em;
		$switch-button-color: var(--color);
		$switch-button-color-off: #ccc;
		$switch-button-border-thickness: 2px;
		$switch-transition: all 0.3s ease-in-out;
		$switch-is-rounded: true;

		height: $switch-button-height;
		min-width:58px;
		width: calc(#{$switch-button-height} * 2);
		border: $switch-button-border-thickness solid $switch-button-color-off;
		box-shadow: inset 0px 0px $switch-button-border-thickness 0px
			rgba(0, 0, 0, 0.33);
		border-radius: if($switch-is-rounded, $switch-button-height, 0);

		transition: $switch-transition;

		$button-side-length: calc(
			#{$switch-button-height} - (2 * #{$switch-button-border-thickness})
		);

		cursor: pointer;

		.button {
			height: $button-side-length;
			width: $button-side-length;
			border: $switch-button-border-thickness solid $switch-button-color-off;
			border-radius: if($switch-is-rounded, $button-side-length, 0);
			background: $switch-button-color-off;

			transition: $switch-transition;
		}

		&.enabled {
			background-color: $switch-button-color;
			box-shadow: none;
			border: $switch-button-border-thickness solid $switch-button-color;

			.button {
				background: white;
				transform: translateX(
					calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
				);
				border: $switch-button-border-thickness solid $switch-button-color;
				background: #fff;
			}
		}
	}

	.switch-button-label {
		margin-right: 10px;
		small{
			display:inline-block;
			color:#777;
		}
	}

	.error{
		margin-top:5px;
		padding-top:5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
	}

	
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
}

</style>
