<template>
	<div id="app" ref="app" :style="{'paddingTop':(['direct_login','privacy_policy','first_time_login'].indexOf(this.$route.name) > -1 ? '0px' : '58px')}">
		<div v-if="['direct_login','privacy_policy','first_time_login'].indexOf(this.$route.name) == -1">
			<navbar></navbar>
			<breadcrumb/>
		</div>
		<router-view v-on:breadcrumb="apply_breadcrumb"/>
		<footer-block v-if="['direct_login'].indexOf(this.$route.name) == -1"></footer-block>
		<connection-status v-if="!online" />
		<cookie-request v-if="!cookies && ['direct_login'].indexOf(this.$route.name) == -1" @accepted="cookies=true"/>
		<welcome v-if="!welcome_msg && ['direct_login'].indexOf(this.$route.name) == -1" @setCookie="welcome_msg=true"/>
		<alert v-if="$store.state.deniedAlert">
			<div slot="title">Permission Denied</div>
			You do not have permission to access this page.
			<button class="btn btn-block btn-secondary" slot="buttons" @click="$store.commit('setDeniedAlert',false)">Ok</button>
		</alert>
	</div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import FooterBlock from './components/Footer.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import ConnectionStatus from './components/ConnectionStatus.vue';
import CookieRequest from './components/CookieRequest.vue';
import Alert from './components/Alert.vue';
import Welcome from './components/Welcome.vue';

export default {
	components: {
		Navbar,
		FooterBlock,
		Breadcrumb,
		ConnectionStatus,
		CookieRequest,
		Alert,
		Welcome
	},
	data(){
		return {
			online: true,
			cookies: true,
			welcome_msg:true
		}
	},
	methods:{
		apply_breadcrumb(breadcrumb){
			this.$root.$data.breadcrumb=breadcrumb;
		}
	},
	mounted(){
		//set connection status & cookies watcher
		setInterval(()=>{
			//check online status
			this.online=window.navigator.onLine;

			//check for cookie policy state
			if(this.$cookies.isKey('cb-enabled')){
				if(this.$cookies.get('cb-enabled')=='accepted'){
					this.cookies=true;
				}else{
					this.cookies=false;
				}
			}else{
				this.cookies=false;
			}

			//check for welcome state
			if(this.$cookies.isKey('welcome_msg')){
				if(this.$cookies.get('welcome_msg')=='accepted'){
					this.welcome_msg=true;
				}else{
					this.welcome_msg=false;
				}
			}else{
				this.welcome_msg=false;
			}
		},1000);
	}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./assets/_vars.scss";

.btn{
	&.btn-primary{
		background: $green;
		border:1px solid darken($green,5)
	}
	&.btn-default{
		background:#ddd;
	}
	&.btn-danger{
		background: #333;
		border:1px solid darken(#333,5)
	}
	&:disabled{
		color: #fff !important;
		background-color: #6c757d !important;
		border-color: #6c757d !important;
	}
}
.bg-default{
	background:#ddd;
}

html,
body{
	font-size:18px;
}

body,#app{
	min-height:100vh;
	background-color: #ddd;
}

a{
	color:$green;
}

.form-group{
	label{
		small{
			display:inline-block;
			color:#777;
		}
	}
}

.card-header:first-child,
.breadcrumb{
	border-radius:0;
}

.has_caret{
	.card-header{
		position:relative;
		.fa-chevron-right{
			transition: all linear 0.25s;
		}

		&.open{
			.fa-chevron-right {
				transform: rotate(90deg);
			}
		}
	}
}

.collapse{
	&:not(.show){
		//override bootstrap default behaviour
		display:block !important;
		overflow:hidden;
	}
	
	transition: border-width 0.2s linear;
	transition: max-height 0.5s linear;
	border-top-color:#ccc;
	border-top-style:solid;
	border-top-width:1px;
	border-bottom:1px solid #ccc;
	max-height:0px;
	height:auto;
	
	&.show{
		border-bottom:10px solid #ccc;
		max-height:1000px;
	}
}

.card-header{
	transition: background-color 0.2s ease;
	&.open{
		border-top-width:10px;
		background:$green;
		color:#fff;
	}
}

.list-group{
	a{
		color:#000;
	}
	.list-group-item{
		border-radius:0px;
	}
}

.btn{
	border-radius:0;
}
.btn-group{
	button{
		white-space:nowrap;
	}
}


.offcanvas{
	position:fixed;
	overflow:hidden;
	z-index:1032;
	top:0;
	height:100vh;
	width:0;
	left:0;
	background:#fff;
	-webkit-transition: width 150ms ease-in-out;
	-moz-transition: width 150ms ease-in-out;
	-o-transition: width 150ms ease-in-out;
	transition: width 150ms ease-in-out;
	box-shadow: 0px 0px 10px #000;
	.navbar{
		height:58px;
		border-bottom:1px solid #ccc;
	}
	.offcanvas-body{
		height:calc(100vh - 58px) !important;
		overflow-y:scroll;
		padding-bottom:100px;

		.list-group{
			.list-group-item:first-child{
				border-top:none;
			}
			.list-group-item{
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
			}
		}
	}
	.offcanvas-title{
		font-weight:bold;
		white-space:nowrap;
	}
	.offcanvas-footer{
		position:fixed;
		z-index:2;
		height:0;
		bottom:0;
		width:100%;
		overflow:hidden;
		button{
			height:100%;
			position:relative;
			z-index:5;
		}
	}

	&.open{
		width:100%;
		.offcanvas-footer{
			height:85px;
			box-shadow: 0px 0px 10px #000;
		}
	}
}

.offcanvas_bottom{
	position:fixed;
	z-index:1031;
	bottom:0;
	width:100%;
	height:auto;
	background:#222;
	color:#fff;
	-webkit-transition: height 150ms ease-in-out;
	-moz-transition: height 150ms ease-in-out;
	-o-transition: height 150ms ease-in-out;
	transition: height 150ms ease-in-out;
	box-shadow: 0px 0px 10px #000;
}

.form-group{
	input{
		position:relative;
		z-index:1;
	}
	.error{
		position:relative;
		z-index:0;
		padding:10px 10px 5px;
		width:100%;
		margin-top:-5px;
		background:#ddd;
		border:1px solid #ccc;
		font-size:0.8em;
		-webkit-border-bottom-right-radius: 5px;
		-webkit-border-bottom-left-radius: 5px;
		-moz-border-radius-bottomright: 5px;
		-moz-border-radius-bottomleft: 5px;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;

	}
}

.multiselect{
	&.multiselect--active{
		position:relative;
		z-index:2 !important;
	}
	.multiselect__option--highlight{
		background:none;
		color:#35495e;
	}
	.multiselect__option--selected{
		background:transparentize($green,0.8);
		color:#000;
	}
}

.block_links{
	a{
		text-decoration:none !important;
		&:active,
		&:hover,
		&:focus{
			background:#ddd !important;
		}
	}
}
.switch_units {
  position: relative;
  top: -2px;
  left: -2px;
  vertical-align: middle;
  width: 16px;
  opacity: 0.5;
  cursor: pointer;
  height: 15px !important;
}

.phone_validation_error{
    font-size:0.8em;
}
</style>
