<template>
<div>
	<div v-for="(topic,index) in list" :key="index">
		<h5 class="container-fluid my-3">{{topic.title}}</h5>
		<div class="list-group">
			<a target="HelpDocument" class="list-group-item" v-for="(link,index2) in topic.links" :href="link.url" :key="index2"><font-awesome-icon icon="file-pdf" fixed-width /> {{link.name}}</a>
		</div>
	</div>
</div>
</template>

<script>
export default{
	data(){
		return {
			list:[
				{
					title: 'User Manuals',
					links: [
						{name: 'Tscanweb User Manual', url: this.backend_base_url('help/Tscanweb_User_Manual.pdf')}
					]
				},
				{
					title: 'User Guides & Help Documents',
					links: [
						{name: '13/12/2018 - Power Probes Help', url: this.backend_base_url('help/power_probes_help.pdf')},
						{name: '26/04/2018 - Realtime Sensors Shortcuts Help', url: this.backend_base_url('help/Realtime%20Sensors%20Shortcuts%20Help.pdf')},
						{name: '09/09/2016 - Stale Data Help', url: this.backend_base_url('help/Stale%20Data%20Help.pdf')}
					]
				},
				{
					title: 'Terms & Conditions',
					links: [
						{name: '11/10/2015 - t-Scan Ltd Terms and Conditions', url: this.backend_base_url('help/terms_and_conditions_20151011.pdf')}
					]
				},
				{
					title: 'Notification History',
					links: [
						{name: '15/03/2017 - Quick-Reports Sensor Graph Update', url: this.backend_base_url('help/sensor_report_update_006_20170315.pdf')},
						{name: '15/02/2017 - Sensor Node Sorting Options', url: this.backend_base_url('help/realtime_sensors_update_005_20170215.pdf')},
						{name: '10/08/2016 - Remedial Actions Page Updates', url: this.backend_base_url('help/remedial_actions_update_001_20160810.pdf')},
						{name: '20/07/2016 - Realtime Sensors Update #2 (Alarm Status)', url: this.backend_base_url('help/realtime_sensors_update_002_20160721.pdf')},
						{name: '14/07/2016 - Realtime Sensors Update #1 (Battery Indicators)', url: this.backend_base_url('help/realtime_sensors_update.pdf')},
						{name: '13/05/2016 - Tscanweb Updates (3.0 Release)', url: this.backend_base_url('help/Tscanweb3%20Release%20Help.pdf')}
					]
				}
			]
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Help Center','/help-center']
		]);
	},
}
</script>
