<template>
  <div style="line-height:18px;" class="mb-2">
    <div class="d-flex justify-content-end align-content-center">
      <img v-if="entity.sensor_type_id == 22" @click.stop="co2Setting = !co2Setting" :src="'./assets/misc_icons/switch.png'" class="switch_units" />
      <div v-html="entityReading"></div>
    </div>
    <small style="color:#bbb;">
      <template v-if="!_isContactSensor(entity)">
        <font-awesome-icon icon="chevron-down" fixed-width />
        <span v-html="thresholdLow"></span>
        <font-awesome-icon icon="chevron-up" fixed-width />
        <span v-html="thresholdHigh"></span>
      </template>
      <template v-if="_isContactSensor(entity)">
        Alarm On: {{alarmOn}}
      </template>
    </small>
  </div>
</template>

<script>
export default {
  name: "SensorEntity",
  props: ['entity', 'contactLabels'],
  data() {
    return {
      co2Setting: {
        type: Boolean,
        default: false
      }
    }
  },
  mounted() {
    this.co2Setting = this.entity.original_co2_setting
  },
  methods: {
    _isContactSensor(entity){
      return this.isContactSensor(entity.sensor_type_id);
    },
    prepCo2Value(value) {
      if (!this.co2Setting) {
        if (this.entity.original_co2_setting) {
          value = this._.round(value / 10000, 2);
        }
      } else {
        if (!this.entity.original_co2_setting) {
          value = this._.round(value * 10000, 2);
        }
      }
      return value
    }
  },
  computed: {
    co2DisplayUnit() {
      return this.co2Setting ? 'PPM' : '%';
    },
    entityReading() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity._actual_reading) + this.co2DisplayUnit;
      }
      return this.entity._sensor_reading_output
    },
    thresholdHigh() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity.threshold_high);
      }
      return this.entity.threshold_high;
    },
    thresholdLow() {
      if (this.entity.sensor_type_id == 22) {
        return this.prepCo2Value(this.entity.threshold_low);
      }
      return this.entity.threshold_low;
    },
    alarmOn() {
      if(this._isContactSensor(this.entity)){
        var label_ = this.contactLabels.filter(label => {
          return Number(label.id) == Number(this.entity.contact_sensor_label_id);
        })[0];

        if(label_){
          if( Number(this.entity.threshold_high) == 1 && Number(this.entity.threshold_low) == 1 ){
            return label_.reading_0_label;
          }else if( Number(this.entity.threshold_high) == 0 && Number(this.entity.threshold_low) == 0 ){
            return label_.reading_1_label;
          }else{
            return 'None';
          }
        }else{
          if( Number(this.entity.threshold_high) == 1 && Number(this.entity.threshold_low) == 1 ){
            return 'Reading 0';
          }else if( Number(this.entity.threshold_high) == 0 && Number(this.entity.threshold_low) == 0 ){
            return 'Reading 1';
          }else{
            return 'None';
          }
        }

      }else{
        return false;
      }
    }
  },
}
</script>

<style scoped>
.switch_units {
  top: 3px;
}

</style>