<template>
	<div class="offcanvas_bottom" v-if="selected && selected.length > 0">
		<div class="container-fluid">
			<p class="text-left my-2" style="font-size:0.8em;">
				<a href="#" @click="unselect" class="float-right">Unselect All</a>
				{{selected.length}} Sensor{{ selected.length > 1 ? 's' : '' }} Selected
			</p>
			<button class="my-2 btn btn-block btn-sm btn-light" v-if="selected.length == 1 && userIs('generalUp')" v-on:click="openEditCanvas">Edit Sensor Settings</button>


			<div class="row py-2 bg-dark">
				<div class="col-12 text-center mb-2" style="font-size:0.8em;">Date Range</div>
				<div class="col-6">
					<input type="date" class="form-control form-control-sm" placeholder="from" :value="dateRange.from" @change="$emit('change-range',{from:$event.target.value})"/>
				</div>
				<div class="col-6">
					<input type="date" class="form-control form-control-sm" placeholder="to" :value="dateRange.to" @change="$emit('change-range',{to:$event.target.value})"/>
				</div>
			</div>
			<div class="row py-2 bg-dark">
				<div class="col">
					<button class="btn btn-block btn-sm btn-primary" @click="$emit('show-daily')">Daily Report</button>
				</div>
				<div class="col">
					<button class="btn btn-block btn-sm btn-primary" @click="$emit('show-graph')" :disabled="selected.length > 3 || !allowComparison || multipleContactSensorsSelected">Sensor Graph</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default{
	props:['selected','dateRange','allowComparison','multipleContactSensorsSelected'],
	data(){
		return {
			loading: false
		}
	},
	methods:{
		openEditCanvas(){
			this.$parent.offcanvasState = "open";
		},
		unselect(e){
			e.preventDefault();
			this.$emit('clearSelection');
		}
	}
}
</script>
