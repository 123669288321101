<template>
    <alert>
        <div slot="title">Welcome to your new T-Scan mobile site</div>
        <div>You can switch to the desktop site at any time by clicking the user icon to the top right of the screen, then Switch to T-Scan Desktop.</div>
        <button slot="buttons" class="btn btn-block mb-2 btn-default" @click="setCookie">Ok</button>
    </alert>
</template>

<script>
    import Alert from './Alert.vue';
    export default {
        components:{Alert},
        methods:{
            setCookie(){
                //set cookie
                this.$cookies.set('welcome_msg','accepted',-1);

                //emit acceptance
                this.$emit('accepted');
            },

            openHelp(){
                this.setCookie();
                this.$router.push('/help-center');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/_vars.scss';
    .link{
        color:$green;
    }
</style>