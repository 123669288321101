<template>
<div>
	<div class="offcanvas_bottom" v-if="!_.isEmpty(selected)">
		<div class="container-fluid py-3">
			<a :class="{disabled:_.isEmpty(selected.alarm_phone)}" :href="'tel:'+selected.alarm_phone" class="btn btn-success btn-block py-2 mb-2" style="line-height:1em;"><font-awesome-icon icon="phone" fixed-width/> Call {{selected.user_name}}<br/><small style="font-size:0.7em;">{{selected.alarm_phone}}</small></a>
			<button class="btn btn-default btn-sm btn-block" @click="contactDetailsState='open'">Show Details</button>
		</div>
	</div>

	<contact :state="contactDetailsState" :contact="selected" v-on:close="contactDetailsState=''"/>
</div>
</template>

<script>
import Contact from './Contact.vue';

export default{
	components:{
		Contact
	},
	props:['selected'],
	data(){
		return {
			contactDetailsState: ''
		}
	}
}
</script>
