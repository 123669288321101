<template>
	<div>
		<div class="fade" :class="state" v-on:click="toggleMenu('close')"></div>
		<div class="menu left" :class="state">
			<router-link to="/dashboard" @click.native="toggleMenu('close')"><font-awesome-icon icon="tachometer-alt" fixed-width /> Dashboard</router-link>
			<router-link to="/alarm-events" @click.native="toggleMenu('close')"><font-awesome-icon icon="bell" fixed-width /> Alarm Events</router-link>
			<router-link to="/realtime-sensors" @click.native="toggleMenu('close')"><font-awesome-icon icon="thermometer-half" fixed-width /> Realtime Sensors</router-link>
			<router-link to="/settings" @click.native="toggleMenu('close')" v-if="userIs('adminUp')"><font-awesome-icon icon="cogs" fixed-width /> Settings</router-link>
		</div>
	</div>
</template>

<script>
export default{
	props:['state']
}
</script>

<style lang="scss">
@import "../assets/_vars.scss";
.menu{
 &.left{
	.router-link-exact-active{
		background:$green;
		color:#fff;
	}
 }
}
</style>
