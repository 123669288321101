<template>
<div class="mb-3">
	<small class="d-block">{{title}}</small>
	<a :href="linkType+':'+content" v-if="linkType">{{content}}</a>
	<span v-if="!linkType">{{content}}</span>
</div>
</template>

<script>
export default{
	props:['title','content','linkType']
}
</script>
