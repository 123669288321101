<template>
	<div class="dashboard container-fluid text-center block_links">
		<div class="row">
			<router-link to="/realtime-sensors" class="col py-5">
				<h6>Realtime Sensors</h6>
				<h4 class="my-0"><font-awesome-icon icon="thermometer-half" /></h4>
			</router-link>
			<router-link v-if="userIs('adminUp')" to="/settings" class="col py-5">
				<h6>Settings</h6>
				<h4 class="my-0"><font-awesome-icon icon="cogs" /></h4>
			</router-link>
		</div>
		<div class="row">
			<router-link to="/support" class="col py-5">
				<h6>Contact Support</h6>
				<h4 class="my-0"><font-awesome-icon icon="comments" /></h4>
			</router-link>
			<router-link to="/audit-trail" class="col py-5">
				<h6>Audit Trail</h6>
				<h4 class="my-0"><font-awesome-icon icon="search" /></h4>
			</router-link>
		</div>
		<div class="row">
			<router-link to="/alarm-events" class="col py-5">
				<h6>Alarm Events</h6>
				<h4 class="my-0"><font-awesome-icon icon="bell" /> <span class="ml-2 badge badge-pill badge-dark" v-if="remedial_actions > 0">{{remedial_actions}}</span></h4>
			</router-link>
		</div>
	</div>
</template>

<script>
export default{
	data(){
		return {
			remedial_actions: 0
		}
	},
	created(){
		var self = this;
		this.axios.get( this.controller("dashboard") ).then(function(response){
            if(response.data.status != 'failed'){
                self.remedial_actions = response.data.data.remedial_actions;
            }
		});
	},
	mounted(){
		this.$emit('breadcrumb',[]);
	}
}
</script>

<style lang="scss" scoped>
.row{
	.col{
		background:#fff;
		border:1px solid #ddd;
	}
	a{
		color:#000;
	}
}
</style>
