<template>
<div class="input-group">
	<input type="text" class="form-control" ref="searchInput" placeholder="Search" :value="value" @input="search" :style="{borderRightWidth:(value==''?1:0)+'px'}">
	<transition name="fade">
		<div v-if="value != ''" class="input-group-append" @click="clear">
			<span class="input-group-text"><font-awesome-icon icon="times-circle" fixed-width /></span>
		</div>
	</transition>
</div>
</template>

<script>
export default{
	props:['value'],

	methods:{
		search(){
			this.$emit('input', this.$refs.searchInput.value );
		},
		clear(){
			this.$emit('input','');
		}
	}
}
</script>

<style lang="scss" scoped>
.input-group{
	background:#fff;
	border-radius: .25rem;

	.input-group-append{
		cursor:pointer;

		background-color:rgba(255,255,255,0);

		.input-group-text{
			background-color:rgba(255,255,255,0);
			border-left:0;
		}
	}
}
</style>
