<template>
	<div>
		<div class="fade" :class="state" v-on:click="toggleMenu('close')"></div>
		<div class="menu right" :class="state">
			<router-link to="/user/settings" @click.native="toggleMenu('close')"><font-awesome-icon icon="user" fixed-width /> {{this.$store.state.user.name}}</router-link>
			<router-link to="/super-admin/company-changer" @click.native="toggleMenu('close')" v-if="userAllowedAccess('/super-admin')"><font-awesome-icon icon="building" fixed-width /> Company<br/><small>{{this.$store.state.user.company.name}} ({{this.$store.state.user.company.id}})<br/>{{this.$store.state.user.department.name}}</small></router-link>
			<router-link to="/department-changer" @click.native="toggleMenu('close')" v-if="!userAllowedAccess('/super-admin') && this.$store.state.user.departments > 1"><font-awesome-icon icon="building" fixed-width /> Company<br/><small>{{this.$store.state.user.company.name}} ({{this.$store.state.user.company.id}})<br/>{{this.$store.state.user.department.name}}</small></router-link>
			<router-link to="/super-admin" @click.native="toggleMenu('close')" v-if="userAllowedAccess('/super-admin')"><font-awesome-icon icon="globe" fixed-width /> Super Admin</router-link>
			<a href="#" @click="useDesktopSite()"><font-awesome-icon icon="desktop" fixed-width /> Switch to T-Scan Desktop</a>
			<a href="#" v-on:click="logout"><font-awesome-icon icon="sign-out-alt" fixed-width /> Sign-out</a>
		</div>
	</div>
</template>

<script>
export default{
	props:['state'],
	methods:{
		logout(){
            var self = this;
            this.axios.get(this.$root.controllers_base_path+'auth/logout').then(function(response){
                //no validation errors
                if(response.data.status != "failed"){
                    //redirect to dashboard
                    self.$cookies.remove("user");
                    self.$store.dispatch('destroyToken');
                    self.$router.push('/login');
                    //var redirect_base = self.$root.controllers_base_path.replace('/mobile/','')
                    //window.location = redirect_base+'auth/login/mobile';
                }
            });
		}
	}
}
</script>

<style lang="scss">
@import "../assets/_vars.scss";
.menu{
	&.right{
		.router-link-exact-active{
			background:$green;
			color:#fff;
		}
		[disabled]{
			color:#ccc;
		}
	}
}
</style>
