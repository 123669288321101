<template>
	<div>
		<alarms v-on:select-group="group=>selected=group" v-on:change-view="view=>currentView=view" :key="renderAlarmGroups"/>
		<users :state="currentView=='users'?'open':'closed'" :group="selected.id" :name="selected.name" v-on:close="currentView=''" v-on:reload-alarm-groups="renderAlarmGroups++"/>
		<sensors :state="currentView=='sensors'?'open':'closed'" :group="selected.id" :name="selected.name" v-on:close="currentView=''" v-on:reload-alarm-groups="renderAlarmGroups++"/>
		<edit :state="currentView=='edit'?'open':'closed'" :group="selected" v-on:close="currentView=''" v-on:reload-alarm-groups="renderAlarmGroups++"/>
		<create :state="currentView=='create'?'open':'closed'" v-on:close="currentView=''" v-on:reload-alarm-groups="renderAlarmGroups++"/>
	</div>
</template>


<script>
import Alarms from '../../components/Settings/SdrGroups/Alarms.vue';
import Users from '../../components/Settings/SdrGroups/Users.vue';
import Sensors from '../../components/Settings/SdrGroups/Sensors.vue';
import Edit from '../../components/Settings/SdrGroups/Edit.vue';
import Create from '../../components/Settings/SdrGroups/Create.vue';

export default{
	data(){
		return {
			selected:'',
			currentView: null,
			//this is bound to :key on the alarms tag above.
			//its only here as changing this value will rerender the data,
			//this means when a refresh is required we can increment this value
			renderAlarmGroups: 0
		}
	},
	components:{
		Alarms,
		Users,
		Sensors,
		Edit,
		Create
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Settings','/settings'],
			['SDR Groups','/settings/sdr-groups']
		]);
	}
}
</script>
