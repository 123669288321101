<template>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeGraph"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">{{ (sensors.length == 1 ? `Sensor ${sensors[0].id}` : `${sensors.length} Sensors Selected`) }}</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<!--<v-touch @pinchout="graphPinchout" @pinchin="graphPinchin" @pinchstart="graphGetPinchStartPoints();startPinch()" @pinchend="finishPinch" @panleft="graphPanleft" @panright="graphPanright" @panstart="graphGetPinchStartPoints">-->
				<GChart :class="{'d-none':_.isEmpty(chartData)}" style="position:relative;"
					:settings="{ packages: ['corechart'] }"
					type="LineChart"
					:data="chartData"
					:options="chartOptions"
					@ready="onChartReady"
					ref="graphContainer"
					:events="chartEvents"
				/>
			<!--</v-touch>-->

			<div v-if="showNoResults" class="no_results d-flex justify-content-center align-items-center">
				<h3 class="text-center">No data for selected range<br/><small>{{displayDate}}</small></h3>
			</div>
		
			<div v-if="_.isEmpty(rawData)" class="h-100 d-flex align-items-center justify-content-center">
				<loading :state="true" size="1.5"/>
			</div>
		</div>
		<div class="offcanvas-footer d-flex justify-content-between align-items-center" v-if="state=='open'">
			<div class="pl-3" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{displayDate}}</div>
			<!--<div v-if="fetchingData" style="min-width:35px;"><font-awesome-icon icon="spinner" spin/></div>
			<button class="btn btn-light" @click="recentre()" style="min-width:106px;">Re-center</button>-->
		</div>
	</div>
</template>

<script>
import Loading from '../Loading.vue';
import { GChart } from 'vue-google-charts';
const $ = require('jquery');

export default{
	components:{
		Loading,
		GChart
	},
	props:['state','sensors','dateRange'],
	data(){
		return {
			showNoResults: false,
			rawData: [],
			graphData: [],
			graphLabels: [],
			GraphRequest: null,
			//below vars added to handle loading more data when graph scrolled out of range
			fetchingData: false,
			dateRangeCopy:{},
			GoogleGraphObject:null,
			ChartObject:null,
			chartEvents:{
				'ready': () => {
					// handle event here
					this.placeMarkers();
				}
			},
			/*pinchStartHMin:null,
			pinchStartHMax:null,
			inPinch:false,*/
			graphHeight:0,
			graphWidth:0,
			chartData: [],
			chartOptions:{
				width: 0,
				height: 0,
				chartArea: {width:0,left:50,top:20,bottom:40,height:0},
				animation:{
					duration: 100,
					easing: 'inAndOut',
					startup: true
				},
				intervals: { 'style':'area' },
				legend: { position: 'top', alignment: 'end' },
				explorer: {
					keepInBounds: true,
					maxZoomIn: .01,
					maxZoomOut: 1
				},
				vAxis: {
					viewWindowMode:'explicit',
					viewWindow: {
						max:250,
						min:-200
					},
					ticks: null
				},
				hAxis:{
					viewWindow: {
						max:null,
						min:null
					},
					gridlines: {
						count: -1,
						units: {
							days: {format: ['d/M/yy']},       // <-----
							hours: {format: ['HH:mm', 'ha']}, // <-----
						}
					},
					minorGridlines: {
						count: -1,
						units: {
							hours: {format: ['HH:mm', 'ha']},       // <-----
							minutes: {format: ['HH:mm a', ':mm']} // <-----
						}
					}
				}
			},
			contact_labels:[]
		}
	},
	mounted(){
		this.setGraphSize();
		var _this = this;
		window.addEventListener("resize", ()=>{
			setTimeout(function(){
				_this.setGraphSize();
			},200);
		});
	},
	computed:{
		displayDate(){
			//is the date range greater than one day?
			if( this.moment(this.chartOptions.hAxis.viewWindow.min).isSame( this.moment(this.chartOptions.hAxis.viewWindow.max), 'day' ) ){
				if( this.chartOptions.hAxis.viewWindow.min ){
					return this.moment(this.chartOptions.hAxis.viewWindow.min).format("DD/MM/YYYY");
				}
			}else{
				if( this.chartOptions.hAxis.viewWindow.min && this.chartOptions.hAxis.viewWindow.max ){
					return this.moment(this.chartOptions.hAxis.viewWindow.min).format("DD/MM/YYYY")+' - '+this.moment(this.chartOptions.hAxis.viewWindow.max).format("DD/MM/YYYY");
				}
			}
            return ''
		}
	},
	methods:{
		//calculate the size of the graph based on the window size
		setGraphSize(){
			this.graphHeight = (window.innerHeight-120);
			this.graphWidth = (window.innerWidth-20);

			this.chartOptions.chartArea.width=this.graphWidth;
			this.chartOptions.width=this.graphWidth;
			this.chartOptions.chartArea.height=this.graphHeight;
			this.chartOptions.height=this.graphHeight;
		},
		onChartReady (chart, google) {
			//store this for use later
			this.GoogleGraphObject = google;
			this.ChartObject = chart;
			//window
		},
		
		/*
		 * Graph Gestures
		 * NOT YET INCLUDED - WORK IN PROGRESS
		 */
		/*startPinch(){
			this.inPinch=true;
		},
		finishPinch(){
			var _this=this;
			setTimeout(function(){_this.inPinch=false},200);
		},
		graphGetPinchStartPoints(e){

			//vAxis
			//this.pinchStartVMin=this.chartOptions.vAxis.viewWindow.min;
			//this.pinchStartVMax=this.chartOptions.vAxis.viewWindow.max;

			//hAxis
			var chartLayout = this.ChartObject.getChartLayoutInterface();
			var chartBounds = chartLayout.getChartAreaBoundingBox();
			var minVisible = chartLayout.getHAxisValue(chartBounds.left);
			var maxVisible = chartLayout.getHAxisValue(chartBounds.left + chartBounds.width);

			this.pinchStartHMin=minVisible.getTime();
			this.pinchStartHMax=maxVisible.getTime();
			
			
		},
		graphPinchout(e){
			if( (e.angle != 0 && e.angle >= -45 && e.angle <= 45) || (e.angle != 0 && (e.angle <= -125 && e.angle >= -179) || (e.angle >= 125 && e.angle <= 180)) ){

				//var scale = e.scale;//-1;
				//this.chartOptions.vAxis.viewWindow.min=this.pinchStartVMin-(this.pinchStartVMin*scale);
				//this.chartOptions.vAxis.viewWindow.max=this.pinchStartVMax-(this.pinchStartVMax*scale);
				
				//calculate scale of hAxis
				var min = this.moment.unix(this.pinchStartHMin/1000);
				var max = this.moment.unix(this.pinchStartHMax/1000);

				if( max.diff(min,'days') > 0 ){
					var measure = 'day';
				}else if( max.diff(min,'hour') > 0 ){
					var measure = 'hour';
				}else{
					var measure = 'min';
				}

				window.console.log('zoom in',measure)

				this.chartOptions.hAxis.viewWindow.min=this.moment.unix(this.pinchStartHMin/1000).add( 2 ,measure).toDate();
				this.chartOptions.hAxis.viewWindow.max=this.moment.unix(this.pinchStartHMax/1000).subtract( 2 ,measure).toDate();

				//window.console.log( this.moment.unix(this.pinchStartHMin/1000).toDate(),
				//this.moment.unix(this.pinchStartHMin/1000).add( 1 ,measure).toDate() )

			}
		},
		graphPinchin(e){
			if( (e.angle != 0 && e.angle >= -45 && e.angle <= 45) || (e.angle != 0 && (e.angle <= -125 && e.angle >= -179) || (e.angle >= 125 && e.angle <= 180)) ){

				//var scale = e.scale;//-1;
				//this.chartOptions.vAxis.viewWindow.min=this.pinchStartVMin-(this.pinchStartVMin*scale);
				//this.chartOptions.vAxis.viewWindow.max=this.pinchStartVMax-(this.pinchStartVMax*scale);
				
				//calculate scale of hAxis
				var min = this.moment.unix(this.pinchStartHMin/1000);
				var max = this.moment.unix(this.pinchStartHMax/1000);

				if( max.diff(min,'days') > 0 ){
					var measure = 'day';
				}else if( max.diff(min,'hour') > 0 ){
					var measure = 'hour';
				}else{
					var measure = 'min';
				}

				window.console.log('zoom out',measure)

				if(this.moment.unix(this.pinchStartHMin/1000).subtract( 2 ,measure).unix() >= this.moment(this.dateRangeCopy.from,"YYYY-MM-DD").unix()){
					this.chartOptions.hAxis.viewWindow.min=this.moment.unix(this.pinchStartHMin/1000).subtract( 2 ,measure).toDate();
				}else{
					this.chartOptions.hAxis.viewWindow.min=this.moment(this.dateRangeCopy.from,"YYYY-MM-DD").toDate();
				}

				if(this.moment.unix(this.pinchStartHMax/1000).add( 2 ,measure).unix() <= this.moment(this.dateRangeCopy.to,"YYYY-MM-DD").unix()){
					this.chartOptions.hAxis.viewWindow.max=this.moment.unix(this.pinchStartHMax/1000).add( 2 ,measure).toDate();
				}else{
					this.chartOptions.hAxis.viewWindow.max=this.moment(this.dateRangeCopy.to,"YYYY-MM-DD").toDate();
				}

				//window.console.log( this.moment.unix(this.pinchStartHMin/1000).toDate(),
				//this.moment.unix(this.pinchStartHMin/1000).add( 1 ,measure).toDate() )

			}
		},
		graphPanleft(e){
			
			if(this.inPinch){
				return;
			}

			var scale = Math.floor(e.distance/15);
			if(scale < 1){scale=1;}

			//calculate scale of hAxis
			var min = this.moment.unix(this.pinchStartHMin/1000);
			var max = this.moment.unix(this.pinchStartHMax/1000);

			if( max.diff(min,'days') > 0 ){
				var measure = 'day';
				if(scale > 5){scale=5;}
			}else if( max.diff(min,'hour') > 0 ){
				var measure = 'hour';
				if(scale > 3){scale=3;}
			}else{
				var measure = 'min';
			}

			window.console.log('pan left',scale)

			//swipe is within range
			if( this.moment.unix(this.pinchStartHMax/1000).add( scale ,measure).unix() <= this.moment(this.dateRangeCopy.to,"YYYY-MM-DD").unix() ){
				this.chartOptions.hAxis.viewWindow.min=this.moment.unix(this.pinchStartHMin/1000).add( scale ,measure).toDate();
				this.chartOptions.hAxis.viewWindow.max=this.moment.unix(this.pinchStartHMax/1000).add( scale ,measure).toDate();
			}
		},
		graphPanright(e){
			if(this.inPinch){
				return;
			}

			var scale = Math.floor(e.distance/15);
			if(scale < 1){scale=1;}
			//calculate scale of hAxis
			var min = this.moment.unix(this.pinchStartHMin/1000);
			var max = this.moment.unix(this.pinchStartHMax/1000);

			if( max.diff(min,'days') > 0 ){
				var measure = 'day';
				if(scale > 5){scale=5;}
			}else if( max.diff(min,'hour') > 0 ){
				var measure = 'hour';
				if(scale > 3){scale=3;}
			}else{
				var measure = 'min';
			}

			window.console.log('pan right',scale)

			if( this.moment.unix(this.pinchStartHMin/1000).subtract( scale ,measure).unix() >= this.moment(this.dateRangeCopy.from,"YYYY-MM-DD").unix() ){
				this.chartOptions.hAxis.viewWindow.min=this.moment.unix(this.pinchStartHMin/1000).subtract( scale ,measure).toDate();
				this.chartOptions.hAxis.viewWindow.max=this.moment.unix(this.pinchStartHMax/1000).subtract( scale ,measure).toDate();
			}
		},*/

		closeGraph(){
			//cancel existing graph requests if promise exists
			if(this.GraphRequest){
				this.GraphRequest.cancel();
			}

			this.rawData = [];
			this.$emit('close')
		},
		
		/*
		 * Add the alarm annotations to the graph
		 */
		fetchGraphData(){

			//clear current chart data to remove existing graph
			this.chartData = [];
			this.showNoResults = false;

			var self = this;

			var f = new FormData();
			f.append('sensor_ids',
				//map the selected sensor array to get just the ids
				this.sensors.map(s=>{
					return s.id
				})
			);
			f.append('ts_from', this.moment( this.dateRangeCopy.from, 'YYYY-MM-DD' ).unix() );
			f.append('ts_to', this.moment( this.dateRangeCopy.to, 'YYYY-MM-DD' ).add(24,'hours').unix() );

			//todo: check what these properties do
			f.append('admin',1);
			f.append('type',1);
			f.append('plot',1);

			//cancel existing graph requests if promise exists
			if(this.GraphRequest){
				this.GraphRequest.cancel();
			}

			//Create new cancellable request
			this.GraphRequest = this.axios.CancelToken.source();

			this.axios.post(this.controller('realtime_sensors/graph'),f,{
				cancelToken: this.GraphRequest.token
			}).then(function(response){
				self.rawData = response.data.data;
				self.contact_labels = response.data.contact_labels;
				self.fillData();

				//reset cancelToken to null
				self.GraphRequest = null;

				//hide the loading spinner
				self.fetchingData=false;
			});
		},
		fillData(){
			/*
			Do we have some data to work with
			*/
			if( this.rawData.length > 0 ){

				//used to display the correct range on graph
				var lowest_value = null;
				var highest_value = null;

				//alarms locations array
				this.alarms = [];

				this.chartData = new this.GoogleGraphObject.visualization.DataTable();
				this.chartData.addColumn('date', 'Date');

				//is this contact sensor(s)?
				if(this.isContactSensor(this.rawData[0].sensor.sensor_type_id)){
					//get the labels for the sensors label_id
					var sensor = this.sensors.filter(sensor => {
						return Number(this.rawData[0].sensor.id) == Number(sensor.id);
					})[0];
					var labels = this.contact_labels.filter(label => {
						return Number(label.id)==Number(sensor.contact_sensor_label_id);
					})[0];
					if(!labels){
						labels = {
							reading_0_label: '0',
							reading_1_label: '1',
						};
					}

					this.chartOptions.vAxis.ticks=[
						{v: 0, f: labels.reading_0_label},
						{v: 1, f: labels.reading_1_label}
					];
				}else{
					this.chartOptions.vAxis.ticks=null;
				}

				//split into 2 passes
				//first for columns
				//second for data

				//PASS 1
					//loop the sensors
					//there may be more than 1
					this.rawData.forEach(sensor => {
						this.chartData.addColumn('number', 'Sensor '+sensor.sensor.id);

						//has the data been downsampled?
						if( sensor.data_margin && !this._.isEmpty(sensor.data_margin) ){
							//downsampled data
							//show average in bg
							//this.chartData.addColumn({type:'string', role:'annotation'});
							this.chartData.addColumn({id:'i0', type:'number', role:'interval'});
							this.chartData.addColumn({id:'i1', type:'number', role:'interval'});
							
						}else{
							//clean data
							//this.chartData.addColumn({type:'string', role:'annotation'});
						}
						
					});

					
				
				//PASS 2
					//loop the sensors
					//there may be more than 1
					var column=0; 
					var rows = [];

					this.rawData.forEach(sensor => {						
						//has the data been downsampled?
						if( sensor.data_margin && !this._.isEmpty(sensor.data_margin) ){
							//downsampled data
							//show average in bg
							sensor.data_margin.forEach(reading => {
								
								var this_row = [];

								//create empty cells where another sensor readings belong
								this_row[0]=new Date(reading[0]*1000);

								for(var x=1;x<=(column*3);x++){
									this_row[x]=null;
								}

								//put sensor readings in this sensors cells
								this_row[ (column*3+1) ]= +Number(reading[1][1]).toFixed(2); //the + used here ensures that a Number is returned otherwise js returns a string after toFixed
								
								
								
								//alarm annotation
								var show_alarm = sensor.alarm_events.find(event => {
									//returns true if this timestamp is found in the alarm events array
									return event[0] == reading[0];
								});

								//a location where the alarm icon should be shown
								if(show_alarm){
									this.alarms.push( [this_row[0],reading[1][1]] );
								}

								//lows and highs
								this_row[ (column*3+2) ]= +Number(reading[1][0]).toFixed(2);
								this_row[ (column*3+3) ]= +Number(reading[1][2]).toFixed(2);
								
								//create empty cells after our sensor readings if necessary
								if( (this_row.length-1) < (this.rawData.length*3) ){
									//we need some empty placeholders
									for( var x2=this_row.length;x2<=(this.rawData.length*3);x2++ ){
										this_row[x2]=null;
									}
								}

								//window.console.log(this_row)

								//create the row
								rows.push(this_row);

								//update the lowest / highest values
								if( lowest_value > Number(reading[1][1]) || !lowest_value ){
									lowest_value = reading[1][1];
								}
								if( lowest_value > Number(reading[1][0]) ){
									lowest_value = reading[1][0];
								}
								if( highest_value < Number(reading[1][1]) || !highest_value ){
									highest_value = reading[1][1];
								}
								if( highest_value < Number(reading[1][2]) ){
									highest_value = reading[1][2];
								}

							});

						}else{
							//clean data
							sensor.data_clean.forEach(reading => {

								var this_row = [];

								//create empty cells where another sensor readings belong
								this_row[0]=new Date(reading[0]*1000);
								for(var x=1;x<=column;x++){
									this_row[x]=null;
								}

								//put sensor readings in this sensors cells
								this_row[ (column+1) ]= +Number(reading[1]).toFixed(2); //the + used here ensures that a Number is returned otherwise js returns a string after toFixed
								
								//alarm annotation
								var show_alarm = sensor.alarm_events.find(event => {
									//returns true if this timestamp is found in the alarm events array
									return event[0] == reading[0];
								});
								
								//this_row[this_row.length]=(show_alarm ? 'A' : null);
								//a location where the alarm icon should be shown
								if(show_alarm){
									this.alarms.push( [this_row[0],reading[1]] );
								}
								
								//create empty cells after our sensor readings if necessary
								if( (this_row.length-1) < this.rawData.length ){
									//we need some empty placeholders
									for( var x2=this_row.length;x2<=this.rawData.length;x2++ ){
										this_row[x2]=null;
									}
								}

								rows.push(this_row);

								if( lowest_value > Number(reading[1]) || !lowest_value ){
									lowest_value = reading[1];
								}
								if( lowest_value > Number(reading[2]) || !lowest_value ){
									lowest_value = reading[2];
								}
								if( highest_value < Number(reading[1]) || !highest_value ){
									highest_value = reading[1];
								}
								if( highest_value < Number(reading[3]) || !highest_value ){
									highest_value = reading[3];
								}
							});
						}
						column++;

					});
					
					//add the rows to the chart object
					this.chartData.addRows(rows);

					//show "no results" message
					if( rows.length == 0 ){
						this.showNoResults = true;
					}

					//set the chart view max and min
					var pad = (highest_value-lowest_value)*0.1;
					this.chartOptions.vAxis.viewWindow.min = (lowest_value-pad).toFixed(2);
					this.chartOptions.vAxis.viewWindow.max = (highest_value+pad).toFixed(2);

					//set the date time format for the tooltip which appears when clicking on a point
					var formatter = new this.GoogleGraphObject.visualization.DateFormat({ 
						pattern: 'dd/MM/YY HH:mm:ss'
					}); 
					formatter.format(this.chartData , 0);

			}else{

				//show an error message
			}
		},
		placeMarkers(){
			//$('.alarm',this.$refs.graphContainer.$el).remove();
			var _this=this;
			
			var cli = _this.ChartObject.getChartLayoutInterface();
			//var chartArea = cli.getChartAreaBoundingBox();

			_this.alarms.forEach(alarm => {
				var y = Math.floor(cli.getYLocation(alarm[1])) - 30 + "px";
				var x = Math.floor(cli.getXLocation(alarm[0])) - 10 + "px";

				//add the alarm div
				if( $('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).length > 0 ){
					$('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).stop().animate({'left':x,'top':y},200);
				}else{
					$(_this.$refs.graphContainer.$el).append('<div class="alarm" data-ref="'+alarm[0]+'-'+alarm[1]+'" style="position:absolute;width:20px;height:20px;text-align:center;left:'+x+';top:'+y+';"><img src="assets/misc_icons/annotations_1_0_0_0.png" style="max-width:20px;max-height:20px;"/></div>');
				}
			});
		}
	},
	watch:{
		state(a){
			if( a == "open"  ){
				this.dateRangeCopy=this._.cloneDeep(this.dateRange);

				//reset graph values
				/*
				this.pinchStartHMin=null;
				this.pinchStartHMax=null;
				*/
				this.chartOptions.hAxis.viewWindow.min=this.moment(this.dateRangeCopy.from).toDate();
				this.chartOptions.hAxis.viewWindow.max=this.moment(this.dateRangeCopy.to).add(24,'hours').toDate();
				this.chartOptions.vAxis.viewWindow.min=-250;
				this.chartOptions.vAxis.viewWindow.max=200;
				
				//remove alarms
				$('.alarm',this.$refs.graphContainer.$el).remove();

				this.fetchGraphData();
			}

			this.showNoResults=false;
		}
	}
}
</script>

<style lang="scss" scoped>
.offcanvas-body{
	//height:calc(100vh - 105px) !important;
	position:relative;
	overflow-y:hidden;
	overflow-x:scroll;
	.no_results{
		position:absolute;
		z-index:2;
		background-color:rgba(255,255,255,0.9);
		width:100%;
		height:calc(100% - 45px);
		top:0;
	}
}
.offcanvas-footer{
	background-color:#eee;
	height:45px !important;
	.btn{
		height:45px;
	}
}
</style>
