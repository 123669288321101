<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <b>{{sensor.id}}</b> <span v-if="_isCompositeSensor"><font-awesome-icon icon="cog" class="composite_settings_cog" @click="$emit('select-composite',sensor)"/></span>
        <div style="font-size:0.8em;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;">
          {{sensor.name}}
        </div>
      </div>
      <div class="text-right">
        <small><span v-if="sensor.reading_timestamp_unix" :class="{'text-danger':(sensor.entities[0]._sensor_stale == 1 || (sensor.entities[1] && sensor.entities[1]._sensor_stale == 1))}"><font-awesome-icon icon="clock" fixed-width /> {{sensor._reading_datetime}}<br/></span></small>
        <div>
          <img :src="'./assets/signal_battery/'+sensor._battery_image"/>
          <img v-if="sensor._signal_strength != ''" :src="'./assets/signal_battery/'+sensor._signal_strength" class="ml-1"/></div>
      </div>
    </div>
    <div class="sensor_entities">
      <single-sensor-entity
        v-for="(entity,i) in sensor.entities"
        @toggle="$emit('toggle', entity)"
        :key="i"
        :num-entity="i"
        :entity="entity"
        :show-clones="showClones"
        :selected-sensor-ids="selectedSensorIds"
        :sensor="sensor"
        :contact-labels="contact_labels"
      />
    </div>

    <div class="text-center mt-0 mb-1" style="font-style:italic;font-size:0.7em;color:#aaa;" v-if="sensor.entities.length > 1">Click metric/reading for graphing</div>

    <div v-for="(entity,i) in sensor.entities" :key="i" class="text-center">
      <div v-if="_.isFinite(Number(entity.latest_reading_adjustment)) && Number(entity.latest_reading_adjustment) != 0" class="reading_adjustment">Adjusted from {{Number(entity.reading).toFixed(2)}}<span v-html="entity.alarming_display_unit"></span> to {{ (Number(entity.reading)+Number(entity.latest_reading_adjustment)).toFixed(2) }}<span v-html="entity.alarming_display_unit"></span> ({{(Number(entity.latest_reading_adjustment) > 0 ? '+' : '')+adjustmentDecimalise(entity.latest_reading_adjustment)}}<span v-html="entity.alarming_display_unit"></span>)</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/_vars.scss";

$single_stack_max_width: 360px;
@mixin bigScreen{
  @media only screen and (min-width: $single_stack_max_width) {
    @content;
  }
}

.composite_settings_cog{
  color:lighten(#000,50);
  margin-left:5px;
  margin-bottom:-3px;
  height:25px;
  width:25px;
  padding:6px;
  &:focus{
    color:#000;
  }
}

.sensor_entities{
  margin:5px -5px;

  @include bigScreen{
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }

  .sensor_entity{
    @include borderRadius();
    border:1px solid #ccc;
    padding:3px;
    flex-grow:1;
    position:relative;
    margin:5px;
    box-sizing:border-box;
    min-width:145px;

    &.out-of-bounds-high{
        background:lighten($selected_high,40);
    }
    &.out-of-bounds-low{
        background:lighten($selected_low,40);
    }
    &.in-bounds{
        background:lighten($selected_in_bounds,40);
    }
    &.active{
      &.out-of-bounds-high{
        background:$selected_high;
      }
      &.out-of-bounds-low{
        background:$selected_low;
      }
      &.in-bounds{
        background:$selected_in_bounds;
      }
    }

    &.disabled{
      color:#ccc;
      .in-bounds,
      .out-of-bounds-high,
      .out-of-bounds-low{
        color:#ccc !important;
      }
    }

    .alarm_icon{
      position:absolute;
      top:2px;
      left:3px;

      .icon_button{
        border:1px solid #ccc;
        background:#eee;
        padding:4px;
      }
    }

    .remedial_wrapper{
      position:absolute;
      top:0px;
      right:2px;

      .remedial{
        background:red;
        color:#fff;
        font-weight:bold;
        padding:3px 10px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        font-size:0.7em;
      }
    }

    .out-of-bounds-high{
      color:red;
    }
    .out-of-bounds-low{
      color:blue;
    }
    .in-bounds{
      color:$green;
    }
  }
}
.reading_adjustment{
  font-size:0.8em;
}

</style>

<script>
import SingleSensorEntity from "@/components/RealtimeSensors/SingleSensorEntity";
export default{
  components: {SingleSensorEntity},
  props:['sensor','selectedSensorIds','showClones','contact_labels'],
  computed:{
    _isCompositeSensor(){
      return this.sensor.entities.length > 1;
    }
  },
  methods:{
    adjustmentDecimalise(adjustment){
      //show the zero if no decimal places
      if( adjustment.toString().indexOf('.') == -1 ){
        return Number(adjustment).toFixed(1);
      }else{
        return adjustment;
      }
    }
  }
}
</script>
