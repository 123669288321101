<template>
	<div v-if="password_required && show && ready">
		<transition name="modal">
			<div class="modal-mask">
				<div class="modal-wrapper">

				<div class="modal-dialog" role="document">
						<div class="modal-content">
								<div class="modal-header">
										<h5 class="modal-title">Password Required</h5>
										<button type="button" class="close" data-dismiss="modal" @click="closeModal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
										</button>
								</div>
								<div class="modal-body" v-if="!blocked">
										<div class="form-group">
											<label>Please enter your password to carry out this action:</label>
											<input type="password" class="form-control" v-model="password" autocomplete="new-password"/>
										</div>
										<div class="alert alert-danger" v-if="message">{{message}}</div>
								</div>
								<div class="modal-footer">
										<button type="button" class="btn btn-block ml-0 mb-2 btn-primary" @click="login" v-if="!blocked">Continue</button>
										<div v-if="blocked" class="text-center my-3">{{blocked_message}}</div>
										<button type="button" class="btn btn-block btn-secondary" @click="closeModal">Close</button>
								</div>
						</div>
				</div>

				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import changes_passworded from '../passworded.js';

export default{
	props:['authenticated'],
	data(){
		return {
			password: '',
			blocked: false,
			interval: null,
			password_required: false,
			ready: false,
			message:'',
			blocked_message:'',
			show:false,
			abandoned_function:null
		}
	},
	mounted(){
		//get current password required status
		this.passwordRequired();
		
		//set function to be executed if authenticated
		if(this.authenticated){
			changes_passworded.on_authenticated = this.authenticated;
		}
	},
	methods:{
		check(success,abandoned){
			if(typeof success == "function"){
				changes_passworded.on_authenticated = success;
			}
			if(typeof abandoned == "function"){
				this.abandoned_function = abandoned;
			}
			this.show=true;
		},
		passwordRequired(){
			return changes_passworded.password_required().then(res=>{
				this.password_required = res;
				
				//tell the view including the password module that
				//password requirements have been loaded, this means its safe to load the view.
				this.$emit('loaded');
				this.ready = true;
				
				//update the blocked state
				if( changes_passworded.retries > changes_passworded.settings.retries ){
				
					//block password entry until = last attempt + retry_timeout minutes
					changes_passworded.blocked = this.moment.unix(changes_passworded.last_retry).add(
						changes_passworded.settings.retry_timeout, 'm'
					).unix();
					
					
					//blocked message
					this.blocked_message = `You have exceeded the allowed password attempts. Please wait until 
					${ this.moment.unix(changes_passworded.blocked).format('HH:mm')}
					before trying again.`;
				}
			});
		},
		closeModal(){
			this.show=false;
			if(typeof this.abandoned_function == "function"){
				this.abandoned_function();
			}
		},
		reset(){
			this.password='';
			this.message='';
			this.ready=false;
		},
		login(){
			var self = this;

			var f = new FormData();
			f.append('password',this.password);

			this.axios.post(this.$root.controllers_base_path+"auth/check_user_password",f).then(function(response){
				if( response.data.status == "success" ){
					changes_passworded.authentication.time = self.moment().unix();
					changes_passworded.on_authenticated();

					//clear the password and hide the view
					self.reset();
					self.show=false;

					//remove the on success function & blocked state
					changes_passworded.blocked=0;
				}else{
					//display incorrect password message
					self.message = response.data.message;
					
					//update the blocked state
					if( response.data.fails > changes_passworded.settings.retries ){
						//block password entry until now + retry_timeout minutes
						changes_passworded.blocked = self.moment().add(
							changes_passworded.settings.retry_timeout, 'm'
						).unix();
						
						//blocked message
						self.blocked_message = 'You have exceeded the allowed password attempts. Please wait for '+changes_passworded.settings.retry_timeout+' minutes before trying again.'
					}
				}
			});
		},
		createInterval(){
			//set an interval to watch the state of block password input
			var self = this;
			clearInterval(this.interval);
			this.interval = setInterval(function(){
				if(changes_passworded.blocked > self.moment().unix()){
					self.blocked = true;
				}else{
					self.blocked = false;
				}
			},1000);
		}
	},
	watch:{
		show(newVal){
			if(newVal == true){
				//the modal has opened so start observing if password entry is blocked
				this.createInterval();
				
				//check for authenticated true and execute attached function
				this.passwordRequired().then(()=>{
					if(!this.password_required){
						changes_passworded.on_authenticated();
						this.reset();
						this.show=false;
					}
				})
				
			}else{
				//its closed, stop the interval
				clearInterval( this.interval );
			}
		},
		authenticated(){
			changes_passworded.on_authenticated = this.authenticated;
		}
	}
}
</script>

<style lang="scss">
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-footer{
	display:block !important;
	>:not(:first-child){
		margin-left:0 !important;
	}
}
</style>
