import axios from 'axios';
var moment = require('moment');
import env from './environment.js';

export default{
	init(){
		//password protection init
		var _this = this;
		return axios.get(env.path()+'auth/password_settings').then(function(response){
			_this.settings = response.data.data.settings;
			_this.retries = response.data.data.fail_count;
			_this.last_retry = response.data.data.last_fail;
			
			if( Number(_this.settings.timer_type) == 1 ){
				//watching time since last password
				_this.authentication.time = response.data.data.last_auth;
			}else{
				//watching time since last setting change
				_this.authentication.time = response.data.data.last_setting_change;
			}
		});
	},
	refresh(){
		//alias method to re-fetch the settings (just incase they have changed))
		return this.init();
	},
	password_required(){
		return this.init().then(()=>{
			if( Number(this.settings.protected)==1 ){
				//company requires password protection
				//run the necessary checks
				if( !this.authentication.time ){
					return true;
				}
				
				/*
				 * time since last auth time
				 * auth time is either last password entry or last setting change depending on company settings
				 */
				if( this.authentication.time ){
					//last auth time present
					//check if time since last auth is greater than timeout
					var time_difference = (moment().unix()-this.authentication.time);
					if( time_difference > (Number(this.settings.timeout)*60) ){
						//last auth timed out
						return true;
					}else{
						//last auth still valid
						return false;
					}
				}else{
					//no last auth so ask for password
					return true;
				}
				
			}else{
				//password protection not required
				return false;
			}
		});
	},

	//this variable will store the function to be called
	//on successfully authenticating the users password
	on_authenticated:null,

	//global store of settings for changes_passworded
	settings:{},

	//retry block
	blocked:0,//this should be set to the unix time the block will last until
	retries:0,
	last_retry:null,

	//last authentication
	authentication:{
		time: null
	}
}
