<template>
<div>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeCanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Landing Page</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">
				<div class="form-group">
					<select class="form-control" v-model="settingsCopy.mobile_landing_page">
						<option value="/alarm-events" :selected="settingsCopy.mobile_landing_page=='/alarm-events'">Alarm Events</option>
						<option value="/dashboard" :selected="settingsCopy.mobile_landing_page=='/dashboard'">Dashboard</option>
                        <option value="/realtime-sensors" :selected="settingsCopy.mobile_landing_page=='/realtime-sensors'">Real-time Sensors</option>
					</select>
				</div>
			</div>
		</div>
		<div class="offcanvas-footer" v-if="hasChanged">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(save)" :disabled="isSaving || !allowChanges">
				<span v-if="!isSaving">Save Changes</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>
	</div>

	<unsaved-changes :show="showWarning" v-on:hide="showWarning=false" v-on:close="forceCloseCanvas" v-on:save="$refs.auth.check(save)"/>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import UnsavedChanges from '../../UnsavedChangesAlert.vue';


export default{
	props:['state','settings'],
	components:{
		UnsavedChanges
	},
	data(){
		return {
			allowChanges: false,
			SaveRequest: null,
            showWarning:false,
            settingsCopy:{
				mobile_landing_page:''
			},
		}
	},
	computed:{
		hasChanged(){
			return !this._.isEqual(this.settingsCopy,this.settings);
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		}
	},
	methods:{
        forceCloseCanvas(){
			this.showWarning = false;
			this.settingsCopy={
                mobile_landing_page:null
            };
			this.$emit('close');
		},
		closeCanvas(){
			if( this.hasChanged ){
				this.showWarning = true;
			}else{
				this.$emit('close');
			}
		},
        save(){
            //cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

            var self = this;
			var f = new FormData();
            f.append('landing_page',this.settingsCopy.mobile_landing_page);

            this.axios.post( this.controller('auth/save_landing_page') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				if( res.data.status == 'success' ){
					self.$emit('reload');
					self.forceCloseCanvas();
				}

				//reset cancelToken to null
				self.SaveRequest = null;
			});
        }
        
	},
	watch:{
		state(a){
			if(a == 'open'){
				this.settingsCopy = this._.cloneDeep(this.settings);
			}
		}
		
	}
}
</script>
