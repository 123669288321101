<template>
	<div>
		<filters v-on:change="d=>{dateRange=d}"/>
		<search-pagination :array="auditData" v-on:data="data=>filteredData=data"/>
		
		<ul class="list-group">
			<li class="list-group-item" v-for="row in filteredData" @click="showAuditAction(row)" :key="row.id">
				<div style="font-size:0.8em;">
					{{moment.unix(row.datetime_of_action_unix).format('DD/MM/YYYY HH:mm')}}<br/>
					{{row.username}}
				</div>
				{{row.notes}}
			</li>
		</ul>
		
		<alert v-if="!_.isEmpty(show)">
			<div slot="title">{{show.title}}</div>
			<div v-html="show.content"></div>
			<div slot="buttons">
				<button class="btn btn-default btn-block" @click="show={}">Close</button>
			</div>
		</alert>
	</div>
</template>

<script>
import Filters from '../components/AuditTrail/Filters.vue';
import SearchPagination from '../components/SearchPagination.vue';
import Alert from '../components/Alert.vue';
export default{
	components:{
		Filters,
		SearchPagination,
		Alert
	},
	data(){
		return {
			dateRange:{},
			auditData:[],
			filteredData:[],
			show:{}
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Audit Trail','/audit-trail']
		]);
	},
	methods:{
		fetchData(){
			var f = new FormData();
			f.append('generate_audit_trail_from',
				this.moment(this.dateRange.from).format('DD/MM/YYYY')
			);
			f.append('generate_audit_trail_to',
				this.moment(this.dateRange.to).format('DD/MM/YYYY')
			);
			var self = this;
			this.axios.post( this.controller('realtime_sensors/generate_audit_trail'), f ).then(res=>{
				self.auditData = res.data.data;
			});
		},
		showAuditAction(row){
			this.show = {
				title: this.moment.unix(row.datetime_of_action_unix).format('DD/MM/YYYY HH:mm'),
				content: `<i>from:</i><br/>${row.value_from}<br/>
				<i>to:</i><br/>${row.value_to}`
			};
		}
	},
	watch:{
		dateRange:{
			handler(){
				//date range has change - refetch the data.
				if( this.dateRange.from != '' && this.dateRange.to != '' && this.moment(this.dateRange.from).unix() <= this.moment(this.dateRange.to).unix() ){
					this.fetchData();
				}
			},
			deep: true
		}
	}
}
</script>