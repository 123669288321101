<template>
<div>
	<contact-details :state="(view=='contact'?'open':'closed')" v-on:close="view=''"/>
	<change-password :state="(view=='changepassword'?'open':'closed')" v-on:close="view=''"/>
	<change-email :state="(view=='changeemail'?'open':'closed')" v-on:close="view=''"/>
	<display-preferences :state="(view=='display'?'open':'closed')" v-on:close="view=''"/>
	<notification-settings :state="(view=='notification'?'open':'closed')" v-on:close="view=''"/>

	<div class="list-group">
		<div @click="view='display'" class="list-group-item">Display Preferences</div>
		<div @click="view='contact'" class="list-group-item">Alarming Contact Details</div>
		<div @click="view='notification'" class="list-group-item">Notification Settings</div>
		<div @click="view='changepassword'" class="list-group-item">Change Password</div>
		<div @click="view='changeemail'" class="list-group-item">Change Login Email</div>
	</div>

</div>
</template>

<script>
import ContactDetails from '../../components/User/ContactDetails.vue';
import ChangePassword from '../../components/User/ChangePassword.vue';
import ChangeEmail from '../../components/User/ChangeEmail.vue';
import DisplayPreferences from '../../components/User/DisplayPreferences.vue';
import NotificationSettings from '../../components/User/NotificationSettings.vue';

export default{
	props:['initView'],
	components:{
		ContactDetails,
		ChangePassword,
		ChangeEmail,
		DisplayPreferences,
		NotificationSettings
	},
	data(){
		return {
			view:''
		}
	},
	watch:{
		view(a){
			if(a == ''){
				//check if an init view was passed. If it was, return to the sitemap as this is where the view was loaded from.
				if(this.initView){
					this.$router.push('/site-map');
				}
			}
		}
	},
	mounted(){

		this.$emit('breadcrumb',[
			['Account Settings','/user/settings']
		]);

		if( this.initView ){
			this.view = this.initView;
		}
	}
}
</script>
