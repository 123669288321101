<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Alarming Contact Details</div>
		</div>
	</nav>

	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<div class="row">
				<div class="col-5" v-if="userCopy.icc">
					<div class="form-group">
						<label>ICC</label>
						<select v-model="userCopy.icc.icc_sms" class="form-control">
							<option v-for="icc in userCopy.icc.icc_codes" :value="icc" :key="icc">{{icc}}</option>
						</select>
					</div>
				</div>
				<div class="col"><form-input v-model="userCopy.sms" label="SMS Number"/></div>
			</div>
            <div class="row mb-3 mt-0 text-danger phone_validation_error" v-if="!smsValidation.valid">
                <div class="col-12">
                    <div class="mb-0" v-for="(error, index) in smsValidation.errors_" :key="index">{{error}}</div>
                </div>
            </div>
			<div class="row">
				<div class="col-5" v-if="userCopy.icc">
					<div class="form-group">
						<label>ICC</label>
						<select v-model="userCopy.icc.icc_phone" class="form-control">
							<option v-for="icc in userCopy.icc.icc_codes" :value="icc" :key="icc">{{icc}}</option>
						</select>
					</div>
				</div>
				<div class="col"><form-input v-model="userCopy.phone" label="Phone Number"/></div>
			</div>
            <div class="row mb-3 mt-0 text-danger phone_validation_error" v-if="!phoneValidation.valid">
                <div class="col-12">
                    <div class="mb-0" v-for="(error, index) in phoneValidation.errors_" :key="index">{{error}}</div>
                </div>
            </div>
			<form-input v-model="userCopy.alarming_email" label="Email Address" :error="validationErrors.alarming_email"/>
		</div>

		<div class="offcanvas-footer" v-if="hasChanged && _.isEmpty(validationErrors)">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="isSaving || !allowChanges">
				<span v-if="!isSaving">Save Changes</span>
				<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
			</button>
		</div>
	</div>


	<alert v-if="showResponse && !_.isEmpty(user)">
		<div slot="title">Important</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
	</alert>

	<unsaved-changes :show="showUnsavedChanges && !_.isEmpty(user)" v-on:hide="showUnsavedChanges=false" :save-disabled="!hasChanged || !_.isEmpty(validationErrors)" v-on:close="forceCloseOffcanvas" v-on:save="$refs.auth.check(saveChanges)"/>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>

</template>

<script>
import FormInput from '../FormElements/Input.vue';
import Alert from '../Alert.vue';
import UnsavedChanges from '../UnsavedChangesAlert.vue';
import PasswordModal from '../PasswordModal.vue';

export default{
	props:['state'],
	components:{
		FormInput,
		Alert,
		PasswordModal,
		UnsavedChanges
	},
	data(){
		return {
			user:{},
			userCopy:{},
			validationErrors:{},
			showUnsavedChanges: false,
			responseMessage: '',
			showResponse: false,
			SaveRequest: null,
			showPasswordModal: false,
			allowChanges:false
		}
	},
	methods:{
		closeOffcanvas(){
			if( this.hasChanged ){
				this.showUnsavedChanges = true;
			}else{
				this.$emit('close');
				this.userCopy = {};
			}
		},
		forceCloseOffcanvas(){
			this.showUnsavedChanges = false;
			this.$emit('close');
			this.userCopy = {};
		},
		getUser(){
			var self = this;
			return this.axios.get( this.controller("auth/contact_details") ).then(res=>{
				self.user = res.data.data;
			})
		},
		validate(){
			this.validationErrors = {}

			//check mobile and phone
			//var phone_test = /^(0[0-9]{10})$/;
			//var phone_with_icc_test = /^(\+[0-9]{1,3})(0[0-9]{10})$/;
			//if( this.userCopy.sms && !phone_test.test(this.userCopy.sms) && !phone_with_icc_test.test(this.userCopy.sms) ){
			//	this.validationErrors.sms = '11 numbers required<br/>(optional icc code e.g. +44)';
			//}
			//if( this.userCopy.phone && !phone_test.test(this.userCopy.phone) && !phone_with_icc_test.test(this.userCopy.phone) ){
			//	this.validationErrors.phone = '11 numbers required<br/>(optional icc code e.g. +44)';
			//}
            if(!this.phoneValidation.valid){
                this.validationErrors.phone = 'invalid';
            }
            if(!this.smsValidation.valid){
                this.validationErrors.sms = 'invalid';
            }

			//alarming email
			if(this.userCopy.alarming_email && !this.isValidEmail(this.userCopy.alarming_email)){
				this.validationErrors.alarming_email = 'Invalid address';
			}
		},
		saveChanges(){
			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			var f = new FormData();
			f.append('sms', (this.userCopy.sms?this.userCopy.sms:''));
			f.append('phone', (this.userCopy.phone?this.userCopy.phone:''));
			f.append('alarming_email',(this.userCopy.alarming_email?this.userCopy.alarming_email:''))

			//if has icc enabled
			if(this.userCopy.icc){
				f.append('icc_sms', this.userCopy.icc.icc_sms);
				f.append('icc_phone', this.userCopy.icc.icc_phone);
			}

			var self = this;
			this.showUnsavedChanges=false;

			this.axios.post( this.controller('auth/contact_details') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{

				if(res.data.status == 'failed'){
					self.responseMessage = res.data.message;
					self.showResponse = true;
				}else{
					self.userCopy = {};
					self.$emit('close');
				}

				self.SaveRequest = null;
			});
		}
	},
	computed:{
		hasChanged(){
			return ( this._.isEqual(this.user,this.userCopy) ? false : true );
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		},
        phoneValidation(){
            var icc = (this.userCopy.icc ? this.userCopy.icc.icc_phone : '')
            return new this.Phone_validation(this.userCopy.phone, icc, this)
        },
        smsValidation(){
            var icc = (this.userCopy.icc ? this.userCopy.icc.icc_sms : '')
            return new this.Phone_validation(this.userCopy.sms, icc, this)
        }
	},
	watch:{
		state(a){
			if(a == "open"){
				this.getUser().then(()=>{
					this.userCopy = this._.cloneDeep(this.user);
				});
			}
		},
		userCopy:{
			handler(){
				this.validate();
			},
			deep:true
		}
	}
}
</script>
