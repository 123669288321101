<template>
<div>
	<ul class="list-group">
		<li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
			<div><font-awesome-icon icon="ellipsis-v" class="mr-2" fixed-width/> <i style="font-size:0.8em;">Drag to set alarm priority</i></div>
			<div class="text-right" style="font-size:14px;">
				<div style="background:#06ab03;border-radius:8px;height:15px;width:25px;margin-bottom:-3px;" class="d-inline-block mr-1"></div> <span>= Enabled</span>
				<div>{{activeUsers.length}} Enabled Users</div>
			</div>
		</li>
	</ul>
	<draggable v-model="usersCopy" class="list-group" @start="reordering=true" @end="reordering=false" handle=".handle">
		<li class="list-group-item" v-for="user in usersCopy" :key="user.userid">
			<div class="d-flex justify-content-between align-items-center">
				<div><font-awesome-icon icon="ellipsis-v" class="handle mr-2" fixed-width/>
				{{user.first_name}} {{user.last_name}} <font-awesome-icon v-if="loading.indexOf(user.id) > -1" icon="spinner" spin/></div>
					<switch-input v-model="user._in_group" v-on:input="v=>{
						$refs.auth.check(()=>{
							//because this has values being passed it has to be wrapped in another func
							//to avoid it trying to execute rather than pass as callback
							//toggleActive(v,user)
						},()=>{
							revertToggle(user);
						});
					}" :ref="user.id"/>
			</div>
		</li>
	</draggable>

	<alert v-if="showAlert">
		<div slot="title">Save Error</div>
		{{message}}
		<div slot="buttons">
			<button class="btn btn-default btn-block" @click="showAlert=false">Ok</button>
		</div>
	</alert>

	<div class="offcanvas-footer" v-if="changed">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)">Save Changes</button>
	</div>

	<unsaved-changes :show="showUnsavedChanges" v-on:close="forceCloseOffcanvas" v-on:hide="showUnsavedChanges=false" v-on:save="$refs.auth.check(saveChangesAndClose)"/>

	<password-modal ref="auth"/>
</div>
</template>

<script>
import SwitchInput from '../../FormElements/Switch.vue';
import Alert from '../../Alert.vue';
import draggable from 'vuedraggable';
import UnsavedChanges from '../../UnsavedChangesAlert.vue';

export default{
	components:{
		SwitchInput,
		Alert,
		draggable,
		UnsavedChanges
	},
	props:['users','group'],
	data(){
		return {
			message: '',
			showAlert:false,
			loading:[],
			usersCopy:[],
			reordering: false,
			showUnsavedChanges: false
		}
	},
	computed:{
		changed(){
			return ( this._.isEqual(this.originalUsers,this.usersCopy) ? false : true );
		},
		originalUsers(){
			//only here to compare for changes against usersCopy
			return this._.sortBy(
				this.users,
				['_priority']
			);
		},
		activeUsers(){
			return this.usersCopy.filter(user=>{
				return Number(user._in_group) == 1;
			});
		}
	},
	methods:{
		close(){
			if( this.changed ){
				this.showUnsavedChanges=true;
			}else{
				this.forceCloseOffcanvas();
			}
		},
		forceCloseOffcanvas(){
			this.showUnsavedChanges=false;
			this.$emit('close');
		},
		revertToggle(user){
			user._in_group = (Number(user._in_group) == 1 || user._in_group == true ? '0' : '1');
		},
		saveChangesAndClose(){
			this.saveChanges().then(()=>{
				this.forceCloseOffcanvas();
			})
		},
		saveChanges(){
			return new Promise((resolve)=>{
				var changed_users = this._.differenceWith(this.usersCopy,this.originalUsers,this._.isEqual);

				var self = this;
				var f = new FormData();
				f.append('users',JSON.stringify(changed_users));
				f.append('alarm_group',this.group);

				this.axios.post(this.controller('settings/save_group_users'),f).then(function(response){
					if( response.data.status == "success" ){
						//emit reload list
						self.saveOrder().then(()=>{
							resolve();
						});
					}else{
						//alert message
						self.message = response.data.message;
						self.showAlert = true;
					}
					
				});
			});
		},
		removeLoading(id){
			if( typeof id == 'undefined' ){
				this.loading=[];
			}else{
				var x = this.loading.indexOf(id);
				this.loading.splice(x,1);
			}
		},
		saveOrder(){
			return new Promise((resolve)=>{
				var order = [];
				var i = 1;
				this.activeUsers.forEach(user=>{
				
					order.push({
						'user':user.id,
						'priority':i
					})

					//start the loader spinning for this user
					this.loading.push(user.id);
					
					i++;
				});
				
				var f = new FormData();
				f.append('priority',JSON.stringify(order));
				f.append('group_id',this.group);
				var self = this;
				this.axios.post( this.controller('settings/save_user_priority'), f ).then(()=>{
					//stop all loading spinners
					self.removeLoading();
					this.$emit('reload');
					resolve();
				});
			});
		}
	},
	watch:{
		users:{
			handler(){
				this.usersCopy = this._.sortBy(
					this._.cloneDeep(this.users),
					['_priority']
				);
			},
			deep: true
		},
		reordering(a,b){
			if(a == false && b == true){
				//this.saveOrder();
				//no longer needed as triggered by button click
			}
		}
	}
}
</script>
