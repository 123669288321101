<template>
<div>
	<div v-for="(section,index) in list" :key="index">
		<h5 class="container-fluid my-3"><font-awesome-icon v-if="section.icon" :icon="section.icon" class="mr-1" fixed-width />{{section.title}}</h5>
		<div class="list-group">
			<router-link class="list-group-item" v-for="(link,index2) in section.links" :to="link.url" :key="index2">{{link.name}}</router-link>
		</div>
	</div>
</div>
</template>

<script>
export default{
	data(){
		return {
			list:[
				{
					title: 'Account Settings',
					icon: 'user',
					links: [
						{name: 'Change Password', url: '/user/settings/changepassword'},
						{name: 'Change Login Email', url: '/user/settings/changeemail'},
						{name: 'Notification Settings', url: '/user/settings/notification'},
						{name: 'Alarm Contact Details', url: '/user/settings/contact'},
						{name: 'Display Preferences', url: '/user/settings/display'}
					]
				},
				{
					title: 'Remedial Actions',
					icon: 'bell',
					links: [
						{name: 'Alarm Events', url: '/alarm-events'}
					]
				},
				{
					title: 'Realtime Sensors',
					icon: 'thermometer-half',
					links: [
						{name: 'Realtime Sensors', url: '/realtime-sensors'}
					]
				}
			]
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Site Map','/site-map']
		]);
	},
}
</script>
