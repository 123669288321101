<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="close"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">{{name}} Users</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3 bg-default">
			<search v-model="search"/>
		</div>
		<loading :state="loading"></loading>
		<user-list ref="list" :users="filteredUsers" :group="group" v-on:reload="getUsers" v-on:close="$emit('close')"/>
	</div>
</div>
</template>

<script>
import UserList from './UserList.vue';
import Search from '../../FormElements/Search.vue';
import Loading from '../../Loading.vue';
export default{
	props:['state','group','name'],
	components:{
		UserList,
		Search,
		Loading
	},
	data(){
		return {
			users: [],
			search: '',
			loading: false,
			Request: null
		}
	},
	watch:{
		state(a){
			if( a == 'open' ){
				this.getUsers();
			}
		}
	},
	computed:{
		filteredUsers(){
			return this.searchArrayObjects(this.users,this.search);
		}
	},
	methods:{
		close(){
			//call close function in user-list component as this component knows if changes have been made
			//it will emit close if its ok to close
			this.$refs.list.close()
		},
		getUsers(){
			if( this.Request ){
				this.Request.cancel();
			}
			this.Request = this.axios.CancelToken.source();

			var f = new FormData();
			f.append('group_id',this.group);
			this.loading = true;
			var self = this;
			this.axios.post(this.controller('settings/get_all_users_for_sdr_group'),f,{
				cancelToken: this.Request.token
			}).then(function(response){
				self.users = response.data.data;
				self.$emit('reload-alarm-groups');
				self.loading = false;

				self.Request = null;
			});
		}
	}
}
</script>
