<template>
<div class="list-group-item" :class="{selected: selected==true}" @click="$emit('select',sensor)">
	<div class="d-flex justify-content-between">
		<div>
			<b>{{sensor.id}}</b>
			<div class="mw-100" style="font-size:0.8em;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;">
				{{sensor.name}}
			</div>
			<div>
				<small>
					<span v-if="sensor.reading_timestamp_unix"><font-awesome-icon icon="clock" fixed-width /> {{(Number(sensor.reading_timestamp_unix) != 0 ? moment.unix(sensor.reading_timestamp_unix).format('DD/MM/YYYY HH:mm') : 'No Readings')}}<br/></span>
				</small>
			</div>
		</div>
		<div class="text-right">
      <sensor-entity
          v-for="(entity, i) in sensor.entities"
          :key="i"
          :entity="entity"
          :contact-labels="contact_labels"
      />
		</div>
	</div>
</div>
</template>

<script>
import SensorEntity from "@/components/Settings/SensorThresholds/SensorEntity";
export default{
  components: {SensorEntity},
  props:['sensor','selected','contact_labels'],
}
</script>

<style lang="scss" scoped>
@import "../../../assets/_vars.scss";

.selected{
	background:$green;
	color:#fff;
}
</style>