<template>
<div class="container-fluid bg-white py-3">
	<h5 class="my-3">Customer Support Enquiry</h5>
	<p>This form can be used to request support from our team.</p>
	<form-input label="Subject" v-model="subject" :error="validationErrors.subject"/>
	<div class="form-group">
		<label>Message</label>
		<textarea class="form-control" rows="5" v-model="message"></textarea>
	</div>
	<button class="btn btn-block btn-success my-3" :disabled="subject.trim()=='' || message.trim()=='' || !_.isEmpty(validationErrors)" @click="send">Send</button>
	
	<alert v-if="alertMsg">
		<div slot="title">Notice</div>
		{{alertMsg}}
		<button class="btn btn-secondary btn-block" @click="alertMsg=''" slot="buttons">Ok</button>
	</alert>
</div>
</template>

<script>
import FormInput from '../components/FormElements/Input.vue';
import Alert from '../components/Alert.vue';
export default{
	components:{
		FormInput,
		Alert
	},
	data(){
		return {
			subject: '',
			message: '',
			validationErrors: {},
			SendRequest: null,
			alertMsg: ''
		}
	},
	methods:{
		validateForm(){
			this.validationErrors = {};
			
			//subject
			if( this.subject && this.subject.length > 255 ){
				this.validationErrors.subject = 'Too long, max 255';
			}
		},
		send(){
			var f = new FormData();
			f.append('subject',this.subject);
			f.append('message',this.message);
			
			//cancel existing graph requests if promise exists
			if(this.SendRequest){
				this.SendRequest.cancel();
			}
			//Create new cancellable request
			this.SendRequest = this.axios.CancelToken.source();
		
			var self = this;
			this.axios.post( this.controller('dashboard/submit_contact'), f, {
				cancelToken: this.SendRequest.token
			}).then(res=>{
				if( res.data.status == 'success' ){
					//display message
					self.alertMsg = 'Message sent successfully';
					
					//reset form
					self.subject='';
					self.message='';
				}else{
					self.alertMsg = res.data.msg;
				}
			
				self.SendRequest = null;
			});
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Customer Support','/support']
		]);
	},
	watch:{
		subject(){
			this.validateForm();
		},
		message(){
			this.validateForm();
		}
	}
}
</script>
