//var moment = require('moment');

// const $ = require('jquery');
// import moment from 'moment';
import _ from 'lodash'

/*
import styling plugins
*/
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell,faThermometerHalf,faCogs,faComments, faSearch, faBars, faUser, faTachometerAlt, faDesktop, faSignOutAlt, faChevronRight, faChevronLeft, faSpinner, faCaretLeft, faCaretRight, faBuilding, faClock, faChevronDown, faChevronUp, faSortUp, faSortDown, faUsers, faSlidersH, faExclamationTriangle, faExclamationCircle, faFilePdf, faTimesCircle, faPhone, faMobileAlt, faLock, faCheckCircle, faSortAmountUp, faSortAmountDown, faHome, faCog, faChartLine, faListUl, faEllipsisV, faGlobe, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/*
set backend url
*/
import env from './environment.js';
/*
import vue related plugins
*/
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import VueSession from 'vue-session';
var VueCookies = require('vue-cookies');
import axios from 'axios';
import VueAxios from 'vue-axios';
import vSelect from 'vue-select'
import VueLodash from 'vue-lodash'
import Multiselect from 'vue-multiselect'
import PasswordModal from './components/PasswordModal.vue';
import VueJWT from 'vuejs-jwt'
import 'es6-promise/auto'
import VueTouch from 'vue-touch';
/*
custom route guarding object
*/
import guard from './guard.js';


library.add(faBell,faThermometerHalf,faCogs,faComments,faSearch,faBars,faUser, faTachometerAlt, faDesktop, faSignOutAlt, faChevronRight, faChevronLeft, faSpinner, faCaretLeft, faCaretRight, faBuilding, faClock, faChevronDown, faChevronUp, faSortUp, faSortDown, faUsers, faSlidersH, faExclamationTriangle, faExclamationCircle, faFilePdf, faTimesCircle, faPhone, faMobileAlt, faLock, faCheckCircle, faSortAmountUp, faSortAmountDown, faHome, faCog, faChartLine, faListUl, faEllipsisV, faGlobe, faNetworkWired );
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)

Vue.use(Vuex)
Vue.use(VueSession);
Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));
Vue.component('v-select', vSelect);
Vue.use(VueLodash,{lodash:_});
Vue.component('password-modal',PasswordModal);
Vue.use(VueJWT)
Vue.use(VueTouch);

//Create Store for VueX Data
import store from './store.js';

Vue.config.productionTip = false

import mixin from './mixin.js';
Vue.mixin(mixin);

//create a routing guard to catch navigation when the session is unauthorised
router.beforeEach((to, from, next) => {

	//close the side navs
	if( router.app.$data ){
		router.app.$data.leftMenuState = "";
		router.app.$data.rightMenuState = "";
	}

	//console.log(store.state)
	if( to.path != "/login" && to.path != "/direct_login" && (typeof store == "undefined" || router.app._.isEmpty(store.state.user)) ){
		next('/login');
	}else if(store.state.user){

		//checked logged in user has access to route
		if( guard.userAllowedAccess(to.path,store.state.user.group) ){
				//check the user has a company and department set
				if(
					to.path != "/direct_login" &&
					to.path != "/privacy-policy" &&
					to.path != "/logout" &&
					to.path != "/super-admin/company-changer" &&
					to.path != "/department-changer" &&
					(Number(store.state.user.company.id) == 0 || Number(store.state.user.department.id) == 0)
				){
					if( router.app.userIs('superAdmin') ){
						router.push('/super-admin/company-changer');
					}else{
						router.push('/department-changer');
					}
				}else{
					next();
				}
		}else{
			//if user is not 4 or 5 and has no company push back to login//
			if( !_.isEmpty(store.state.user) && [4,5].indexOf( Number(store.state.user.group) ) == -1 && Number(store.state.user.company.id) == 0 ){
				router.app.$session.flash.set('message', 'Your user has no company assigned. Please contact us.');

				if(from.path == '/login'){
					router.go();
				}else{
					if(from.path != '/login'){
						next('/login');
					}
				}
			}else{
				next('/');
			}
		}

	}
});

//In the case of a 401 error (invalid session) in any call through axios
//this interceptor will redirect to login
function session_monitor(app){

	//listen to all axios responses for redirect and message
	//this pushes the browser to the login view if the php session timesout
	app.axios.interceptors.response.use(
		function(response){
			//check for token refresh
			if( typeof response.data.jwt != 'undefined' ){
				store.dispatch('setToken', response.data.jwt);
				
				//check the jwt has a department, if not we need to redirect the user
				if(!_.isEmpty(store.state.user) && store.state.user.department && Number(store.state.user.department.id) == 0 && app.$route.path != '/super-admin/company-changer' && app.$route.path != '/department-changer' ){
					//set flash message
					app.$session.flash.set('msg','Please select a department to proceed.');

					if( app.userIs('superAdmin') ){
						router.push('/super-admin/company-changer');
					}else{
						router.push('/department-changer');
					}
				}
			}

			//catch access denied
			if( typeof response.data.status != 'undefined' && response.data.status == 'denied' ){
				//show alert
				store.commit('setDeniedAlert',true);
			}

			//catch redirect request
			if( typeof response.data.redirect != 'undefined' && response.data.redirect != '' ){
				//set flash data message
				if( typeof response.data.message != 'undefined' && response.data.message != '' ){
					app.$session.flash.set('message', response.data.message);
				}
				//redirect to passed url
				router.push(response.data.redirect);
			}
			return response;
		},
		function(error) {
			if (error.response && error.response.status === 401) {
				router.push('/login');
			}
			return Promise.reject(error);
		}
	);

	//this adds config options to every request
	//we add the cookie token (which the php side created during login and will compare)
	app.axios.interceptors.request.use(function (config) {
		// Do something before request is sent
		if(store.state.token != ''){
			config.headers = {'Authorization': store.state.token};
		}
		return config;

	}, function (error) {
		// Do something with request error
		return Promise.reject(error);
	});
}

/*
MAIN MAIN APPLICATION ENTRY POINT
*/
new Vue({
	router,
	store,
	data:{
		controllers_base_path:env.path(),
		user:{},
		offcanvasState: "",
		leftMenuState: "",
		rightMenuState: "",
		breadcrumb:[['Dashboard','/']]
	},
	created: function(){
		this.$session.start();
		session_monitor(this);
	},
	mounted: function(){
		/*
		//check if user has set mobile/desktop preference
		//only consider if the visit is direct, this avoids infinite
		//loops of refirecting between the two sites.
		if(!document.referrer){
			//get the preference cookie
			var site_preference = window.localStorage.getItem('site_preference');
			if(site_preference == 'desktop'){
				//push to desktop site login
				this.useDesktopSite();
			}
		}else{
			//check if has been redirected from desktop site
			//remove localStorage preference if true
			if( typeof this.$route.query.preference_change != "undefined" ){
				window.localStorage.removeItem('site_preference');
			}	
		}
		*/

		//check for jwt being passed in from desktop site
		if(this.$route.query.auth){
			var jwt = this.$route.query.auth;
			//set jwt token
			this.$store.dispatch('setToken', jwt).then(()=>{
				if(this.$route.query.redirect){
					this.$router.push('/'+this.$route.query.redirect)
				}else{
					this.$router.push('/');
				}
			});
		}

		if( this.$store.state && this._.isEmpty(this.$store.state.user) ){
			if(this.$route.path != '/login' && this.$route.path != '/direct_login'){
				console.log('path: '+this.$route.path);
				router.push('/login');
			}
		}else{
			//store has a user, update
			this.$store.dispatch('refreshUser');
		}
	},
	render: h => h(App)
}).$mount('#app')
