<template>
<div>
	<ul class="nav my-4 w-100 justify-content-between px-5">
		<li class="nav-item" :class="{active:step==1}">1</li>
		<li class="nav-item" :class="{active:step==2}">2</li>
		<li class="nav-item" :class="{active:step==3}">3</li>
	</ul>

	<div class="bg-white container-fluid py-3">

		<div v-if="step==1">
			<h5 class="text-center my-3">Change Your Password</h5>
			<password v-model="password" v-on:strength="s=>passwordStrength=s"/>
			<form-input type="password" v-model="confirmPassword" label="Confirm Password" :disabled="passwordStrength<4" :error="validationErrors.confirmPassword"/>
		</div>
		<div v-if="step==2">
			<h5 class="text-center my-3">Enter Contact Details</h5>
			<div class="row">
				<div class="col-5" v-if="userCopy.icc">
					<div class="form-group">
						<label>ICC</label>
						<select v-model="userCopy.icc.icc_sms" class="form-control">
							<option v-for="icc in userCopy.icc.icc_codes" :value="icc" :key="icc">{{icc}}</option>
						</select>
					</div>
				</div>
				<div class="col"><form-input v-model="userCopy.sms" label="Mobile Number" :error="validationErrors.sms"/></div>
			</div>
			<div class="row">
				<div class="col-5" v-if="userCopy.icc">
					<div class="form-group">
						<label>ICC</label>
						<select v-model="userCopy.icc.icc_phone" class="form-control">
							<option v-for="icc in userCopy.icc.icc_codes" :value="icc" :key="icc">{{icc}}</option>
						</select>
					</div>
				</div>
				<div class="col"><form-input v-model="userCopy.phone" label="Phone Number" :error="validationErrors.phone"/></div>
			</div>
			<form-input v-model="userCopy.alarming_email" label="Email Address" :error="validationErrors.alarming_email"/>
		</div>
		<div v-if="step==3">
			<h5 class="text-center my-3">Website Navigation Help</h5>
			<ul class="list-group">
				<li class="list-group-item">
					<h6 class="mb-3">Account Settings</h6>
					<p class="my-0">Button in the top right hand side of the screen containing your name is for editing your personal settings.</p>
				</li>
				<li class="list-group-item">
					<h6 class="mb-3">Alarm Events</h6>
					<p class="my-0">Alarm events for your equipment.</p>
				</li>
				<li class="list-group-item">
					<h6 class="mb-3">Power Probes</h6>
					<p class="my-0">View and report on all power probes.</p>
				</li>
				<li class="list-group-item">
					<h6 class="mb-3">Realtime Sensors</h6>
					<p class="my-0">Live readings for your equipment.</p>
				</li>
				<li class="list-group-item">
					<h6 class="mb-3">Reports</h6>
					<p class="my-0">Run reports on historical data for your equipment.</p>
				</li>
				<li class="list-group-item">
					<h6 class="mb-3">Settings</h6>
					<p class="my-0">View your alarm groups and sensor settings.</p>
				</li>
			</ul>
		</div>

		<button class="btn btn-success btn-block mt-4" @click="next">Next</button>

	</div>

	<alert v-if="responseMessage != ''">
		<div slot="title">Error</div>
		{{responseMessage}}
		<button slot="buttons" class="btn btn-default btn-block" @click="responseMessage=''">Ok</button>
	</alert>
</div>
</template>

<script>
import Password from '../../components/FormElements/PasswordStrength.vue';
import FormInput from '../../components/FormElements/Input.vue';
import Alert from '../../components/Alert.vue';

export default{
	components:{
		Password,
		FormInput,
		Alert
	},
	data(){
		return {
			step:1,
			password:'',
			passwordStrength:0,
			confirmPassword:'',
			user:{},
			userCopy:{},
			validationErrors:{},
			responseMessage:''
		}
	},
	methods:{
		next(){
			//save current step
			var f = new FormData();
			var self = this;
			
			switch(this.step){
				case 1:
					//save the password
					f.append('new_password',this.password);
					f.append('confirm_password',this.confirmPassword);

					this.axios.post( this.controller('auth/first_change_password'), f ).then(res=>{
						if( res.data.status == 'success' ){
							//move to next step
							self.step++;
						}else{
							//display error message
							self.responseMessage = res.data.message;
						}
					});
				break;

				case 2:
					//save the password
					f.append('sms', this.userCopy.sms);
					f.append('phone', this.userCopy.phone);
					f.append('alarming_email',this.userCopy.alarming_email)

					//if has icc enabled
					if(this.userCopy.icc){
						f.append('icc_sms', this.userCopy.icc.icc_sms);
						f.append('icc_phone', this.userCopy.icc.icc_phone);
					}

					this.axios.post( this.controller('auth/contact_details'), f ).then(res=>{
						if( res.data.status == 'success' ){
							//move to next step
							self.step++;
						}else{
							//display error message
							self.responseMessage = res.data.message;
						}
					});
				break;

				case 3:
					this.$router.push('/');
				break;
			}
		},
		getUser(){
			var self = this;
			this.axios.get( this.controller('auth/contact_details') ).then(res=>{
				self.user = res.data;
			});
		},
		validateErrors(){
			//run validation

			this.validationErrors = {};

			switch(this.step){
				case 1:
					if( this.userCopy.confirm_password && this.userCopy.confirmPassword != this.userCopy.password ){
						this.validationErrors.confirmPassword = 'mismatch';
					}
				break;

				case 2:
					//check mobile and phone
					var phone_test = /^(0[0-9]{10})$/;
					if( this.userCopy.sms && !phone_test.test(this.userCopy.sms) ){
						this.validationErrors.sms = '11 numbers required';
					}
					if( this.userCopy.phone && !phone_test.test(this.userCopy.phone) ){
						this.validationErrors.phone = '11 numbers required';
					}

					//alarming email
					if(this.userCopy.alarming_email && !this.isValidEmail(this.userCopy.alarming_email)){
						this.validationErrors.alarming_email = 'Invalid address';
					}
				break;

				case 3:

				break;
			}
		}
	},
	mounted(){
		this.getUser();
	},
	watch:{
		user:{
			handler(){
				this.userCopy = this._.cloneDeep(this.user);
			},
			deep:true
		},
		userCopy:{
			handler(){
				this.validateErrors();
			},
			deep: true
		},
		confirmPassword(){
			this.validateErrors();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/_vars.scss';
.nav-item{
	width:35px;
	height:35px;
	text-align:center;
	display:flex;
	justify-content:center;
	align-items:center;
	color:#777;
}

.active{
	border:2px solid $green;
	border-radius:50px;
	color:#000;
	font-weight:bold;
}
</style>
