<template>
	<div class="offcanvas" :class="state">
        <nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeGraph"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">{{alarm.location_name}} ({{alarm.sensor_id}}) - {{moment.unix(this.alarm.reading_timestamp_unix).format('DD/MM/YY')}}</div>
			</div>
		</nav>
        <div class="offcanvas-body">
            <!--<dygraph-module v-if="!_.isEmpty(rawData)" :data-string="graphData" :options="{
                colors: ['green','blue','red'],
                legend: 'always',
                labelsSeparateLines: true,
                labels: graphLabels,
                connectSeparatedPoints: true,
                animatedZooms: true,
                customBars: true,
                drawCallback:function(a,initial_draw){
					dragged(a);
					if(initial_draw){
						update_alarms(a);
					}
				}
            }" ref="graph"></dygraph-module>-->
			<GChart :class="{'d-none':_.isEmpty(chartData)}" style="position:relative;"
					:settings="{ packages: ['corechart'] }"
					type="LineChart"
					:data="chartData"
					:options="chartOptions"
					@ready="onChartReady"
					ref="graphContainer"
					:events="chartEvents"
				/>
                
            <div v-if="loading" class="h-100 d-flex align-items-center justify-content-center">
				<loading :state="true" size="1.5"/>
			</div>
        </div>
    </div>
</template>

<script>
import Loading from '../Loading.vue';
//import DygraphModule from '../DygraphWrapper.vue';
import { GChart } from 'vue-google-charts';
const $ = require('jquery');

export default{
	components:{
		Loading,
		GChart
	},
	props:['state','alarm','contact_labels'],
    data(){
        return {
            loading:false,
            GraphRequest:null,
            rawData:[],
            graphData:[],

			/*Graph settings and vars*/
			alarms:[],
			GoogleGraphObject:null,
			ChartObject:null,
			chartEvents:{
				'ready': () => {
					// handle event here
					this.placeMarkers();
				}
			},
			graphHeight:0,
			graphWidth:0,
			chartData: [],
			chartOptions:{
				width: 0,
				height: 0,
				series: {
					0: { color: 'green' },
					1: { color: 'blue' },
					2: { color: 'red' },
				},
				chartArea: {width:0,left:50,top:20,bottom:40,height:0},
				animation:{
					duration: 100,
					easing: 'inAndOut',
					startup: true
				},
				intervals: { 'style':'area' },
				legend: { position: 'top', alignment: 'end' },
				explorer: {
					keepInBounds: true,
					maxZoomIn: .01,
					maxZoomOut: 1
				},
				vAxis: {
					viewWindowMode:'explicit',
					viewWindow: {
						max:250,
						min:-200
					},
					ticks:null
				},
				hAxis:{
					viewWindow: {
						max:null,
						min:null
					},
					gridlines: {
						count: -1,
						units: {
							days: {format: ['d/M/yy']},       // <-----
							hours: {format: ['HH:mm', 'ha']}, // <-----
						}
					},
					minorGridlines: {
						count: -1,
						units: {
							hours: {format: ['HH:mm', 'ha']},       // <-----
							minutes: {format: ['HH:mm a', ':mm']} // <-----
						}
					}
				}
			}
        }
    },
	mounted(){
		this.setGraphSize();
		var _this = this;
		window.addEventListener("resize", ()=>{
			setTimeout(function(){
				_this.setGraphSize();
			},200);
		});
	},
	computed:{
		ContactLabels(){
			if( this.isContactSensor(this.alarm.sensor_type_id) ){
				var labels_ = this.contact_labels.filter(label => {
					return Number(label.id)==Number(this.alarm.contact_sensor_label_id);
				})[0];

				if(labels_){
					return labels_;
				}else{
					return {
						reading_0_label: '0',
						reading_1_label: '1',
					};
				}
			}else{
				return null;
			}
		}
	},
    methods:{
        closeGraph(){
            this.rawData = [];
            this.$emit('close');
        },
		setGraphSize(){
			this.graphHeight = (window.innerHeight-60);
			this.graphWidth = (window.innerWidth-20);

			this.chartOptions.chartArea.width=this.graphWidth;
			this.chartOptions.width=this.graphWidth;
			this.chartOptions.chartArea.height=this.graphHeight;
			this.chartOptions.height=this.graphHeight;
		},
		onChartReady (chart, google) {
			//store this for use later
			this.GoogleGraphObject = google;
			this.ChartObject = chart;
			//window
		},

        /*dragged(a){
			if( a.xAxisRange()[0] < a.xAxisExtremes()[0] || a.xAxisRange()[1] > a.xAxisExtremes()[1] ){
				this.$refs.graph.unzoom()
			}
		},*/
        
        /*
		 * Add the alarm annotations to the graph
		 */
		/*
		update_alarms(graph){
			//only used here so not made global
			function alarm_processor(value){
				if(value.timestamp_epoch == null){
					value.timestamp_epoch = parseInt(value.reading_timestamp_unix)*1000;
				}
				if(value.reading_datetime_string == null){
					value.reading_datetime = new Date(value.timestamp_epoch);
					value.reading_datetime_string = value.reading_datetime.getFullYear()+'/'+('0'+(value.reading_datetime.getMonth()+1)).slice(-2)+'/'+('0'+value.reading_datetime.getDate()).slice(-2)+' '+('0'+value.reading_datetime.getHours()).slice(-2)+':'+('0'+value.reading_datetime.getMinutes()).slice(-2)+':'+('0'+value.reading_datetime.getSeconds()).slice(-2);
				}
				if(value.pending == 0 && value.ack_datetime_string == null){
					value.acknowledgment_datetime = new Date(value.acknowledgment_timestamp_unix*1000);
					value.ack_datetime_string = value.acknowledgment_datetime.getFullYear()+'/'+('0'+(value.acknowledgment_datetime.getMonth()+1)).slice(-2)+'/'+('0'+value.acknowledgment_datetime.getDate()).slice(-2)+' '+('0'+value.acknowledgment_datetime.getHours()).slice(-2)+':'+('0'+value.acknowledgment_datetime.getMinutes()).slice(-2)+':'+('0'+value.acknowledgment_datetime.getSeconds()).slice(-2);
				}
				if(value.cancel_datetime_string == null && value.cancellation_timestamp_unix != null){
					value.cancellation_datetime = new Date(value.cancellation_timestamp_unix*1000);
					value.cancel_datetime_string = value.cancellation_datetime.getFullYear()+'/'+('0'+(value.cancellation_datetime.getMonth()+1)).slice(-2)+'/'+('0'+value.cancellation_datetime.getDate()).slice(-2)+' '+('0'+value.cancellation_datetime.getHours()).slice(-2)+':'+('0'+value.cancellation_datetime.getMinutes()).slice(-2)+':'+('0'+value.cancellation_datetime.getSeconds()).slice(-2);
				}
				return value;
			}
		
			var alarms = [];
			//each datapoint with alarm events
			this.rawData.forEach(sensor=>{
				sensor.alarm_events.forEach(v=>{
					//each alarm event for this datapoint
					v[1].forEach(value=>{
						//process dataset for cache
						if(value.timestamp_epoch == null){
							value = alarm_processor(value);
						}

						alarms.push({
							series: 'Sensor '+sensor.sensor.id,
							x: value.timestamp_epoch,
							icon: 'assets/misc_icons/annotations_1_0_0_0.png',
							width: 18,
							height: 18,
                            text:''
						});
					});
				});
			});
			graph.setAnnotations(alarms);
		},
		*/

        /*
         * GET RAW GRAPH DATA AND STORE
         */
        fetchGraphData(){
			var self = this;

			this.chartData = [];

			var f = new FormData();
			f.append('sensor_ids',[this.alarm.sensor_id]);
			f.append('ts_from', (Number(this.alarm.reading_timestamp_unix)-1800) );
			f.append('ts_to', (Number(this.alarm.reading_timestamp_unix)+1800) );

			//todo: check what these properties do
			f.append('admin',1);
			f.append('type',1);
			f.append('plot',1);

			//cancel existing graph requests if promise exists
			if(this.GraphRequest){
				this.GraphRequest.cancel();
			}

			//Create new cancellable request
			this.GraphRequest = this.axios.CancelToken.source();

			this.axios.post(this.controller('realtime_sensors/graph'),f,{
				cancelToken: this.GraphRequest.token
			}).then(function(response){
				self.rawData = response.data.data;
				self.fillData();

				//reset cancelToken to null
				self.GraphRequest = null;

				//hide the loading spinner
				self.loading=false;
			});
		},

        /*
         * ORGANISE THE RAW DATA READY FOR DYGRAPH
         */
        fillData(){
			if( this.rawData.length > 0 ){
				//used to display the correct range on graph
				var lowest_value = null;
				var highest_value = null;

				this.chartData = new this.GoogleGraphObject.visualization.DataTable();
				
				this.chartData.addColumn('date', 'Date');
				this.chartData.addColumn('number', 'Reading');
				this.chartData.addColumn('number', 'Low Threshold');
				this.chartData.addColumn('number', 'High Threshold');
				
				//is this contact sensor(s)?
				if(this.isContactSensor(this.rawData[0].sensor.sensor_type_id)){
					this.chartOptions.vAxis.ticks=[
						{v: 0, f: this.ContactLabels.reading_0_label},
						{v: 1, f: this.ContactLabels.reading_1_label}
					];
				}else{
					this.chartOptions.vAxis.ticks=null;
				}

				var i = 0;
				var rows = [];

				this.rawData[0].data_clean.forEach(reading => {
					if(reading){
						if(!rows[i]){
							rows[i]=[];
						}

						rows[i][0]=new Date(reading[0]*1000);
						rows[i][1]= +Number(reading[1]).toFixed(2);
						rows[i][2]= +Number(reading[2]).toFixed(2);
						rows[i][3]= +Number(reading[3]).toFixed(2);

						var show_alarm = this.rawData[0].alarm_events.find(event => {
							//returns true if this timestamp is found in the alarm events array
							return event[0] == reading[0];
						});
						
						//this_row[this_row.length]=(show_alarm ? 'A' : null);
						//a location where the alarm icon should be shown
						if(show_alarm){
							this.alarms.push( [rows[i][0],rows[i][1]] );
						}

						//window.console.log(this_row)
						//update the lowest / highest values
						if( lowest_value > rows[i][1] || !lowest_value ){
							lowest_value = rows[i][1];
						}
						if( lowest_value > rows[i][2] ){
							lowest_value =rows[i][2];
						}
						if( highest_value <  rows[i][1] || !highest_value ){
							highest_value = rows[i][1];
						}
						if( highest_value < rows[i][3] ){
							highest_value = rows[i][3];
						}
					}
					i++;
				});

				//create the row
				this.chartData.addRows(rows);

				//set the chart view max and min
				var pad = (highest_value-lowest_value)*0.1;
				this.chartOptions.vAxis.viewWindow.min = (lowest_value-pad).toFixed(2);
				this.chartOptions.vAxis.viewWindow.max = (highest_value+pad).toFixed(2);

				//set the date time format for the tooltip which appears when clicking on a point
				var formatter = new this.GoogleGraphObject.visualization.DateFormat({ 
					pattern: 'dd/MM/YY HH:mm:ss'
				}); 
				formatter.format(this.chartData , 0);
				
				//this.graphData = return_data;
			}
		},
		placeMarkers(){
			//$('.alarm',this.$refs.graphContainer.$el).remove();
			var _this=this;
			
			var cli = _this.ChartObject.getChartLayoutInterface();
			//var chartArea = cli.getChartAreaBoundingBox();

			_this.alarms.forEach(alarm => {
				var y = Math.floor(cli.getYLocation(alarm[1])) - 30 + "px";
				var x = Math.floor(cli.getXLocation(alarm[0])) - 10 + "px";

				//add the alarm div
				if( $('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).length > 0 ){
					$('[data-ref="'+alarm[0]+'-'+alarm[1]+'"]',_this.$refs.graphContainer.$el).stop().animate({'left':x,'top':y},200);
				}else{
					$(_this.$refs.graphContainer.$el).append('<div class="alarm" data-ref="'+alarm[0]+'-'+alarm[1]+'" style="position:absolute;width:20px;height:20px;text-align:center;left:'+x+';top:'+y+';"><img src="assets/misc_icons/annotations_1_0_0_0.png" style="max-width:20px;max-height:20px;"/></div>');
				}
			});
		}

    },
    watch:{
        state(a){
            if( a == 'open' ){
                //graph has been opened so load some data
                this.loading=true;
                this.fetchGraphData();
            }
        }
    }
};
</script>
