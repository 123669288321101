<template>
<div>
	<div class="offcanvas" :class="state">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeCanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Notification Settings</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3">
				<h5 class="my-3">Holiday Status</h5>
				<div class="form-group">
					<label>I am currently</label>
					<select class="form-control" v-model="settingsCopy.on_holiday">
						<option value="0">At Work</option>
						<option value="1">On Holiday</option>
					</select>
				</div>

				<h5 class="my-3">Stale Data Notification Settings</h5>
				<div class="form-group">
					<label>SDR Notifications</label>
					<select class="form-control" v-model="settingsCopy.sdr_notifications">
						<option value="0">Disabled</option>
						<option value="1">Enabled</option>
					</select>
				</div>

				<div v-if="settingsCopy.sdr_notifications == 1">
					<div class="form-group">
						<label>Notification Type</label>
						<select class="form-control" v-model="settingsCopy.sdr_notification_type">
							<option value="1">Snapshot of stale sensors</option>
							<option value="2">On SDR change event</option>
							<option value="3">Snapshot &amp; change event</option>
						</select>
					</div>

					<div v-if="settingsCopy.sdr_notification_type != 2">
						<div class="form-group">
							<label>Frequency</label>
							<select class="form-control" v-model="settingsCopy.sdr_notification_frequency_type">
								<option value="1">Hours</option>
								<option value="2">Daily</option>
								<option value="3">Presets</option>
								<option value="4">Weekly</option>
								<option value="5">Months</option>
							</select>
						</div>

						<!--Hourly-->
						<div v-if="settingsCopy.sdr_notification_frequency_type == 1">
							<div class="form-group">
								<label>Every</label>
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_value">
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="6">6</option>
									<option value="8">8</option>
									<option value="12">12</option>
								</select>
								hour<span v-if="settingsCopy.sdr_notification_frequency_value > 1">s</span>
							</div>
						</div>

						<!--Daily-->
						<div v-if="settingsCopy.sdr_notification_frequency_type == 2">
							<div class="form-group">
								<label>At</label>
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_offset">
									<option value="0">00:00</option><option value="1">01:00</option><option value="2">02:00</option><option value="3">03:00</option><option value="4">04:00</option><option value="5">05:00</option><option value="6">06:00</option><option value="7">07:00</option><option value="8">08:00</option><option value="9">09:00</option><option value="10">10:00</option><option value="11">11:00</option><option value="12">12:00</option><option value="13">13:00</option><option value="14">14:00</option><option value="15">15:00</option><option value="16">16:00</option><option value="17">17:00</option><option value="18">18:00</option><option value="19">19:00</option><option value="20">20:00</option><option value="21">21:00</option><option value="22">22:00</option><option value="23">23:00</option>
								</select>
							</div>
						</div>

						<!--Presets-->
						<div v-if="settingsCopy.sdr_notification_frequency_type == 3">
							<div class="form-group">
								on
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_value">
									<option value="0">Mon, Tue, Wed, Thu, Fri</option><option value="1">Mon, Wed, Fri</option><option value="2">Mon, Fri</option><option value="3">Tue, Thu</option><option value="4">Sat, Sun</option>
								</select>
								at
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_offset">
									<option value="0">00:00</option><option value="1">01:00</option><option value="2">02:00</option><option value="3">03:00</option><option value="4">04:00</option><option value="5">05:00</option><option value="6">06:00</option><option value="7">07:00</option><option value="8">08:00</option><option value="9">09:00</option><option value="10">10:00</option><option value="11">11:00</option><option value="12">12:00</option><option value="13">13:00</option><option value="14">14:00</option><option value="15">15:00</option><option value="16">16:00</option><option value="17">17:00</option><option value="18">18:00</option><option value="19">19:00</option><option value="20">20:00</option><option value="21">21:00</option><option value="22">22:00</option><option value="23">23:00</option>
								</select>
							</div>
						</div>

						<!--Weekly-->
						<div v-if="settingsCopy.sdr_notification_frequency_type == 4">
							<div class="form-group">
								on
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_value">
									<option value="0">Sunday</option><option value="1">Monday</option><option value="2">Tuesday</option><option value="3">Wednesday</option><option value="4">Thursday</option><option value="5">Friday</option><option value="6">Saturday</option>
								</select>
								at
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_offset">
									<option value="0">00:00</option><option value="1">01:00</option><option value="2">02:00</option><option value="3">03:00</option><option value="4">04:00</option><option value="5">05:00</option><option value="6">06:00</option><option value="7">07:00</option><option value="8">08:00</option><option value="9">09:00</option><option value="10">10:00</option><option value="11">11:00</option><option value="12">12:00</option><option value="13">13:00</option><option value="14">14:00</option><option value="15">15:00</option><option value="16">16:00</option><option value="17">17:00</option><option value="18">18:00</option><option value="19">19:00</option><option value="20">20:00</option><option value="21">21:00</option><option value="22">22:00</option><option value="23">23:00</option>
								</select>
							</div>
						</div>

						<!--Monthly-->
						<div v-if="settingsCopy.sdr_notification_frequency_type == 5">
							<div class="form-group">
								every
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_value">
									<option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="6">6</option>
								</select>
								month<span v-if="settingsCopy.sdr_notification_frequency_value > 1">s</span><br/>
								1<sup>st</sup> of the month at
								<select class="mx-2 form-control d-inline-block" style="width:auto;" v-model="settingsCopy.sdr_notification_frequency_offset">
									<option value="0">00:00</option><option value="1">01:00</option><option value="2">02:00</option><option value="3">03:00</option><option value="4">04:00</option><option value="5">05:00</option><option value="6">06:00</option><option value="7">07:00</option><option value="8">08:00</option><option value="9">09:00</option><option value="10">10:00</option><option value="11">11:00</option><option value="12">12:00</option><option value="13">13:00</option><option value="14">14:00</option><option value="15">15:00</option><option value="16">16:00</option><option value="17">17:00</option><option value="18">18:00</option><option value="19">19:00</option><option value="20">20:00</option><option value="21">21:00</option><option value="22">22:00</option><option value="23">23:00</option>
								</select>
							</div>
						</div>

					</div>

				</div>

			</div>

			<div class="offcanvas-footer" v-if="hasChanged">
				<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(save)" :disabled="isSaving||!allowChanges">
					<span v-if="!isSaving">Save Changes</span>
					<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
				</button>
			</div>
		</div>
	</div>

	<alert v-if="showResponse && state == 'open'">
		<div slot="title">Important</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="closeAlertFunc()">Ok</button>
	</alert>

	<unsaved-changes :show="showWarning" v-on:hide="showWarning=false" v-on:close="forceCloseCanvas" v-on:save="$refs.auth.check(save)"/>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import Alert from '../Alert.vue';
import UnsavedChanges from '../UnsavedChangesAlert.vue';
export default{
	components:{
		Alert,
		UnsavedChanges
	},
	props:['state'],
	data(){
		return {
			temp:'1',
			settings:{},
			settingsCopy:{},
			showResponse:false,
			responseMessage:'',
			showWarning:false,
			closeAlertFunc:()=>{this.showResponse=false;},
			SaveRequest: null,
			allowChanges: false
		}
	},
	computed:{
		hasChanged(){
			return !this._.isEqual(this.settings,this.settingsCopy);
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		}
	},
	methods:{
		fetchSettings(){
			var self = this;
			this.axios.get( this.controller('auth/notifications') ).then(res=>{
				self.settings = res.data.data;
			});
		},
		closeCanvas(){
			if( this.hasChanged ){
				this.showWarning = true;
			}else{
				this.$emit('close');
			}
		},
		forceCloseCanvas(){
			this.showWarning = false;
			this.$emit('close');
		},
		save(){
			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			var f = new FormData();
			for( var key in this.settingsCopy ){
				f.append(key, this.settingsCopy[key]);
			}

			var self = this;
			this.showWarning=false;

			this.axios.post( this.controller('auth/save_notifications') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				if( res.data.message != '' ){
					self.closeAlertFunc=()=>{
						this.showResponse=false;
						this.$emit('reload');
						this.forceCloseCanvas();
					}
					self.responseMessage = res.data.message;
					self.showResponse = true;
				}else{
					self.$emit('reload');
					self.forceCloseCanvas();
				}

				self.SaveRequest = null;
			});
		}
	},
	watch:{
		state(a){
			if( a == 'open' ){
				this.fetchSettings();
			}
		},
		settings:{
			handler(){
				this.settingsCopy = this._.cloneDeep(this.settings);
			},
			deep: true
		}
	}
}
</script>
