export default class Phone_validation{

    constructor(number, icc, vue){
        this.valid=false;
        this.errors_=[];
        this.suggestion='';
        this.icc_config = vue.$store.state.user.company.icc_config;
        this.number = number;
        this.icc = icc;
        this.icc_detected_='';
        
        this.run_validation();
    }

    error(msg){
        if(msg){
            this.errors_.push(msg);
        }
    }

    validate(){
        return this.valid;
    }

    run_validation(){
        //is there anything to validate
        if(!this.number){
            this.valid = true;
            return true;
        }

        //is numeric?
        if(!this.isNum(this.number)){
            this.error('Number contains non-numeric characters')
        }

        //is the number long enough?
        if(this.number && this.number.toString().length < 5){
            this.error('Number too short');
        }
        
        //store the first char of the number
        // var first_char = this.number.toString().charAt(0);
        
        //generate suggestion here
        var suggestion = this.number.toString();
        var first_char = '';
        //loop through possible icc to find if this number starts with one!
        this.icc_config.icc_codes.forEach(code=>{
            var code_length = code.toString().length;
            if( this.number.toString().substr(0,code_length) == code ){
                //code found
                if(parseInt(this.icc_config.company_icc_enabled) == 1){
                    this.icc_detected_ = code;
                }
                suggestion = suggestion.substr(code_length);
                first_char = '+';
            }else if( this.number.toString().substr(0,2) == '00' ){
                var test_number = '+'+this.number.toString().substr(2);
                if( test_number.substr(0,code_length) == code ){
                    if(parseInt(this.icc_config.company_icc_enabled) == 1){
                        this.icc_detected_ = code;
                    }
                    suggestion = test_number.substr(code_length);
                    first_char = '0';
                }
            }
        });

        //is icc enabled for the company
        if(parseInt(this.icc_config.company_icc_enabled) == 1){
            if( !this.icc || this.icc == '' ){
                this.error('ICC is required')
            }
            //adjust the suggestion
            if( suggestion.substr(0,1) == '0' ){
                //remove any leading zero found after the code
                suggestion = Number(suggestion);
                first_char = '0';
            }
            this.suggestion=suggestion;
            
            if( first_char == '+' ){
                this.error(`Incorrect format detected, did you mean: ${suggestion}?`);
                this.error(`Phone and SMS numbers should not start with ${first_char} and be at least 5 digits long`);
            }
            if( first_char == '0' ){
                this.error(`Incorrect format detected, did you mean: ${suggestion}?`);
                this.error(`Phone and SMS numbers should not start with ${first_char} and be at least 5 digits long`);
            }
        }else{
            //adjust the suggestion
            if( suggestion.substr(0,1) != '0' ){
                //remove any leading zero found after the code
                suggestion = '0'+suggestion;
            }
            if( suggestion.substr(0,2) == '00' ){
                //remove all but one leading zero
                suggestion = '0'+Number(suggestion);
            }
            this.suggestion=suggestion;

            if( this.number.substr(0,1) != '0' ){
                //remove any leading zero found after the code
                this.error(`Incorrect format detected, did you mean: ${suggestion}?`);
                this.error('Phone and SMS numbers should start with 0 and be at least 5 digits long');
            }
            if( this.number.substr(0,2) == '00' ){
                //remove all but one leading zero
                this.error(`Incorrect format detected, did you mean: ${suggestion}?`);
                this.error('Phone and SMS numbers should start with 0 and be at least 5 digits long');
            }

        }
        if(this.errors_ && this.errors_.length == 0){
            this.valid = true;
            return true;
        }else{
            this.valid = false;
            return false;
        }
    }

    errors(){
        return this.errors_;
    }

    isNum(number){
        return /^\d+$/.test(number);
    }
}
