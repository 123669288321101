
<template>
<div class="offcanvas" :class="{open:!_.isEmpty(sensorCopy)}">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Edit Sensor {{sensorCopy.id}}</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<div v-for="(entity,i) in sensorCopy.entities" :key="i">
				<div v-if="i == 0" class="mb-4">
					<form-input v-model="entity.location_name" label="Location" :error="getValidationErrors(entity.id,'location_name')" :not-saved="getNotSaved(entity.id,'location_name')"/>
					<div class="form-group">
						<label>Alarm Group</label>
						<select class="form-control" v-model="entity.alarm_group_id">
							<option value="0">Non-Alarmed</option>
							<option v-for="group in alarmGroups" :key="group.id" :value="group.id">{{group.name}}</option>
						</select>
					</div>	
				</div>
				<template v-if="sensorCopy.entities.length > 1">
					<h6 class="mt-2">{{entity.id}} {{entity.public_type_name}}</h6>
					<hr/>
				</template>

				<div class="row" v-if="!_isContactSensor(entity)">
					<div class="col">
						<form-input type="number" v-model="entity.threshold_low" :label="'Low '+entity.threshold_unit" :error="getValidationErrors(entity.id,'threshold_low')" :not-saved="getNotSaved(entity.id,'threshold_low')"/>
					</div>
					<div class="col">
						<form-input type="number" v-model="entity.threshold_high" :label="'High '+entity.threshold_unit" :error="getValidationErrors(entity.id,'threshold_high')" :not-saved="getNotSaved(entity.id,'threshold_high')"/>
					</div>
				</div>

				<div class="row" v-if="_isContactSensor(entity) && sensorCopy.entities.length == 1">
						<div class="form-group col">
							<label>Alarm On</label>
							<select class="form-control" @input="setThresholds">
								<option value="-1" :selected="alarm_on(entity) == -1">None</option>
								<option value="0" :selected="alarm_on(entity) === 0">{{contact_label_options.reading_0_label}}</option>
								<option value="1" :selected="alarm_on(entity) == 1">{{contact_label_options.reading_1_label}}</option>
							</select>
						</div> 
					</div>

				<div class="row">
					<div class="col">
						<form-input type="number" v-model="entity.alarm_delay" :label="'Alarm Delay<br/><small>(0 - '+Number(entity.max_delay)/60+' mins)</small>'" :error="getValidationErrors(entity.id,'alarm_delay')" :not-saved="getNotSaved(entity.id,'alarm_delay')"/>
					</div>
					<div class="col">
						<div class="form-group">
							<label>Log Interval<span v-if="getNotSaved(entity.id,'log_interval')" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span><br/><small>(1 - 15 mins)</small></label>
							<select class="form-control" v-model="entity.log_interval">
								<option v-for="i in 15" :value="i.toString()" :key="i">{{i}} min{{(i>1?'s':'')}}</option>
							</select>
							<div class="error" v-if="getValidationErrors(entity.id,'log_interval')">{{getValidationErrors(entity.id,'log_interval')}}</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>
	</div>

	<div class="offcanvas-footer" v-if="hasChanged">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(save)" :disabled="isSaving||!allowChanges">
			<span v-if="!isSaving">Save Changes</span>
			<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
		</button>
	</div>

	<unsaved-changes-alert :show="showWarning" v-on:close="forceClose" v-on:hide="showWarning=false" v-on:save="()=>{showWarning=false;$refs.auth.check(save);}"/>

	<alert v-if="showResponse && !_.isEmpty(sensorCopy)">
		<div slot="title">Important</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
	</alert>

	<password-modal ref="auth" v-if="!_.isEmpty(sensorCopy)" v-on:loaded="allowChanges=true" />
</div>
</template>

<script>
import UnsavedChangesAlert from '../../UnsavedChangesAlert.vue';
import FormInput from '../../FormElements/Input.vue';
import Alert from '../../Alert.vue';

export default{
	props:['sensor','alarmGroups','contact_labels'],
	components:{
		UnsavedChangesAlert,
		FormInput,
		Alert
	},
	data(){
		return {
			sensorCopy:{},
			showWarning:false,
			validationErrors:{},
			notSaved:[],
			responseMessage:'',
			showResponse:false,
			SaveRequest: null,
			allowChanges: false
		}
	},
	computed:{
		hasChanged(){
			return !this._.isEqual(this.sensor,this.sensorCopy);
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		},
		contact_label_options(){
			var self=this;
			var labels_ = this.contact_labels.filter(label => {
				return Number(label.id) == Number(self.sensorCopy.contact_sensor_label_id);
			})[0];

			if(labels_){
				return labels_;
			}else{
				return {
					reading_0_label: 'Reading 0',
					reading_1_label: 'Reading 1',
				};
			}
		}
	},
	methods:{
		_isContactSensor(entity){
			return this.isContactSensor(entity.sensor_type_id);
		},
		getValidationErrors(entity_id,field){
			if(this.validationErrors[entity_id] && this.validationErrors[entity_id][field]){
				return this.validationErrors[entity_id][field];
			}else{
				return '';
			}
		},
		getNotSaved(entity_id,field){
			if(this.notSaved[entity_id] && this.notSaved[entity_id].indexOf(field) > -1){
				return true;
			}else{
				return false;
			}
		},
		alarm_on(entity){
			if(this._isContactSensor(entity)){
				if( Number(entity.threshold_high) == 1 && Number(entity.threshold_low) == 1 ){
					return 0;
				}else if( Number(entity.threshold_high) == 0 && Number(entity.threshold_low) == 0 ){
					return 1;
				}else{
					return -1;
				}
			}else{
				return false;
			}
		},
		setThresholds(e){
			if(Number(e.target.value) == -1){
				this.sensorCopy.entities[0].threshold_low="0";
				this.sensorCopy.entities[0].threshold_high="1";
			}else if(Number(e.target.value) === 0){
				this.sensorCopy.entities[0].threshold_low="1";
				this.sensorCopy.entities[0].threshold_high="1";
			}else if(Number(e.target.value) == 1){
				this.sensorCopy.entities[0].threshold_low="0";
				this.sensorCopy.entities[0].threshold_high="0";
			}
		},
		validateForm(){
			if( this._.isEmpty(this.sensorCopy) ){
				return true;
			}
			
			this.validationErrors={};
			var errors = 0;
			var e = this.validationErrors;
		
			for(const i in this.sensorCopy.entities){
				var s = this.sensorCopy.entities[i];
				
				//create empty object for this entity
				e[s.id]={};

				//validate inputs for this sensor
				if( i == 0 ){
					// only validate these ones the first time around.
					//location name
					if( s.location_name.length > 255 ){
						e[s.id].location_name = 'Too long';
						errors++;
					}
				}

				//Threshold Low
				if(s.range_max !== null){
					if( !this._.isFinite( Number(s.threshold_low) )){
						e[s.id].threshold_low = 'Not a number';
						errors++;
					}else if( Number(s.threshold_low) < Number(s.range_min) ){
						e[s.id].threshold_low = 'Value too low';
						errors++;
					}else if( Number(s.threshold_low) > Number(s.range_max) || Number(s.threshold_low) > Number(s.threshold_high) ){
						e[s.id].threshold_low = 'Value too high';
						errors++;
					}
				}else{
					if( !this._.isFinite( Number(s.threshold_low) )){
						e[s.id].threshold_low = 'Not a number';
						errors++;
					}else if( Number(s.threshold_low) < Number(s.range_min) ){
						e[s.id].threshold_low = 'Value too low';
						errors++;
					}else if( Number(s.threshold_low) > Number(s.threshold_high) ){
						e[s.id].threshold_low = 'Value too high';
						errors++;
					}
				}

				//Threshold High
				if(s.range_max !== null){
					if( !this._.isFinite( Number(s.threshold_high) )){
						e[s.id].threshold_high = 'Not a number';
						errors++;
					}else if( Number(s.threshold_high) < Number(s.range_min) ){
						e[s.id].threshold_high = 'Value too low';
						errors++;
					}else if( Number(s.threshold_high) > Number(s.range_max) ){
						e[s.id].threshold_high = 'Value too high';
						errors++;
					}else if( Number(s.threshold_high) < Number(s.threshold_low) ){
						e[s.id].threshold_high = 'Value too low';
						errors++;
					}
				}else{
					if( !this._.isFinite( Number(s.threshold_high) )){
						e[s.id].threshold_high = 'Not a number';
						errors++;
					}else if( Number(s.threshold_high) < Number(s.range_min) ){
						e[s.id].threshold_high = 'Value too low';
						errors++;
					}else if( Number(s.threshold_high) < Number(s.threshold_low) ){
						e[s.id].threshold_high = 'Value too low';
						errors++;
					}
				}

				//Alarm Delay
				if( !this._.isFinite( Number(s.alarm_delay) ) ){
					e[s.id].alarm_delay = 'Not a number';
					errors++;
				}else if( Number(s.alarm_delay) < 0 ){
					e[s.id].alarm_delay = 'Must be zero or greater';
					errors++;
				}else if( Number(s.alarm_delay)*60 > Number(s.max_delay) ){
					e[s.id].alarm_delay = 'Must be '+(s.max_delay/60)+' or less';
					errors++;
				}

				//Log Interval
				if( !this._.isFinite( Number(s.log_interval) ) ){
					e[s.id].log_interval = 'Not a number';
					errors++;
				}else if( Number(s.log_interval) <= 0 ){
					e[s.id].log_interval = 'Must be greater than zero';
					errors++;
				}else if( Number(s.log_interval) > 15 ){
					e[s.id].log_interval = 'Must be less than 16';
					errors++;
				}

			}

			this.allowChanges = (errors == 0 ? true : false);
		},
		closeOffcanvas(){
			if( this.hasChanged ){
				this.showWarning = true;
			}else{
				this.forceClose();
			}
		},
		forceClose(){
			this.showWarning=false;
			this.sensorCopy={};
			this.notSaved=[];

			//reload sensor data
			this.$emit('reload');

			this.$emit('close');
		},
		save(){
			if( this.SaveRequest ){
				this.SaveRequest.cancel();
			}
			this.SaveRequest = this.axios.CancelToken.source();

			var f = new FormData();
			for( const sensor in this.sensorCopy.entities ){
				for( const entity in this.sensorCopy.entities[sensor] ){
					f.append(`${sensor}[${entity}]`, this.sensorCopy.entities[sensor][entity]);
				}
			}
			var self = this;

			this.axios.post( this.controller('sensor_thresholds/save_sensor_settings') ,f,{
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				self.notSaved=res.data.not_saved;
				if( res.data.message != '' ){
					//set message and display alert
					self.responseMessage = res.data.message;
					self.showResponse = true;
				}else{
					self.sensorCopy = self._.cloneDeep(self.sensor);
					self.closeOffcanvas();
				}

				self.SaveRequest = null;
			})
		}
	},
	watch:{
		sensor(){
			this.validationErrors={};
			this.sensorCopy = this._.cloneDeep(this.sensor);
		},
		sensorCopy:{
			handler(){
				this.validateForm();
			},
			deep:true
		}
	}
}
</script>
