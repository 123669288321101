<template>
<div>

	<div class="container-fluid py-3">
		<search v-model="search"/>
	</div>

	<loading :state="loading"/>

	<div class="container-fluid py-3 bg-white">
		<h4>Location</h4>
		<a :href="'https://www.google.com/maps/place/'+encodeURI(contact.location)" target="MAP">{{contact.location}}</a>
	</div>

	<div v-for="group in contact.groups" :key="group.id">
		<h6 class="my-3 container-fluid">{{group.group_name}}</h6>
		<ul class="list-group">
			<li class="list-group-item" v-for="contact in contacts(group)" :key="contact.id" :class="{active: contact.user_id==selected.user_id}" @click="()=>{selected=(contact.user_id!=selected.user_id?contact:{})}">
				<font-awesome-icon icon="user" fixed-width /> {{contact.user_name}}
				<small v-if="contact.job_description"><br/>{{contact.job_description}}</small>
			</li>
		</ul>
	</div>


	<offcanvas-bottom :selected="selected"></offcanvas-bottom>
</div>
</template>

<script>
import OffcanvasBottom from '../../components/SuperAdmin/ContactDash/OffcanvasBottom.vue';
import Loading from '../../components/Loading.vue';
import Search from '../../components/FormElements/Search.vue';

export default{
	components:{
		OffcanvasBottom,
		Loading,
		Search
	},
	data(){
		return {
			contact:{},
			selected:{},
			loading:false,
			search:''
		}
	},
	methods:{
		contacts(group){
			return this._.pickBy(group,(value,key)=>{
				if(this._.isFinite(Number(key))){
					//if search value is set test against name
					if( this.search ){
						if( value.user_name.toLowerCase().indexOf(this.search) > -1 ){
							return true;
						}else{
							return false;
						}
					}else{
						return true;
					}
					
				}else{
					return false;
				}
			});
		},
		getData(){
			var self = this;
			this.loading = true;
			this.axios.get( this.controller('super_admin/contact_details') ).then(res=>{
				self.contact = res.data.data;
				self.loading = false;
			});
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Super Admin','/super-admin'],
			['Contact Dash','/super-admin/contact-dash'],
		]);

		this.getData();
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/_vars.scss';
.list-group-item{
	&.active{
		background:$green;
		border:1px solid darken($green,5);
	}
}
</style>
