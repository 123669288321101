<template>
	<div class="offcanvas" :class="{open: !_.isEmpty(user)}">
		<nav class="navbar navbar-expand-sm navbar-light bg-light">
			<div class="w-100 d-flex align-items-center">
				<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
				<div class="offcanvas-title">Edit User - {{user.first_name}} {{user.last_name}}</div>
			</div>
		</nav>
		<div class="offcanvas-body">
			<div class="container-fluid py-3 pb-5" style="margin-bottom:100px;" v-if="!_.isEmpty(userCopy)">
					<div class="row text-center">
						<div class="form-group col">
							<label>On Holiday<span v-if="fieldsNotSaved.indexOf('on_holiday') > -1" class="ml-1 text-danger" style="font-size:0.8em;"><br/>(Not Saved)</span></label>
							<switch-button v-model="userCopy.on_holiday" :error="validationErrors.on_holiday"/>
						</div>
						<div class="form-group col">
							<label>SDR Alerts<span v-if="fieldsNotSaved.indexOf('sdr_notifications') > -1" class="ml-1 text-danger" style="font-size:0.8em;"><br/>(Not Saved)</span></label>
							<switch-button v-model="userCopy.sdr_notifications" :error="validationErrors.sdr_notifications"/>
						</div>
						<!--<div class="form-group col">
							<label>SDR Alarm<span v-if="fieldsNotSaved.indexOf('sdr_alarm') > -1" class="ml-1 text-danger" style="font-size:0.8em;"><br/>(Not Saved)</span></label>
							<switch-button v-model="userCopy.sdr_alarm" :center="true" :error="validationErrors.sdr_alarm"/>
						</div>-->
					</div>
					<text-input v-model="userCopy.first_name" label="First Name" :error="validationErrors.first_name" :not-saved="fieldsNotSaved.indexOf('first_name') > -1"/>
					<text-input v-model="userCopy.last_name" label="Last Name" :error="validationErrors.last_name" :not-saved="fieldsNotSaved.indexOf('last_name') > -1"/>
					<text-input v-model="userCopy.email" label="Login Email" :error="validationErrors.email" :not-saved="fieldsNotSaved.indexOf('email') > -1"/>
					<text-input v-model="userCopy.alarming_email" label="Alarming Email" :error="validationErrors.alarming_email" :not-saved="fieldsNotSaved.indexOf('alarming_email') > -1"/>
					<div class="row">
						<div class="col-4" v-if="!_.isEmpty(iccCodes)">
							<div class="form-group">
								<label>ICC <span v-if="fieldsNotSaved.indexOf('icc_phone') > -1" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span></label>
								<select v-model="userCopy.icc_phone" class="form-control">
									<option value="">None</option>
									<option v-for="icc in iccCodes" :value="icc" :key="icc">{{icc}}</option>
								</select>
							</div>
						</div>
						<div :class="{'col-8':!_.isEmpty(iccCodes),'col-12':_.isEmpty(iccCodes)}">
							<text-input v-model="userCopy.phone" type="tel" label="Phone (voice)" :not-saved="fieldsNotSaved.indexOf('phone') > -1"/>
						</div>
					</div>
                    <div class="row mb-3 mt-0 text-danger phone_validation_error" v-if="!phoneValidation.valid">
                        <div class="col-12">
                            <div class="mb-0" v-for="(error, index) in phoneValidation.errors_" :key="index">{{error}}</div>
                        </div>
                    </div>
					<div class="row">
						<div class="col-4" v-if="!_.isEmpty(iccCodes)">
							<div class="form-group">
								<label>ICC <span v-if="fieldsNotSaved.indexOf('icc_sms') > -1" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span></label>
								<select v-model="userCopy.icc_sms" class="form-control">
									<option value="">None</option>
									<option v-for="icc in iccCodes" :value="icc" :key="icc">{{icc}}</option>
								</select>
							</div>
						</div>
						<div :class="{'col-8':!_.isEmpty(iccCodes),'col-12':_.isEmpty(iccCodes)}">
							<text-input v-model="userCopy.sms" type="tel" label="SMS" :not-saved="fieldsNotSaved.indexOf('sms') > -1"/>
						</div>
					</div>
                    <div class="row mb-3 mt-0 text-danger phone_validation_error" v-if="!smsValidation.valid">
                        <div class="col-12">
                            <div class="mb-0" v-for="(error, index) in smsValidation.errors_" :key="index">{{error}}</div>
                        </div>
                    </div>
					
					<select-input v-model="userCopy.user_groups_id" v-if="userCopy.user_groups_id != '3' && userCopy.user_groups_id != '7'" :options="filtered_groups" label="Permissions" :error="validationErrors.user_groups_id" :not-saved="fieldsNotSaved.indexOf('user_groups_id') > -1"/>

					<button class="btn btn-block btn-secondary" @click="showConfirmDeactivate=true" :disabled="userCopy.id==$store.state.user.id">De-activate User</button>
			</div>

		</div>

		<div class="offcanvas-footer" v-if="hasChanged && _.isEmpty(validationErrors)">
			<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)">Save Changes</button>
		</div>

		<unsaved-changes :show="showUnsavedChanges && !_.isEmpty(user)" v-on:close="forceCloseOffcanvas" v-on:hide="showUnsavedChanges=false" v-on:save="$refs.auth.check(saveChanges)"/>

		<alert v-if="showResponse && !_.isEmpty(user)">
			<div slot="title">Important</div>
			<div v-html="responseMessage"></div>
			<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
		</alert>

		<alert v-if="showHolidayWarning && !_.isEmpty(user)">
			<div slot="title"><font-awesome-icon icon="exclamation-triangle" class="float-right text-danger"/> Warning</div>
			Enabling the "On Holiday" switch will stop this user receiving alarms.
			<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showHolidayWarning=false">Ok</button>
		</alert>
		
		<alert v-if="showConfirmDeactivate">
			<div slot="title">Confirm</div>
			Are you sure you want to de-activate this user?
			<div slot="buttons" class="mb-2 row">
				<div class="col">
					<button slot="buttons" class="btn btn-block btn-default" @click="showConfirmDeactivate=false">Cancel</button>
				</div>
				<div class="col">
					<button slot="buttons" class="btn btn-block btn-default" @click="deactivateUser">Confirm</button>
				</div>
			</div>
		</alert>

		<password-modal ref="auth" v-if="!_.isEmpty(user)"/>
	</div>
</template>

<script>
import Alert from '../../Alert.vue';
import TextInput from '../../FormElements/Input.vue';
import SelectInput from '../../FormElements/Select.vue';
import SwitchButton from '../../FormElements/Switch.vue';
import UnsavedChanges from '../../UnsavedChangesAlert.vue';

export default{
	components:{
		TextInput,
		SelectInput,
		Alert,
		SwitchButton,
		UnsavedChanges
	},
	props:['user','groups','iccCodes'],
	data(){
		return {
			showPasswordModal: false,
			userCopy: {},
			responseMessage: '',
			showResponse: false,
			showUnsavedChanges: false,
			showHolidayWarning: false,
			validationErrors: {},
			fieldsNotSaved: [],
			showConfirmDeactivate: ''
		}
	},
	methods:{
		saveChanges(){
			var f = new FormData();
			for( var key in this.userCopy ){
				f.append(key, 
					(this.userCopy[key]!=null?this.userCopy[key]:'')
				);
			}

			var self = this;
			this.showUnsavedChanges=false;

			this.axios.post(this.controller('auth/save_user'), f).then(function(response){

				//check for response message
				if( response.data.message != '' ){
					self.responseMessage = response.data.message;
					//show the response
					self.showResponse = true;
				}else{
					self.$emit('unselect-user');
				}

				if(response.data.not_saved.length > 0){
					self.fieldsNotSaved = response.data.not_saved;
				}

				if( response.data.status == "success" ){
					self.$emit('reload');
				}
			});

		},
		closeOffcanvas(){
			if( this.hasChanged ){
				this.showUnsavedChanges = true;
			}else{
				this.$emit('unselect-user');
				this.userCopy = {};
			}
		},
		forceCloseOffcanvas(){
			this.showUnsavedChanges = false;
			this.$emit('unselect-user');
			this.userCopy = {};
		},
		validateForm(){
			//clear the array
			this.validationErrors = {};

			//first name validation
			if(this.userCopy.first_name == ''){
				this.validationErrors.first_name = 'Cannot be empty';
			}

			//last name
			if(this.userCopy.last_name == ''){
				this.validationErrors.last_name = 'Cannot be empty';
			}

			//login email
			if(!this.userCopy.email){
				this.validationErrors.email = 'Cannot be empty';
			}else if(!this.isValidEmail(this.userCopy.email)){
				this.validationErrors.email = 'Invalid address';
			}

			//alarming email
			if(this.userCopy.alarming_email && !this.isValidEmail(this.userCopy.alarming_email)){
				this.validationErrors.alarming_email = 'Invalid address';
			}

			////sms prefix
			//var icc_test = /^(\+[0-9]{2,3})$/;
			//if(this.userCopy.icc_sms && !icc_test.test(this.userCopy.icc_sms)){
			//	this.validationErrors.icc_sms = 'format as +123';
			//}
			////phone prefix
			//if(this.userCopy.icc_phone && !icc_test.test(this.userCopy.icc_phone)){
			//	this.validationErrors.icc_phone = 'format as +123';
			//}

			////sms number
			//var phone_test = /^(0[0-9]{10})$/;
			//var phone_with_icc_test = /^(\+[0-9]{1,3})(0[0-9]{10})$/;
			//if(this.userCopy.sms && !phone_test.test(this.userCopy.sms) && !phone_with_icc_test.test(this.userCopy.sms)){
			//	this.validationErrors.sms = '11 numbers required<br/>(optional icc code e.g. +44)';
			//}
			////phone number
			//if(this.userCopy.phone && !phone_test.test(this.userCopy.phone) && !phone_with_icc_test.test(this.userCopy.phone)){
			//	this.validationErrors.phone = '11 numbers required<br/>(optional icc code e.g. +44)';
			//}

            if(!this.phoneValidation.valid){
                this.validationErrors.phone = 'invalid';
            }
            if(!this.smsValidation.valid){
                this.validationErrors.sms = 'invalid';
            }

			return false;
		},
		deactivateUser(){
			var self = this;
			var f = new FormData();
			f.append('user_id',this.user.id);
			
			this.axios.post( this.controller('settings/deactivate_user'), f ).then(res=>{
				self.showConfirmDeactivate = '';
				self.responseMessage = res.data.msg;
				self.showResponse = true;
				
				if( res.data.status == 'success' ){
					//reload the users
					self.$emit('reload');
				}
			});
		}
	},
	watch:{
		user( user ){
			if( !this._.isEmpty(user) ){

				//clear variables
				this.validationErrors={};
				this.fieldsNotSaved=[];

				this.userCopy = this._.cloneDeep(user);
			}
		},
		userCopy:{
				handler(a){
					if(a.on_holiday == 1 && (this.user.on_holiday == 0 || typeof this.user.on_holiday == "undefined")){
						this.showHolidayWarning = true;
					}

					this.validateForm();
				},
				deep:true
		}
	},
	computed:{
		hasChanged(){
			return !this._.isEqual(this.user,this.userCopy);
		},
        phoneValidation(){
            return new this.Phone_validation(this.userCopy.phone,this.userCopy.icc_phone, this)
        },
        smsValidation(){
            return new this.Phone_validation(this.userCopy.sms,this.userCopy.icc_sms, this)
        },
        filtered_groups(){
            //filter out company admin form the options
            return this.groups.filter(group=>{
                return parseInt(group.value) != 3
            });
        }
	}
}
</script>
