<template>
	<div class="form-group">
		<label v-if="label" :class="{'text-secondary':disabled}"><font-awesome-icon v-if="labelIcon" :icon="labelIcon" class="mr-1" />
		<span v-html="label"></span>
		<span v-if="notSaved" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span>
		</label>
		<input :type="(type ? type : 'text')" class="form-control" ref="input_value" :value="value" @input="set" :placeholder="placeholder" :disabled="disabled" :autocomplete="autocomplete ? autocomplete : 'off'" v-on:blur="$emit('blur')"/>
		<div class="error" v-if="error != '' && typeof error != 'undefined'" v-html="error"></div>
	</div>
</template>

<script>
export default{
	props:[
		'value',
		'placeholder',
		'label',
		'labelIcon',
		'error',
		'notSaved',
		'type',
		'disabled',
		'autocomplete'
	],
	methods:{
		set(){
			this.$emit('input', this.$refs.input_value.value);
			this.$emit('change', this.$refs.input_value.value);
		}
	}
}
</script>
