<template>
<div>
	<list :sensors="sensors" :selected-id="selectedSensorId" :contact_labels="contact_labels" v-on:select="setSelected" :loading="loading"/>
	<edit-thresholds :sensor="selectedSensor" :alarm-groups="alarmGroups" :contact_labels="contact_labels" v-on:close="()=>{selectedSensor={};selectedSensorId=''}" v-on:reload="getSensors"/>
</div>
</template>

<script>
import List from '../../components/Settings/SensorThresholds/List.vue';
import EditThresholds from '../../components/Settings/SensorThresholds/EditThresholds.vue';

export default{
	components:{
		List,
		EditThresholds
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Settings','/settings'],
			['Sensor Settings','/settings/sensor-settings']
		]);
		
		//get sensors
		this.getSensors();
	},
	data(){
		return {
			sensors:[],
			alarmGroups:[],
			contact_labels:[],
			selectedSensor:{},
			selectedSensorId:'',
			loading:false
		}
	},
	methods:{
		getSensors(){
			var self = this;
			this.loading = true;
			this.axios.get( this.controller('sensor_thresholds') ).then(res=>{
				self.sensors = res.data.data.sensors;
				self.alarmGroups = res.data.data.groups;
				self.contact_labels = res.data.data.contact_labels;
				self.loading = false;
			});
		},
		setSelected(s){
			this.selectedSensor = s;
			this.selectedSensorId = s.id;
		}
	}
}
</script>
