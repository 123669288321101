<template>
	<div class="container-fluid text-center block_links">
		<div class="row">
			<router-link to="/settings/users" class="col py-5">
				<h6>Users</h6>
				<h4 class="my-0"><font-awesome-icon icon="users" /></h4>
			</router-link>
			<router-link to="/settings/sensor-settings" class="col py-5">
				<h6>Sensor Settings</h6>
				<h4 class="my-0"><font-awesome-icon icon="sliders-h" :rotation="Number(90)" /></h4>
			</router-link>
		</div>

		<div class="row">
			<router-link to="/settings/alarm-groups" class="col py-5">
				<h6>Alarm Groups</h6>
				<h4 class="my-0"><font-awesome-icon icon="bell" /></h4>
			</router-link>
			<router-link to="/settings/sdr-groups" class="col py-5">
				<h6>SDR Groups</h6>
				<h4 class="my-0"><font-awesome-icon icon="clock"/></h4>
			</router-link>
		</div>

		<div class="row">
			<router-link to="/settings/document-center" class="col py-5">
				<h6>Document Center</h6>
				<h4 class="my-0"><font-awesome-icon icon="file-pdf" /></h4>
			</router-link>
			<router-link to="/settings/passwords" class="col py-5">
				<h6>Passwords</h6>
				<h4 class="my-0"><font-awesome-icon icon="lock" /></h4>
			</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.row{
	.col{
		background:#fff;
		border:1px solid #ddd;
	}
	a{
		color:#000;
	}
}
</style>

<script>
export default{
	mounted(){
		this.$emit('breadcrumb',[
			['Settings','/settings']
		]);
	}
}
</script>
