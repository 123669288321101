<template>
	<div :class="{'form-group':label}">
		<label v-if="label">{{label}}<span v-if="notSaved" class="ml-1 text-danger" style="font-size:0.8em;">(Not Saved)</span></label>
		<multiselect :options="options" v-on:select="changed" :searchable="(searchable!==false?true:false)" :value="getValue" :show-labels="false" :custom-label="({label,value})=>{return label;}">
			<template slot="option" slot-scope="props">
				{{ props.option.label }}
			</template>
		</multiselect>
		<div class="error" v-if="error != '' && typeof error != 'undefined'">{{error}}</div>
	</div>
</template>

<script>
export default{
	props:['value','label','options','clearable','error','notSaved','searchable'],
	methods:{
		changed(e){
			if( typeof e != "undefined" && e.value != this.value ){
				this.$emit('input', e.value);
				//this.$emit('input', this.selectValue);
				this.$emit('change', e);
			}
		}
	},
	computed:{
		getValue(){
			if( /*this.value && */typeof this.value != "undefined" && this.value !== null ){
				var self = this;
				var selected = this.options.filter(option=>{
					return (option.value == self.value ? true : false);
				});
				return selected[0];
			}else{
				return {label:'none',value:null};
			}
		}
	}
}
</script>

<style lang="scss" scoped>
#v-select .active a {
		background: rgba(50,50,50,.1);
		color: #333;
}

#v-select .dropdown-toggle{
	background:#fff;
}

#v-select.dropdown .highlight a,
#v-select.dropdown li:hover a {
		background: #fff;
		color: #222;
}
</style>