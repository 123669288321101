<template>
<alert v-if="show">
	<div slot="title">Unsaved Changes</div>
	{{message}}
	<div slot="buttons">
		<button class="btn btn-block btn-success" @click="$emit('save')" :disabled="saveDisabled">Save Changes</button>
		<button class="btn btn-secondary btn-block" @click="$emit('close')">Leave without Saving</button>
		<button  class="btn btn-block mb-2 btn-default" @click="$emit('hide')">Return to Editing</button>
	</div>
</alert>
</template>

<script>
import Alert from './Alert.vue';
export default{
	components:{
		Alert
	},
	props:{
		show:{},
		title:{
			default: 'Unsaved Changes!'
		},
		message:{
			default: 'You have unsaved changes.'
		},
		save:{
			default: true
		},
		saveDisabled:{
			default: false
		}
	}
}
</script>
