<template>
<div>

	<div v-if="alarm_groups.length == 0 && loading == false" class="bg-white container-fluid text-center py-3">
		No alarm groups
	</div>

	<button @click="$emit('change-view','create')" class="btn btn-block btn-secondary">New Alarm Group</button>
	
	<loading :state="loading"></loading>
	
	<div class="list">
		<div class="accordion has_caret" id="alarmGroupsAccordion">
			<div class="card" v-for="group in alarm_groups" :key="group.id">

				<div class="card-header" :id="'heading'+group.id" data-toggle="collapse" :data-target="'#group'+group.id" aria-expanded="false" :aria-controls="'remedial'+group.id" @click="$emit('select-group',group)">
					<div class="d-flex justify-content-between">
						<div>{{group.name}}</div>
						<div>
							<font-awesome-icon icon="users" fixed-width /> {{group._user_count}}
							<font-awesome-icon icon="list-ul" fixed-width /> {{group._sensor_count}}
						</div>
					</div>
				</div>

				<div :id="'group'+group.id" class="collapse" :aria-labelledby="'heading'+group.id" data-parent="#alarmGroupsAccordion">
					<div class="card-body py-0">
						<div class="buttons row text-center">
							<div class="col py-3" @click="$emit('change-view','users')">
								Users
							</div>
							<div class="col py-3" @click="$emit('change-view','sensors')">
								Sensors
							</div>
							<div class="col py-3" @click="$emit('change-view','edit')">
								Edit
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
</template>

<script>
import Loading from '../../Loading.vue';

export default{
	components:{
		Loading
	},
	data(){
		return {
			loading:false,
			alarm_groups:[],
			Request: null
		}
	},
	mounted(){
		this.fetchAlarmGroups();
	},
	methods:{
		fetchAlarmGroups(){
			if( this.Request ){
				this.Request.cancel();
			}
			this.Request = this.axios.CancelToken.source();

			//get alarm groups
			this.loading=true;
			var self = this;
			this.axios.get(this.$root.controllers_base_path+'settings/alarm_groups',{
				cancelToken: this.Request.token
			}).then(function(response){
				self.alarm_groups = response.data.data;
				self.loading=false;

				self.Request = null;
			});
		}
	},
	beforeDestroy(){
		//cancel existing graph requests if promise exists
		if(this.Request){
			this.Request.cancel();
		}
		this.Request = null;
	}
}
</script>

<style lang="scss" scoped>
.card-body{
	margin-top:1px;
}

.row{
	&.buttons{
		.col:first-child{
			border-right:1px solid #ccc;
		}
		.col:last-child{
			border-left:1px solid #ccc;
		}
	}
}
</style>
