<template>
<div class="offcanvas" :class="state">
	<nav class="navbar navbar-expand-sm navbar-light bg-light">
		<div class="w-100 d-flex align-items-center">
			<button type="button" class="btn btn-light mr-1" v-on:click="closeOffcanvas"><font-awesome-icon icon="chevron-left" /></button>
			<div class="offcanvas-title">Contact Login Email</div>
		</div>
	</nav>
	<div class="offcanvas-body">
		<div class="container-fluid py-3">
			<form-input :value="$store.state.user.email" type="email" label="Current Email Address" :disabled="true"/>
			<form-input v-model="email" type="email" label="New Email Address" :error="validationErrors.email"/>
			<div class="available" v-if="checkingAvailability">
				<span v-if="emailAvailability==null"><font-awesome-icon icon="spinner" spin/> checking availability</span>
				<span v-if="emailAvailability==true" class="text-success"><font-awesome-icon icon="check-circle"/> available</span>
				<span v-if="emailAvailability==false"><font-awesome-icon icon="exclamation-circle"/> already in use</span>
			</div>
			<form-input v-model="confirmEmail" :disabled="!emailAvailability || !email || validationErrors.email" type="email" label="Confirm Email Address" :error="validationErrors.confirmEmail"/>
			<form-input v-model="password" type="password" :disabled="!email || !confirmEmail || validationErrors.email || email!=confirmEmail" label="Password"/>
		</div>
	</div>
	<div class="offcanvas-footer" v-if="hasChanged && _.isEmpty(validationErrors)">
		<button class="btn btn-success btn-lg btn-block" v-on:click="$refs.auth.check(saveChanges)" :disabled="isSaving||!allowChanges">
			<span v-if="!isSaving">Save Changes</span>
			<span v-if="isSaving">Saving <font-awesome-icon icon="spinner" spin/></span>
		</button>
	</div>

	<unsaved-changes :show="showWarning" v-on:close="forceCloseOffcanvas" :save-disabled="!hasChanged || !_.isEmpty(validationErrors)" v-on:hide="showWarning=false" v-on:save="$refs.auth.check(saveChanges)"/>

	<alert v-if="showResponse">
		<div slot="title">{{responseTitle}}</div>
		<div v-html="responseMessage"></div>
		<button slot="buttons" class="btn btn-block mb-2 btn-default" @click="showResponse=false">Ok</button>
	</alert>

	<password-modal ref="auth" v-on:loaded="allowChanges=true"/>
</div>
</template>

<script>
import FormInput from '../../components/FormElements/Input.vue';
import UnsavedChanges from '../../components/UnsavedChangesAlert.vue';
import Alert from '../../components/Alert.vue';

export default{
	props:['state'],
	components:{
		FormInput,
		UnsavedChanges,
		Alert
	},
	computed:{
		hasChanged(){
			if( this.password != '' || this.email != '' || this.confirmEmail != '' ){
				return true;
			}else{
				return false;
			}
		},
		isSaving(){
			if(this.SaveRequest){
				return true;
			}else{
				return false;
			}
		}
	},
	data(){
		return {
			email:'',
			confirmEmail:'',
			password:'',
			showWarning:false,
			responseTitle:'',
			responseMessage:'',
			showResponse:false,
			validationErrors:{},
			checkingAvailability:false,
			emailAvailability:'',
			SaveRequest: null,
			AvailabilityRequest: null,
			allowChanges: false
		}
	},
	methods:{
		checkAvailable(){
			if( this.email != '' && !this.validationErrors.email ){
				//cancel existing graph requests if promise exists
				if(this.AvailabilityRequest){
					this.AvailabilityRequest.cancel();
				}

				//Create new cancellable request
				this.AvailabilityRequest = this.axios.CancelToken.source();

				this.checkingAvailability = true;
				this.emailAvailability = null;

				var self = this;
				var f = new FormData();
				f.append('email',this.email);
				this.axios.post( this.controller('auth/email_availability_check'), f, {
					cancelToken: this.AvailabilityRequest.token
				}).then(res=>{
					if(res.data == 'available'){
						self.emailAvailability = true;
					}else{
						self.emailAvailability = false;
					}

					self.AvailabilityRequest = null;
				});
			}else{
				this.checkingAvailability = false;
				this.emailAvailability = null;
			}
		},
		formValidation(){
			this.validationErrors={};

			if( this.email && !this.isValidEmail(this.email) ){
				this.validationErrors.email = 'Invalid email address';
			}

			if(this.email != this.confirmEmail){
				this.validationErrors.confirmEmail = 'Mismatch';
			}

			//block save button appearing
			if(!this.email || !this.confirmEmail || !this.password){
				this.validationErrors.missing = true;
			}
		},
		reset(){
			this.email='';
			this.confirmEmail='';
			this.showWarning=false;
			this.responseTitle='';
			this.responseMessage='';
		},
		closeOffcanvas(){
			if( this.hasChanged ){
				this.showWarning = true;
			}else{
				this.forceCloseOffcanvas();
			}
		},
		forceCloseOffcanvas(){
			this.reset();
			this.$emit('close');
		},
		saveChanges(){

			//cancel existing graph requests if promise exists
			if(this.SaveRequest){
				this.SaveRequest.cancel();
			}

			//Create new cancellable request
			this.SaveRequest = this.axios.CancelToken.source();

			var f = new FormData();
			f.append('password',this.password);
			f.append('new_email',this.email);
			f.append('new_confirm',this.confirmEmail);
			
			var self = this;

			this.axios.post( this.controller('auth/change_login_email') ,f, {
				cancelToken: this.SaveRequest.token
			}).then(res=>{
				if( res.data.status == 'failed' ){
					self.responseTitle='Error';
					self.responseMessage=res.data.message;
					self.showResponse=true;
				}

				self.SaveRequest = null;
			});
		}
	},
	watch:{
		email(){
			this.formValidation();
			this.checkAvailable();
		},
		confirmEmail(){
			this.formValidation();
		},
		password(){
			this.formValidation();
		}
	}
}
</script>

<style lang="scss" scoped>
.available{
	font-size:0.8em;
	margin-top:-10px;
	margin-bottom:15px;
}
</style>
