<template>
<div class="list-group">
	<router-link class="list-group-item" v-for="(link,index) in list" :to="link.url" :key="index">{{link.name}}</router-link>
</div>
</template>

<script>
export default{
	data(){
		return {
			list:[
				{name: 'Company Changer', url: '/super-admin/company-changer'},
				{name: 'Contact Dash', url: '/super-admin/contact-dash'}
			]
		}
	},
	mounted(){
		this.$emit('breadcrumb',[
			['Super Admin','/super-admin']
		]);
	}
}
</script>
