<template>
	<div v-if="state == true" class="bg-white container-fluid text-center py-3" :style="'font-size:'+size+'em;'">
		<font-awesome-icon icon="spinner" spin/><br/><small>Loading</small>
	</div>
</template>

<script>
export default{
	props:{
		state:{},
		size:{
			default: '1'
		}
	}
}
</script>
