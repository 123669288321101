<template>
<div class="container-fluid bg-white py-3">
	<div class="text-center">
		<img :src="'./assets/large_logo_alpha.png'" style="max-height:40px;" class="my-3"/>
		<h4>Privacy Policy</h4>
		<p>In order to use tscanweb you must accept our privacy policy.</p>
		<iframe src="https://www.tscanweb.com/help/t_scan_limited_privacy_notice.pdf"></iframe>
		<button class="btn btn-success btn-block my-3" @click="accept">Accept &amp; Continue</button>
	</div>
	<alert v-if="responseMessage != ''">
		<div slot="title">Notice</div>
		{{responseMessage}}
		<button slot="buttons" class="btn btn-default btn-block" @click="next">Ok</button>
	</alert>
</div>
</template>

<script>
import Alert from '../../components/Alert.vue';
export default {
		components:{
			Alert
		},
		data() {
			return {
				responseMessage: ''
			}
		},
		mounted() {

		},
		methods:{
			accept(){
				var self = this;
				this.axios.get( this.controller('auth/accept_privacy_policy') ).then(res=>{
					if( res.data.status == 'failed' ){
						self.responseMessage = res.data.message;
					}
				});
			},
			next(){
				this.responseMessage='';
				this.$router.push('/first-time-login');
			}
		}
}

</script>

<style lang="scss" scoped>
iframe{
	height:calc(100vh - 350px);
	width:100%;
	border:0;
}
</style>
